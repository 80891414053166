import { Dialog } from "@headlessui/react";

import Input from "../../../controls/Input";
import ExportLabelToPrint from "./ExportLabelToPrint";
import { useState } from "react";

export default function ExportLabelDialog(props) {
  const { certImage, printLabel } = props;

  const [errors, setErrors] = useState([]);
  const [label, setLabel] = useState("");

  function submitPrint() {
    if (label.length > 0) {
      printLabel(label);
    } else {
      setErrors({ label: "Please put a label" });
    }
  }

  return (
    <div>
      <div>
        <div className="text-center sm:mt-5">
          <Dialog.Title
            as="h3"
            className="text-base font-semibold leading-6 text-gray-900"
          >
            Please enter certificate number
          </Dialog.Title>
        </div>
      </div>

      <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
        <div>
          <Input
            label="Label"
            name="label"
            onChange={(e) => setLabel(e.target.value)}
            value={label}
            labelOn={true}
            type="text"
            error={errors.label}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
          />

          <button
            type="button"
            className="mt-4 inline-flex items-center rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
            onClick={submitPrint}
          >
            Print
          </button>
        </div>
        <ExportLabelToPrint
          certImage={certImage}
          label={label}
          showOnScreen={true}
        />
      </div>
    </div>
  );
}
