import React from "react";

import Input from "../../../../components/controls/Input";

export default function InvoiceRecordReceivingQualityDetails(props) {
  const { invoiceDetails } = props;

  return (
    <div className="col-span-1">
      <div className="px-4 py-5 sm:p-2 grid grid-cols-1 overflow-hidden rounded-lg bg-white shadow md:grid-cols-3">
        <div className="col-span-3">
          <h3 className="text-base font-semibold leading-6 text-gray-900">
            Quality Control
          </h3>
        </div>
        <div className="mt-2 col-span-1">
          <Input
            label="Product Temp C&deg;"
            name="product_temperature"
            value={invoiceDetails.product_temperature}
            labelOn={true}
            type="text"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-0 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
          />
        </div>
        <div className="mt-2 ml-2 col-span-1">
          <Input
            label="Product Cond."
            name="product_condition"
            value={invoiceDetails.product_condition.label}
            labelOn={true}
            type="text"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-0 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
          />
        </div>
        <div className="mt-2 ml-2 col-span-1">
          <Input
            label="Product Type"
            name="product_type"
            value={invoiceDetails.product_type}
            labelOn={true}
            type="text"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-0 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
          />
        </div>

        <div className="mt-2 col-span-1">
          <Input
            label="Truck Temp C&deg;"
            name="truck_temperature"
            value={invoiceDetails.truck_temperature}
            labelOn={true}
            type="text"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-0 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
          />
        </div>
        <div className="mt-2 ml-2 col-span-1">
          <Input
            label="Truck Cond."
            name="truck_condition"
            value={invoiceDetails.truck_condition.label}
            labelOn={true}
            type="text"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-0 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
          />
        </div>
        {/* <div className="mt-2 ml-2 col-span-1">
          <Input
            label="Is Meat Product"
            name="is_non_meat"
            value={invoiceDetails.is_non_meat}
            labelOn={true}
            type="text"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-0 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
          />
        </div> */}

        <div className="mt-2 ml-2 col-span-1">
          <Input
            label="Pallet Condition"
            name="pallet_condition"
            value={invoiceDetails.pallet_condition.label}
            labelOn={true}
            type="text"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-0 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
          />
        </div>
        <div className="mt-2 col-span-2">
          <Input
            label="Certification"
            name="certifications"
            value={invoiceDetails.certifications}
            labelOn={true}
            type="text"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-0 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
          />
        </div>
        <div className="mt-2 ml-2 col-span-1">
          <Input
            label="Received By"
            name="received_by"
            value={invoiceDetails.received_by}
            labelOn={true}
            type="text"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-0 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
          />
        </div>

        <div className="grid col-span-3 grid-cols-2">
          <div className="mt-2 col-span-1">
            <Input
              label="Allergens"
              name="allergens"
              value={invoiceDetails.allergens}
              labelOn={true}
              type="text"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-0 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            />
          </div>
          <div className="mt-2 ml-2 col-span-1">
            <Input
              label="Preservatives"
              name="preservatives"
              value={invoiceDetails.preservatives}
              labelOn={true}
              type="text"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-0 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
