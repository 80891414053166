import React, { useEffect, useRef, useState } from "react";

import { Dialog } from "@headlessui/react";
import { MagnifyingGlassIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { formatDate } from "../../../../shared/utility";
import { Paginator } from "primereact/paginator";

export default function ReceivedMaterialsForPackaging(props) {
  const { getInContactPackagingSupplies, setItemValues, setOpen } = props;

  const [receivedPackagingMaterial1, setReceivedPackagingMaterial1] = useState({
    data: [],
  });

  const searchRef = useRef();

  const [first, setFirst] = useState(1);
  const [rows, setRows] = useState(20);

  var currentPage = 1;

  const handleSearch = async function () {
    var searchVar = null;
    if (searchRef) {
      searchVar = searchRef.current.value;
    }
    const callResult = await getInContactPackagingSupplies(
      currentPage,
      searchVar
    );
    setReceivedPackagingMaterial1(callResult.data);
  };

  useEffect(() => {
    handleSearch();
  }, []);

  const handlePagination = (event) => {
    currentPage = event.page + 1;
    setFirst(event.first);
    setRows(event.rows);
    handleSearch();
  };

  return (
    <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
      <div className="flex min-h-0 flex-1 flex-col overflow-y-scroll py-6">
        <div className="px-4 sm:px-6">
          <div className="flex items-start justify-between">
            <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
              Choose a packaging item
            </Dialog.Title>
            <div className="ml-3 flex h-7 items-center">
              <button
                type="button"
                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                onClick={() => setOpen({ childComponent: null, open: false })}
              >
                <span className="sr-only">Close panel</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
          </div>
          <div className="mt-2 flex rounded-md">
            <div className="relative flex items-stretch flex-grow focus-within:z-10">
              <input
                type="search"
                name="searchInput"
                id="searchInput"
                className="block w-full rounded-lg rounded-l-md pl-2 sm:text-sm border border-gray-300 rounded-tr-none rounded-br-none"
                placeholder="Search by name"
                ref={searchRef}
              />
            </div>
            <button
              type="button"
              className="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1"
              onClick={() => handleSearch()}
            >
              <MagnifyingGlassIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </button>
          </div>
        </div>
        <div className="relative mt-6 flex-1 px-4 sm:px-6">
          <div id="product-table" className="overflow-hidden card-custom">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-white">
                <tr>
                  <th
                    scope="col"
                    className="py-3 pl-3 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Lot #
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Received Date
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {receivedPackagingMaterial1.data.map((packagingItem) => (
                  <tr
                    key={packagingItem.id}
                    className="cursor-pointer"
                    onClick={() => {
                      setItemValues(packagingItem.product_lot);
                      setOpen({ childComponent: null, open: false });
                    }}
                  >
                    <td className="whitespace-nowrap py-1.5 pl-4 pr-3 text-sm font-medium text-gray-500 sm:pl-6">
                      {packagingItem.product_name}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {packagingItem.product_lot}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {formatDate(packagingItem.created_at)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="w-full">
              <Paginator
                first={first}
                rows={rows}
                totalRecords={receivedPackagingMaterial1.total}
                // rowsPerPageOptions={[10, 20, 30]}
                onPageChange={handlePagination}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
