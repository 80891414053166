import { useState } from "react";

import { makePostApiCall, makePutApiCall } from "../../../shared/makeApiCall";
import ProductionCostsForm from "../../../components/forms/setup/productionCosts/ProductionCostsForm";
import { BannerNotificationType } from "../../../components/SimpleBanner";

export function ProductionCostsViewModel(logout) {
  const [loading, setLoading] = useState(false);
  const [productionCosts, setProductionCosts] = useState({ data: [] });

  const [showSimpleNotification, setShowSimpleNotification] = useState({
    show: false,
    message: null,
    title: null,
    type: BannerNotificationType.Success,
  });
  const [slideOver, setSlideOver] = useState({
    childComponent: null,
    open: false,
  });

  const user_id = localStorage.getItem("id");

  const getProductionCosts = async function (search, page) {
    setLoading(true);
    const payload = {
      page: page,
      search: search,
    };
    let callResult = await makePostApiCall(`productionCosts`, payload);
    setLoading(false);
    if (callResult.success) {
      setProductionCosts(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const createProductionCost = async function (values) {
    const payload = { ...values, user_id: user_id };
    let callResult = await makePostApiCall("createProductionCost", payload);

    if (callResult.success) {
      setShowSimpleNotification({
        show: true,
        title: "ProductionCost added",
        type: BannerNotificationType.Success,
      });
      setSlideOver({
        childComponent: null,
        open: false,
      });
      setTimeout(function () {
        getProductionCosts("", 1);
      }, 1000);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const updateProductionCost = async function (productionCost, values, index) {
    let callResult = await makePutApiCall(
      `productionCosts/${productionCost.id}`,
      values
    );

    if (callResult.success) {
      setShowSimpleNotification({
        show: true,
        title: "ProductionCost updated",
        type: BannerNotificationType.Success,
      });
      setSlideOver({
        childComponent: null,
        open: false,
      });
      setTimeout(function () {
        getProductionCosts("", 1);
      }, 1000);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const openAddProductionCostSlideOver = () => {
    setSlideOver({
      childComponent: (
        <ProductionCostsForm
          isEdit={false}
          setOpen={setSlideOver}
          actionCall={createProductionCost}
        />
      ),
      open: true,
    });
  };

  const viewProductionCostDetails = async function (productionCost, index) {
    setSlideOver({
      childComponent: (
        <ProductionCostsForm
          productionCost={productionCost}
          index={index}
          isEdit={true}
          setOpen={setSlideOver}
          actionCall={updateProductionCost}
        />
      ),
      open: true,
    });
  };

  return {
    getProductionCosts,
    loading,
    productionCosts,
    openAddProductionCostSlideOver,
    setSlideOver,
    setShowSimpleNotification,
    slideOver,
    showSimpleNotification,
    viewProductionCostDetails,
  };
}
