import { useState } from "react";

import { makePostApiCall, makePutApiCall } from "../../../shared/makeApiCall";
import EmployeesForm from "../../../components/forms/setup/employees/EmployeesForm";
import { BannerNotificationType } from "../../../components/SimpleBanner";
import DeleteConfirmationForm from "../../../components/forms/DeleteConfirmationForm";

export function EmployeesViewModel(logout) {
  const [emptyModal, setEmptyModelOpen] = useState({
    open: false,
    childComponent: null,
  });
  const [loading, setLoading] = useState(false);
  const [employees, setEmployees] = useState({ data: [] });

  const [showSimpleNotification, setShowSimpleNotification] = useState({
    show: false,
    message: null,
    title: null,
    type: BannerNotificationType.Success,
  });
  const [slideOver, setSlideOver] = useState({
    childComponent: null,
    open: false,
  });

  const user_id = localStorage.getItem("id");

  const getEmployees = async function (search, page) {
    setLoading(true);
    const payload = {
      page: page,
      search: search,
    };
    let callResult = await makePostApiCall(`employees`, payload);
    setLoading(false);
    if (callResult.success) {
      setEmployees(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const createEmployee = async function (values) {
    const payload = { ...values, user_id: user_id };
    let callResult = await makePostApiCall("createEmployee", payload);

    if (callResult.success) {
      setShowSimpleNotification({
        show: true,
        title: "Employee added",
        type: BannerNotificationType.Success,
      });
      setSlideOver({
        childComponent: null,
        open: false,
      });
      setTimeout(function () {
        getEmployees("", 1);
      }, 1000);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const updateEmployee = async function (employee, values, index) {
    let callResult = await makePutApiCall(`employees/${employee.id}`, values);

    if (callResult.success) {
      setShowSimpleNotification({
        show: true,
        title: "Employee updated",
        type: BannerNotificationType.Success,
      });
      setSlideOver({
        childComponent: null,
        open: false,
      });
      setTimeout(function () {
        getEmployees("", 1);
      }, 1000);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const openAddEmployeeSlideOver = () => {
    setSlideOver({
      childComponent: (
        <EmployeesForm
          isEdit={false}
          setOpen={setSlideOver}
          actionCall={createEmployee}
        />
      ),
      open: true,
    });
  };

  const viewEmployeeDetails = async function (id, index) {
    // setLoading(true);

    let callResult = await makePostApiCall(`employees/${id}`);
    // setLoading(false);
    if (callResult.success) {
      const employee = callResult.data;
      setSlideOver({
        childComponent: (
          <EmployeesForm
            employee={employee}
            index={index}
            isEdit={true}
            setOpen={setSlideOver}
            actionCall={updateEmployee}
          />
        ),
        open: true,
      });
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const deleteEmployee = async function (id, index) {
    setLoading(true);

    let callResult = await makePostApiCall(`deleteEmployee/${id}`, {});
    setLoading(false);
    if (callResult.success) {
      setEmptyModelOpen({
        open: false,
        childComponent: null,
      });
      getEmployees(null, 0);
      setShowSimpleNotification({
        show: true,
        title: callResult.data.message,
        type: BannerNotificationType.Success,
      });
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const askDeleteEmployee = function (employee, index) {
    setEmptyModelOpen({
      childComponent: (
        <DeleteConfirmationForm
          buttonMessage="Delete"
          confirmAction={() => deleteEmployee(employee.id, index)}
          message={`Are you sure you want to delete ${employee.name}?`}
          setOpen={setEmptyModelOpen}
        />
      ),
      open: true,
    });
  };

  // const getEmployeeDepartmentsForDropdown = async function () {
  //   let callResult = await makePostApiCall(`unitOfMeasuresForDropdown`);

  //   if (callResult.success) {
  //     setUnitOfMeasureOptions(callResult.data);
  //   } else {
  //     if (callResult.errorStatus === 401) {
  //       logout();
  //     }
  //   }
  // };

  return {
    askDeleteEmployee,
    emptyModal,
    setEmptyModelOpen,
    getEmployees,
    loading,
    employees,
    openAddEmployeeSlideOver,
    setSlideOver,
    setShowSimpleNotification,
    slideOver,
    showSimpleNotification,
    viewEmployeeDetails,
  };
}
