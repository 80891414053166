import { useEffect } from "react";
import { NewPackageViewModel } from "../../viewmodels/packaging/NewPackageViewModel";

import { connect } from "react-redux";

import * as actions from "../../store/actions/index";

import { withRouter } from "../../shared/utility";

import SlideOver from "../../components/slideOver/SlideOver";

import NewPackageForm from "../../components/forms/packaging/NewPackageForm";
import EmptyModal from "../../components/modals/EmptyModal";
import PackagingProductLabel from "../../components/forms/packaging/barcode-label/PackagingProductLabel";
import PackagingSkidLabel from "../../components/forms/packaging/barcode-label/PackagingSkidLabel";
import SimpleBanner from "../../components/SimpleBanner";

function NewPackageScreen(props) {
  const { logout } = props;
  const {
    getManufacturedItemByLot,
    emptyModal,
    setEmptyModelOpen,
    setShowSimpleNotification,
    setSlideOver,
    showSimpleNotification,
    slideOver,
    employeeOptions,
    getEmployeesForDropdown,
    getInContactPackagingSupplies,
    savePackagingDetailsAndPrint,
    getBrands,
    brands,
    showErrorModal,
    createSkidLabelBarcode,
    skidLabel,
    printingLabelType,
    scaleIsOpen,
    setScaleIsOpen,
    printedPackageItems,
    deleteSinglePackagedItem,
    reprintPackageLabel,
    packagingLabel,
    setPackagingLabel,
    fetchProductByPlu,
    printInProgress,
    printSkidLabelInProgress,
    reprintInProgress,
    deleteInProgress,
    supervisorOptions,
  } = NewPackageViewModel(logout);

  useEffect(() => {
    getEmployeesForDropdown(2, [1, 2]);
    getEmployeesForDropdown(1, [1, 2]);
    getBrands();
  }, []);

  function printPackagingLabel(labelDetails) {
    setTimeout(function () {
      window.print();

      if (labelDetails.printCounter < labelDetails.serials.length) {
        const newLabelDetails = {
          ...labelDetails,
          printCounter: labelDetails.printCounter + 1,
        };

        setPackagingLabel(newLabelDetails.serials[labelDetails.printCounter]);

        printPackagingLabel(newLabelDetails);
      }
    }, 1500);
  }

  function myLoop(labelDetails) {
    const newLabelDetails = {
      ...labelDetails,
      printCounter: 1,
    };
    setPackagingLabel(newLabelDetails.serials[0]);
    printPackagingLabel(newLabelDetails);
  }

  return (
    <div>
      <SimpleBanner
        setShow={setShowSimpleNotification}
        showSimpleNotification={showSimpleNotification}
      />
      <div className="hide-for-print mt-2">
        <NewPackageForm
          employeeOptions={employeeOptions}
          isEdit={false}
          getManufacturedItemByLot={getManufacturedItemByLot}
          packagedProduct={null}
          setEmptyModelOpen={setEmptyModelOpen}
          setSlideOver={setSlideOver}
          savePackagingDetailsAndPrint={savePackagingDetailsAndPrint}
          packagingLabel={packagingLabel}
          getInContactPackagingSupplies={getInContactPackagingSupplies}
          setPackagingLabel={setPackagingLabel}
          brands={brands}
          myLoop={myLoop}
          showErrorModal={showErrorModal}
          createSkidLabelBarcode={createSkidLabelBarcode}
          setScaleIsOpen={setScaleIsOpen}
          scaleIsOpen={scaleIsOpen}
          printedPackageItems={printedPackageItems}
          deleteSinglePackagedItem={deleteSinglePackagedItem}
          reprintPackageLabel={reprintPackageLabel}
          fetchProductByPlu={fetchProductByPlu}
          printInProgress={printInProgress}
          printSkidLabelInProgress={printSkidLabelInProgress}
          reprintInProgress={reprintInProgress}
          deleteInProgress={deleteInProgress}
          supervisorOptions={supervisorOptions}
        />
      </div>

      <EmptyModal
        childComponent={emptyModal.childComponent}
        open={emptyModal.open}
        setOpen={setEmptyModelOpen}
      />
      <SlideOver
        childComponent={slideOver.childComponent}
        customClass={slideOver.size ? slideOver.size : "max-w-7xl"}
        slideOver={slideOver}
        setSlideOver={setSlideOver}
      />
      {printingLabelType.type === 1 ? (
        <PackagingSkidLabel skidLabel={skidLabel} />
      ) : (
        <PackagingProductLabel packagingLabel={packagingLabel} />
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(actions.logout()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NewPackageScreen)
);
