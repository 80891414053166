import { useState } from "react";

import { makePostApiCall } from "../../../shared/makeApiCall";
import BrandDetailsForm from "../../../components/forms/setup/brand/BrandDetailsForm";
import { BannerNotificationType } from "../../../components/SimpleBanner";
import DeleteConfirmationForm from "../../../components/forms/DeleteConfirmationForm";

export function BrandsViewModel(logout) {
  const [emptyModal, setEmptyModelOpen] = useState({
    open: false,
    childComponent: null,
  });
  const [loading, setLoading] = useState(false);
  const [brands, setBrands] = useState({
    data: [],
  });
  const [showSimpleNotification, setShowSimpleNotification] = useState({
    show: false,
    message: null,
    title: null,
    type: BannerNotificationType.Success,
  });
  const [slideOver, setSlideOver] = useState({
    childComponent: null,
    open: false,
  });

  const user_id = localStorage.getItem("id") * 1;

  const getBrands = async function (search, page) {
    setLoading(true);
    const payload = {
      page: page,
      search: search,
    };
    let callResult = await makePostApiCall(`brands`, payload);
    setLoading(false);
    if (callResult.success) {
      setBrands(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const createBrand = async function (values) {
    const payload = { ...values, user_id: user_id };
    let callResult = await makePostApiCall(
      "createBrand",
      payload,
      "multipart/form-data"
    );

    if (callResult.success) {
      setShowSimpleNotification({
        show: true,
        title: "Brand added",
        type: BannerNotificationType.Success,
      });
      setSlideOver({
        childComponent: null,
        open: false,
      });
      setTimeout(function () {
        getBrands("", 1);
      }, 1000);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const updateBrand = async function (brand, values, index) {
    let callResult = await makePostApiCall(
      `updateBrand/${brand.id}`,
      values,
      "multipart/form-data"
    );

    if (callResult.success) {
      setShowSimpleNotification({
        show: true,
        title: "Brand updated",
        type: BannerNotificationType.Success,
      });
      setSlideOver({
        childComponent: null,
        open: false,
      });
      setTimeout(function () {
        getBrands("", 1);
      }, 1000);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const openAddBrandSlideOver = () => {
    setSlideOver({
      childComponent: (
        <BrandDetailsForm
          isEdit={false}
          setOpen={setSlideOver}
          actionCall={createBrand}
        />
      ),
      open: true,
    });
  };

  const viewBrandDetails = async function (brand, index) {
    setSlideOver({
      childComponent: (
        <BrandDetailsForm
          brand={brand}
          index={index}
          isEdit={true}
          setOpen={setSlideOver}
          actionCall={updateBrand}
        />
      ),
      open: true,
    });
  };

  const deleteBrand = async function (id, index) {
    setLoading(true);

    let callResult = await makePostApiCall(`deleteBrand/${id}`, {});
    setLoading(false);
    if (callResult.success) {
      setEmptyModelOpen({
        open: false,
        childComponent: null,
      });
      getBrands(null, 0);
      setShowSimpleNotification({
        show: true,
        title: callResult.data.message,
        type: BannerNotificationType.Success,
      });
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const askDeleteBrand = function (brand, index) {
    setEmptyModelOpen({
      childComponent: (
        <DeleteConfirmationForm
          buttonMessage="Delete"
          confirmAction={() => deleteBrand(brand.id, index)}
          message={`Are you sure you want to delete ${brand.name}?`}
          setOpen={setEmptyModelOpen}
        />
      ),
      open: true,
    });
  };

  return {
    askDeleteBrand,
    emptyModal,
    openAddBrandSlideOver,
    getBrands,
    loading,
    brands,
    setSlideOver,
    slideOver,
    setEmptyModelOpen,
    setShowSimpleNotification,
    showSimpleNotification,
    viewBrandDetails,
  };
}
