import React from "react";
import { classNames } from "../../shared/utility";

export default function TextWithLabel(props) {
  const { label, labelClass, parentClass, text, textClass } = props;

  return (
    <div className={parentClass ? parentClass : null}>
      <div className="flex space-x-2 mb-1">
        <p
          className={classNames(
            labelClass ? labelClass : "font-12",
            "block font-medium text-gray-600"
          )}
        >
          {label}
        </p>
      </div>

      <div>
        <div className="flex space-x-2 mb-1">
          <p
            className={classNames(
              textClass ? textClass : "text-base",
              "block font-medium text-gray-900"
            )}
          >
            {text}
          </p>
        </div>
      </div>
    </div>
  );
}
