import { useState } from "react";

import { makePostApiCall, makePutApiCall } from "../../../shared/makeApiCall";
import AssemblyItemDetailsForm from "../../../components/forms/setup/assemblyItem/AssemblyItemDetailsForm";
import { BannerNotificationType } from "../../../components/SimpleBanner";

export function AssemblyItemsViewModel(logout) {
  const [loading, setLoading] = useState(false);
  const [assemblyItems, setAssemblyItems] = useState({
    data: [],
  });
  const [countryOptions, setCountryOptions] = useState([]);
  const [showSimpleNotification, setShowSimpleNotification] = useState({
    show: false,
    message: null,
    title: null,
    type: BannerNotificationType.Success,
  });
  const [slideOver, setSlideOver] = useState({
    childComponent: null,
    open: false,
  });
  const [certifiedByOptions, setCertifiedByOptions] = useState([]);
  const [manufacturersOptions, setManufacturersOptions] = useState([]);
  const [preservativeOptions, setPreservativeOptions] = useState([]);
  const [claimsOptions, setClaimsOptions] = useState([]);
  const [allergensOptions, setAllergensOptions] = useState([]);
  const [productTypeOptions, setProductTypeOptions] = useState([]);
  const [myIngredientsOptions, setMyIngredientsOptions] = useState([]);
  const [unitOfMeasureOptions, setUnitOfMeasureOptions] = useState([]);

  const user_id = localStorage.getItem("id") * 1;

  const getAssemblyItems = async function (search, page) {
    setLoading(true);
    const payload = {
      page: page,
      search: search,
    };
    let callResult = await makePostApiCall(`assemblyItems`, payload);
    setLoading(false);
    if (callResult.success) {
      setAssemblyItems(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const createAssemblyItem = async function (values) {
    const payload = { ...values, user_id: user_id };
    let callResult = await makePostApiCall(
      "createAssemblyItem",
      payload,
      "multipart/form-data"
    );

    if (callResult.success) {
      setShowSimpleNotification({
        show: true,
        title: "Supplier item added",
        type: BannerNotificationType.Success,
      });
      setSlideOver({
        childComponent: null,
        open: false,
      });
      setTimeout(function () {
        getAssemblyItems("", 1);
      }, 1000);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const updateAssemblyItem = async function (assemblyItem, values, index) {
    let callResult = await makePutApiCall(
      `assemblyItems/${assemblyItem.id}`,
      values
    );

    if (callResult.success) {
      setShowSimpleNotification({
        show: true,
        title: "Supplier item updated",
        type: BannerNotificationType.Success,
      });
      setSlideOver({
        childComponent: null,
        open: false,
      });
      setTimeout(function () {
        getAssemblyItems("", 1);
      }, 1000);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const fetchAssemblyItemDetails = async function (assemblyItemId, index) {
    const payload = {
      assemblyItemId: assemblyItemId,
    };
    let callResult = await makePostApiCall(
      `assemblyItems/${assemblyItemId}`,
      payload
    );

    if (callResult.success) {
      setSlideOver({
        childComponent: (
          <AssemblyItemDetailsForm
            actionCall={updateAssemblyItem}
            assemblyItem={callResult.data}
            index={index}
            isEdit={true}
            certifiedByOptions={certifiedByOptions}
            manufacturersOptions={manufacturersOptions}
            myIngredientsOptions={myIngredientsOptions}
            preservativeOptions={preservativeOptions}
            claimsOptions={claimsOptions}
            allergensOptions={allergensOptions}
            productTypeOptions={productTypeOptions}
            unitOfMeasureOptions={unitOfMeasureOptions}
            setOpen={setSlideOver}
            countryOptions={countryOptions}
          />
        ),
        open: true,
      });
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const getCertifiedByOptionsForDropdown = async function () {
    let callResult = await makePostApiCall(`certifiedByOptionsForDropdown`);

    if (callResult.success) {
      setCertifiedByOptions(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const getCountryOptionsForDropdown = async function () {
    let callResult = await makePostApiCall(`countryOptions`);

    if (callResult.success) {
      setCountryOptions(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const getUnitOfMeasuresForDropdown = async function () {
    let callResult = await makePostApiCall(`unitOfMeasuresForDropdown`);

    if (callResult.success) {
      setUnitOfMeasureOptions(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const getAllergensForDropdown = async function () {
    let callResult = await makePostApiCall(`allergensForDropdown`);

    if (callResult.success) {
      setAllergensOptions(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const getClaimsForDropdown = async function () {
    let callResult = await makePostApiCall(`claimsForDropdown`);

    if (callResult.success) {
      setClaimsOptions(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const getManufacturersForDropdown = async function () {
    let callResult = await makePostApiCall(`manufacturersForDropdown`);

    if (callResult.success) {
      setManufacturersOptions(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const getPreservativesForDropdown = async function () {
    let callResult = await makePostApiCall(`preservativesForDropdown`);

    if (callResult.success) {
      setPreservativeOptions(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const getProductTypes = async function () {
    let callResult = await makePostApiCall(`productTypesForDropdown`);

    if (callResult.success) {
      setProductTypeOptions(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const openAddAssemblyItemSlideOver = () => {
    setSlideOver({
      childComponent: (
        <AssemblyItemDetailsForm
          isEdit={false}
          setOpen={setSlideOver}
          actionCall={createAssemblyItem}
          certifiedByOptions={certifiedByOptions}
          manufacturersOptions={manufacturersOptions}
          myIngredientsOptions={myIngredientsOptions}
          preservativeOptions={preservativeOptions}
          claimsOptions={claimsOptions}
          allergensOptions={allergensOptions}
          unitOfMeasureOptions={unitOfMeasureOptions}
          productTypeOptions={productTypeOptions}
          countryOptions={countryOptions}
        />
      ),
      open: true,
    });
  };

  const deleteAssemblyItem = async function (id, index) {
    let callResult = await makePostApiCall(`deleteAssemblyItem/${id}`);

    if (callResult.success) {
      const updatedAssemblyItems = assemblyItems.data.filter(
        (item) => item.id !== id
      );
      setAssemblyItems({ ...assemblyItems, data: updatedAssemblyItems });

      setShowSimpleNotification({
        show: true,
        title: "Supplier deleted",
        type: BannerNotificationType.Success,
      });
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const getMyIngredientsOptionsForDropdown = async function () {
    let callResult = await makePostApiCall(`myIngredientsForDropdown`);

    if (callResult.success) {
      setMyIngredientsOptions(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  return {
    deleteAssemblyItem,
    openAddAssemblyItemSlideOver,
    getAllergensForDropdown,
    getAssemblyItems,
    getCertifiedByOptionsForDropdown,
    getClaimsForDropdown,
    getManufacturersForDropdown,
    getMyIngredientsOptionsForDropdown,
    getPreservativesForDropdown,
    getProductTypes,
    loading,
    assemblyItems,
    setSlideOver,
    slideOver,
    setShowSimpleNotification,
    showSimpleNotification,
    fetchAssemblyItemDetails,
    getUnitOfMeasuresForDropdown,
    unitOfMeasureOptions,
    getCountryOptionsForDropdown,
  };
}
