import React from "react";
import { Link } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { formatDate } from "../../../../shared/utility";

export default function TraceManufacturedProductsTable(props) {
  const { manufacturedProducts } = props;

  const itemActionTemplate = (rowData) => {
    return (
      <div className="ml-10">
        <Link
          className="ml-5 rounded-md bg-main-purple px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          to={"/manufactured-products/" + rowData.id}
          target="_blank"
          rel="noopener noreferrer"
        >
          View
        </Link>
      </div>
    );
  };

  const productNameTemplate = (rowData) => {
    return (
      <p>
        {rowData.product_name} <br />
        {rowData.plu}
      </p>
    );
  };

  const productionDateTemplate = (rowData) => {
    return formatDate(rowData.production_date);
  };

  return (
    <div className="flow-root">
      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          {/* <h3 className="text-lg mb-2">Manufactured Products</h3> */}
          <div className="overflow-hidden card-custom bg-white">
            <DataTable
              value={manufacturedProducts}
              dataKey="id"
              tableClassName="min-w-full bg-white divide-y divide-gray-300"
              cellClassName="py-2 bg-gray-100 border-1-bottom-gray"
            >
              <Column header="Product Name" body={productNameTemplate} />
              <Column header="Production Date" body={productionDateTemplate} />
              <Column field="description_en" header="Description" />
              <Column field="new_lot_number" header="Lot Number" />
              <Column field="amount_used" header="Amount Used" />
              <Column field="certification_name" header="Certification" />
              <Column field="supervisor_name" header="Supervisor Name" />
              <Column header="" body={itemActionTemplate} />
            </DataTable>
          </div>
        </div>
      </div>
    </div>
  );
}
