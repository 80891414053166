import { useEffect } from "react";

import { ReceivingInvoiceDetailsViewModel } from "../../../../viewmodels/receiving/invoices/details/ReceivingInvoiceDetailsViewModel";

import { connect } from "react-redux";

import * as actions from "../../../../store/actions/index";

import { withRouter } from "../../../../shared/utility";

import SlideOver from "../../../../components/slideOver/SlideOver";
import AddReceivingInvoiceForm from "../../../../components/forms/receiving/invoices/AddReceivingInvoiceForm";
import SimpleBanner from "../../../../components/SimpleBanner";
import { useParams } from "react-router-dom";
import EmptyModal from "../../../../components/modals/EmptyModal";

function ReceivingInvoiceDetails(props) {
  const { logout } = props;

  const { invoiceId } = useParams();

  const {
    addItemToReceivingInvoice,
    barcodeDetails,
    createReceivingInvoice,
    getAllAssemblyItems,
    getAssemblyItemByPlu,
    invoice,
    invoiceItems,
    onGenerateBarcodePdf,
    loading,
    setSlideOver,
    setShowSimpleNotification,
    slideOver,
    showSimpleNotification,
    getReceivingInvoiceDetails,
    getReceivingInvoiceItems,
    updateReceivingInvoiceApiCall,
    setEmptyModelOpen,
    emptyModal,
    showErrorModal,
    openReceivingItemDetails,
    deleteReceivingItem,
    getEmployeesForDropdown,
    employeeOptions,
  } = ReceivingInvoiceDetailsViewModel(logout, invoiceId);

  useEffect(() => {
    getEmployeesForDropdown();
    getReceivingInvoiceDetails();
    getReceivingInvoiceItems(invoiceId);
  }, []);

  return (
    <div>
      <SimpleBanner
        setShow={setShowSimpleNotification}
        showSimpleNotification={showSimpleNotification}
      />
      <div className="mt-2 px-4 sm:px-8">
        {invoice.id > 0 ? (
          <AddReceivingInvoiceForm
            addItemToReceivingInvoice={addItemToReceivingInvoice}
            barcodeDetails={barcodeDetails}
            createReceivingInvoice={createReceivingInvoice}
            invoice={invoice}
            isEdit={true}
            onGenerateBarcodePdf={onGenerateBarcodePdf}
            getAllAssemblyItems={getAllAssemblyItems}
            getAssemblyItemByPlu={getAssemblyItemByPlu}
            setSlideOver={setSlideOver}
            invoiceItems={invoiceItems}
            updateReceivingInvoiceApiCall={updateReceivingInvoiceApiCall}
            showErrorModal={showErrorModal}
            openReceivingItemDetails={openReceivingItemDetails}
            deleteReceivingItem={deleteReceivingItem}
            setEmptyModelOpen={setEmptyModelOpen}
            employeeOptions={employeeOptions}
            getReceivingInvoiceItems={getReceivingInvoiceItems}
          />
        ) : null}
      </div>

      <EmptyModal
        childComponent={emptyModal.childComponent}
        open={emptyModal.open}
        setOpen={setEmptyModelOpen}
      />
      <SlideOver
        childComponent={slideOver.childComponent}
        customClass="max-w-3xl"
        slideOver={slideOver}
        setSlideOver={setSlideOver}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(actions.logout()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ReceivingInvoiceDetails)
);
