import { useState } from "react";

import { makePostApiCall } from "../../shared/makeApiCall";
import { BannerNotificationType } from "../../components/SimpleBanner";
import DeleteConfirmationForm from "../../components/forms/DeleteConfirmationForm";

export function RecipesViewModel(logout) {
  const [emptyModal, setEmptyModelOpen] = useState({
    open: false,
    childComponent: null,
  });
  const [loading, setLoading] = useState(false);
  const [recipes, setRecipes] = useState({ data: [] });

  const [showSimpleNotification, setShowSimpleNotification] = useState({
    show: false,
    message: null,
    title: null,
    type: BannerNotificationType.Success,
  });
  const [slideOver, setSlideOver] = useState({
    childComponent: null,
    open: false,
  });

  const getRecipes = async function (search, page) {
    setLoading(true);
    const payload = {
      page: page,
      search: search,
    };
    let callResult = await makePostApiCall(`recipes`, payload);
    setLoading(false);
    if (callResult.success) {
      setRecipes(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const deleteRecipe = async function (id) {
    setLoading(true);

    let callResult = await makePostApiCall(`deleteRecipe/${id}`, {});
    setLoading(false);
    if (callResult.success) {
      setEmptyModelOpen({
        open: false,
        childComponent: null,
      });
      getRecipes(null, 0);
      setShowSimpleNotification({
        show: true,
        title: callResult.data.message,
        type: BannerNotificationType.Success,
      });
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const askDeleteRecipe = function (recipe) {
    setEmptyModelOpen({
      childComponent: (
        <DeleteConfirmationForm
          buttonMessage="Delete"
          confirmAction={() => deleteRecipe(recipe.id)}
          message={`Are you sure you want to delete  ${recipe.recipe_name}?`}
          setOpen={setEmptyModelOpen}
        />
      ),
      open: true,
    });
  };

  return {
    askDeleteRecipe,
    emptyModal,
    getRecipes,
    loading,
    recipes,
    setEmptyModelOpen,
    setSlideOver,
    setShowSimpleNotification,
    slideOver,
    showSimpleNotification,
  };
}
