import { useState } from "react";

import { makePostApiCall } from "../../../shared/makeApiCall";
import { BannerNotificationType } from "../../../components/SimpleBanner";
import ModelErrorComponent from "../../../components/modals/ModelErrorComponent";
import ReceivingAssemblyItemDetails from "../../../components/forms/receiving/invoices/ReceivingAssemblyItemDetails";

export function AddReceivingInvoiceViewModel(logout) {
  const [barcodeDetails, setBarcodeDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const [emptyModal, setEmptyModelOpen] = useState({
    open: false,
    childComponent: null,
  });
  const [slideOver, setSlideOver] = useState({
    childComponent: null,
    open: false,
  });
  const [showSimpleNotification, setShowSimpleNotification] = useState({
    show: false,
    message: null,
    title: null,
    type: BannerNotificationType.Success,
  });
  const [productTypeOptions, setProductTypeOptions] = useState([]);
  const [claimsOptions, setClaimsOptions] = useState([]);
  const [allergensOptions, setAllergensOptions] = useState([]);
  const [invoice, setInvoice] = useState({
    id: 0,
    supplier_name: "",
    invoice_number: "",
  });
  const [invoiceItems, setInvoiceItems] = useState([]);
  const [employeeOptions, setEmployeeOptions] = useState([]);

  const addItemToReceivingInvoice = async function (values) {
    const payload = {
      ...values,
    };
    let callResult = await makePostApiCall(
      "addItemToReceivingInvoice",
      payload
    );
    if (callResult.success) {
      setInvoiceItems([...invoiceItems, callResult.data.receiving_item]);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
        return;
      }
      showErrorModal(callResult.data.message);
    }
    return callResult;
  };

  const createReceivingInvoice = async function (values) {
    // First create the invoice, then create its sub items...
    const payload = { ...values };
    let callResult = await makePostApiCall("createReceivingInvoice", payload);

    if (callResult.success) {
      setInvoice(callResult.data.receiving_invoice);
      return callResult;
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
      showErrorModal(callResult.data.message);
    }
    return callResult;
  };

  const getAllAssemblyItems = async function () {
    let callResult = await makePostApiCall(`allAssemblyItems`);

    if (callResult.success) {
      return callResult;
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const onGenerateBarcodePdf = async function (values) {
    const payload = { ...values };

    let callResult = await makePostApiCall("createBarcodePdf", payload);

    if (callResult.success) {
      setBarcodeDetails(callResult.data);
      return callResult;
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      } else {
        setShowSimpleNotification({
          show: true,
          title: "Please fill out all fields",
          type: BannerNotificationType.Error,
        });
      }
      return callResult;
    }
  };

  const getAssemblyItemByPlu = async function (plu) {
    const payload = { plu: plu };
    let callResult = await makePostApiCall(`assemblyItems/plu/${plu}`, payload);

    if (callResult.success) {
      return callResult.data;
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
      showErrorModal(callResult.data.message);
      return false;
    }
  };

  const showErrorModal = (subtitle) => {
    setEmptyModelOpen({
      childComponent: (
        <ModelErrorComponent
          setOpen={setEmptyModelOpen}
          subtitle={subtitle}
          title="Error"
        />
      ),
      open: true,
    });
  };

  const updateReceivingAssemblyCall = async function (values, id) {
    let callResult = await makePostApiCall(
      `updateReceivingAssemblyItem/${id}`,
      values
    );

    if (callResult.success) {
      return callResult.data;
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
      showErrorModal(callResult.data.message);
      return false;
    }
  };

  const openReceivingItemDetails = async function (receivingItemId) {
    let callResult = await makePostApiCall(`receivingItem/${receivingItemId}`);

    if (callResult.success) {
      setSlideOver({
        childComponent: (
          <ReceivingAssemblyItemDetails
            onGenerateBarcodePdf={onGenerateBarcodePdf}
            receivingItem={callResult.data.receiving_item}
            setOpen={setSlideOver}
            updateReceivingAssemblyCall={updateReceivingAssemblyCall}
          />
        ),
        open: true,
      });
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const deleteReceivingItem = async function (receivingItemId) {
    let callResult = await makePostApiCall(
      `deleteReceivingItem/${receivingItemId}`
    );

    if (callResult.success) {
      const filtereditems = invoiceItems.filter(
        (item) => item.receiving_item_id !== receivingItemId
      );
      setInvoiceItems(filtereditems);

      setShowSimpleNotification({
        show: true,
        title: callResult.data.message,
        type: BannerNotificationType.Success,
      });
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const getEmployeesForDropdown = async function () {
    const payload = { department: [1, 4], type: [1, 2] };
    let callResult = await makePostApiCall(`employeesForDropdown`, payload);

    if (callResult.success) {
      setEmployeeOptions(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const getReceivingInvoiceItems = async function (invoiceId) {
    let callResult = await makePostApiCall(
      `getReceivingInvoiceItems/${invoiceId}`
    );
    if (callResult.success) {
      setInvoiceItems(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  return {
    addItemToReceivingInvoice,
    barcodeDetails,
    createReceivingInvoice,
    getAllAssemblyItems,
    getAssemblyItemByPlu,
    invoice,
    invoiceItems,
    onGenerateBarcodePdf,
    productTypeOptions,
    claimsOptions,
    allergensOptions,
    loading,
    setSlideOver,
    setShowSimpleNotification,
    slideOver,
    showSimpleNotification,
    setEmptyModelOpen,
    emptyModal,
    showErrorModal,
    openReceivingItemDetails,
    deleteReceivingItem,
    getEmployeesForDropdown,
    employeeOptions,
    getReceivingInvoiceItems,
  };
}
