import { useEffect, useState } from "react";
import { useForm } from "../../useForm";

import { Dialog } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import SelectMenu from "../../../controls/SelectMenu";

import Input from "../../../controls/Input";
import { classNames } from "../../../../shared/utility";

const conditionTypes = [
  { value: 0, label: "Choose Condition" },
  { value: 1, label: "Satisfactory" },
  { value: 2, label: "NOT Satisfactory" },
];

const initialFValues = {};
export default function ReceivingAssemblyItemDetails(props) {
  const {
    onGenerateBarcodePdf,
    receivingItem,
    setOpen,
    updateReceivingAssemblyCall,
  } = props;

  const [createBarcodeInProgress, setCreateBarcodeInProgress] = useState(false);
  const [updateInProgress, setUpdateInProgress] = useState(false);

  const { values, setValues, errors, handleInputChange } = useForm(
    initialFValues,
    false
  );

  const handleSubmit = async function (e) {
    e.preventDefault();

    setUpdateInProgress(true);
    await updateReceivingAssemblyCall(values, values.receiving_item_id);
    setUpdateInProgress(false);
  };

  useEffect(() => {
    setValues(receivingItem);
  }, [receivingItem, setValues]);

  const generateBarcodeStringClicked = async function () {
    setCreateBarcodeInProgress(true);
    const callResult = await onGenerateBarcodePdf({
      receiving_item_id: values.receiving_item_id,
    });
    setCreateBarcodeInProgress(false);

    if (callResult.success) {
      setTimeout(() => {
        window.print();
      }, 1500);
    }
  };

  return (
    <div className="hide-for-print flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
      <div className="flex min-h-0 flex-1 flex-col overflow-y-scroll py-6">
        <div className="px-4 sm:px-6">
          <div className="flex items-start justify-between">
            <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
              Invoice details
            </Dialog.Title>
            <div className="ml-3 flex h-7 items-center">
              <button
                type="button"
                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                onClick={() => setOpen({ childComponent: null, open: false })}
              >
                <span className="sr-only">Close panel</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
        <div className="relative mt-6 flex-1 px-4 sm:px-6">
          <div className="grid grid-cols-1 gap-4">
            <div>
              <Input
                label="Barcode"
                name="barcode"
                onChange={handleInputChange}
                value={values.barcode}
                labelOn={true}
                disabled={true}
                type="text"
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
              />
            </div>
          </div>
          <div className="mt-3 grid grid-cols-2 gap-4">
            <div>
              <Input
                label="Product name"
                name="product_name"
                onChange={handleInputChange}
                value={values.product_name}
                labelOn={true}
                type="text"
                disabled={true}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
              />
            </div>
            <div>
              <Input
                label="Manufacturer"
                name="manufacturer_name"
                onChange={handleInputChange}
                value={values.manufacturer_name}
                labelOn={true}
                disabled={true}
                type="text"
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
              />
            </div>
          </div>

          <div
            className={classNames(
              values.isBarcode === 2 ? "hidden" : null,
              "mt-3 grid grid-cols-2 gap-4"
            )}
          >
            <div>
              <Input
                label="Product lot"
                name="product_lot"
                onChange={handleInputChange}
                value={values.product_lot}
                labelOn={true}
                type="text"
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                error={errors.product_lot}
              />
            </div>
          </div>
          <div className="mt-3 grid grid-cols-4 gap-4">
            <div>
              <Input
                label="Unit cost"
                name="unit_cost"
                onChange={handleInputChange}
                value={values.unit_cost}
                labelOn={true}
                type="text"
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                error={errors.unit_cost}
              />
            </div>
            <div>
              <Input
                label="Total unit"
                name="total_unit"
                onChange={handleInputChange}
                value={values.total_unit}
                labelOn={true}
                type="text"
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                error={errors.total_unit}
              />
            </div>
            <div>
              <Input
                label="Pack unit"
                name="pack_unit"
                onChange={handleInputChange}
                value={values.pack_unit}
                disabled={true}
                labelOn={true}
                type="text"
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                error={errors.pack_unit}
              />
            </div>
            <div>
              <Input
                label="Unit of measure"
                name="unit_of_measure"
                onChange={handleInputChange}
                value={values.unit_of_measure}
                disabled={true}
                labelOn={true}
                type="text"
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                error={errors.unit_of_measure}
              />
            </div>
          </div>
          <div className="mt-3 grid grid-cols-3 gap-4">
            <div>
              <Input
                label="Product Temperature"
                name="product_temperature"
                onChange={handleInputChange}
                value={values.product_temperature}
                labelOn={true}
                type="text"
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                error={errors.product_temperature}
              />
            </div>
            <div>
              <SelectMenu
                handleInputChange={handleInputChange}
                name="product_condition"
                options={conditionTypes}
                defaultValue={values.product_condition}
                value={values.product_condition}
                title="Product Condition"
                error={errors.product_condition}
              />
            </div>
            <div>
              <SelectMenu
                handleInputChange={handleInputChange}
                name="product_quality"
                options={conditionTypes}
                defaultValue={values.product_quality}
                value={values.product_quality}
                title="Product quality"
                error={errors.product_quality}
              />
            </div>
          </div>
          <div className="mt-3 grid grid-cols-2 gap-4">
            <div>
              <SelectMenu
                handleInputChange={handleInputChange}
                name="pallet_condition"
                options={conditionTypes}
                defaultValue={values.pallet_condition}
                value={values.pallet_condition}
                title="Pallet Condition"
                error={errors.pallet_condition}
              />
            </div>
          </div>
          <div className="mt-3 grid grid-cols-2 gap-4">
            <button
              type="button"
              className="print-button rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400"
              onClick={() => generateBarcodeStringClicked()}
            >
              {createBarcodeInProgress ? (
                <svg
                  className="animate-spin -ml-1 mr-3 h-5 w-5 text-black"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              ) : (
                "Print barcode"
              )}
            </button>
          </div>
        </div>
      </div>
      <div className="flex flex-shrink-0 justify-end px-4 py-4">
        <button
          type="button"
          className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400"
          onClick={() => setOpen({ childComponent: null, open: false })}
        >
          Cancel
        </button>
        <button
          type="submit"
          className="ml-4 inline-flex justify-center rounded-md bg-main-purple px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          onClick={handleSubmit}
          disabled={updateInProgress}
        >
          {updateInProgress ? (
            <svg
              className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          ) : null}
          Save
        </button>
      </div>
    </div>
  );
}
