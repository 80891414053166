import { useState } from "react";

import { makeGetApiCall, makePostApiCall } from "../../../shared/makeApiCall";
import { BannerNotificationType } from "../../../components/SimpleBanner";
import ExportLabelDialog from "../../../components/forms/qaReports/exportLabel/ExportLabelDialog";

export function ExportLabelViewModel(logout) {
  const [emptyModal, setEmptyModelOpen] = useState({
    open: false,
    childComponent: null,
  });
  const [loading, setLoading] = useState(false);
  const [showSimpleNotification, setShowSimpleNotification] = useState({
    show: false,
    message: null,
    title: null,
    type: BannerNotificationType.Success,
  });
  const [exportImage, setExportImage] = useState({ image: null });
  const [label, setLabel] = useState(null);

  const printLabel = (newLabel) => {
    setLabel(newLabel);

    setTimeout(function () {
      window.print();
    }, 125);
  };

  const openExportLabelDialog = async function () {
    setEmptyModelOpen({
      open: true,
      childComponent: (
        <ExportLabelDialog
          certImage={exportImage.image}
          printLabel={printLabel}
        />
      ),
    });
  };

  const getExportLabelImage = async function () {
    let callResult = await makeGetApiCall("media/2");
    setLoading(false);
    if (callResult.success) {
      setExportImage(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  return {
    emptyModal,
    loading,
    setEmptyModelOpen,
    setShowSimpleNotification,
    showSimpleNotification,
    openExportLabelDialog,
    exportImage,
    getExportLabelImage,
    label,
  };
}
