import React, { useEffect } from "react";

import { useForm } from "../../useForm";

import Input from "../../../controls/Input";
import { convertToTwoDecimals } from "../../../../shared/utility";

const initialFValues = {
  total_batch: "",
  spice_unit: "",
  nitrate: "",
  nitrate_result: "",
  phosphate: "",
  phosphate_result: "",
};

export default function NitrateAndPhosphateCalculator(props) {
  const { actionCall, manufacturedProduct } = props;

  const validate = (fieldValues = values, isSubmit = true) => {
    let temp = { ...errors };

    if ("total_batch" in fieldValues) {
      temp.total_batch = null;
      if (fieldValues.total_batch === "" || isNaN(fieldValues.total_batch)) {
        temp.total_batch = "This field is required.";
      }
    }

    if ("spice_unit" in fieldValues) {
      temp.spice_unit = null;
      if (fieldValues.spice_unit === "" || isNaN(fieldValues.spice_unit)) {
        temp.spice_unit = "This field is required.";
      }
    }

    if ("nitrate" in fieldValues) {
      temp.nitrate = null;
      if (fieldValues.nitrate === "" || isNaN(fieldValues.nitrate)) {
        temp.nitrate = "This field is required.";
      }
    }

    if ("phosphate" in fieldValues) {
      temp.phosphate = null;
      if (fieldValues.phosphate === "" || isNaN(fieldValues.phosphate)) {
        temp.phosphate = "This field is required.";
      }
    }

    if (isSubmit) {
      if ("nitrate_result" in fieldValues) {
        temp.nitrate_result = null;
        if (fieldValues.nitrate_result === "") {
          temp.nitrate_result = "This field is required.";
        }
      }

      if ("phosphate_result" in fieldValues) {
        temp.phosphate_result = null;
        if (fieldValues.phosphate_result === "") {
          temp.phosphate_result = "This field is required.";
        }
      }
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === null);
    }
  };

  const { values, setValues, errors, setErrors, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );

  function onCalculate() {
    if (validate(values, false)) {
      var nitrateResult =
        convertToTwoDecimals(
          (((values.nitrate * 1000 * values.spice_unit) / 100) * 1000) /
            values.total_batch
        ) + "ppm";
      var phosphateResult =
        ((values.phosphate * values.spice_unit) / values.total_batch).toFixed(
          3
        ) + "%"; // make this 3 decim
      setValues({
        ...values,
        nitrate_result: nitrateResult,
        phosphate_result: phosphateResult,
      });
    } else {
    }
  }

  return (
    <div className="justify-between">
      <div>
        <div className="flex items-center justify-between flex-wrap sm:flex-nowrap">
          <div className="mt-2">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Calculations for Sodium Nitrate and Sodum Phosphate
            </h3>
          </div>
        </div>

        {/* here */}
        <div className="pt-2 pb-5 gap-4">
          <div className="bg-white sm:rounded-xl">
            <div className="grid grid-cols-2 gap-4">
              <Input
                label="Total Batch"
                name="total_batch"
                onChange={handleInputChange}
                value={values.total_batch}
                labelOn={true}
                type="text"
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                error={errors.total_batch}
              />
              <Input
                label="Spice Unit"
                name="spice_unit"
                onChange={handleInputChange}
                value={values.spice_unit}
                labelOn={true}
                type="text"
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                error={errors.spice_unit}
              />
            </div>
            <div className="mt-3 grid grid-cols-2 gap-4">
              <Input
                label="Nitrate"
                name="nitrate"
                onChange={handleInputChange}
                value={values.nitrate}
                labelOn={true}
                type="text"
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                error={errors.nitrate}
              />
              <Input
                label="Result"
                name="nitrate_result"
                onChange={handleInputChange}
                value={values.nitrate_result}
                labelOn={true}
                type="text"
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                error={errors.nitrate_result}
              />
            </div>

            <div className="mt-3 grid grid-cols-2 gap-4">
              <Input
                label="Phosphate"
                name="phosphate"
                onChange={handleInputChange}
                value={values.phosphate}
                labelOn={true}
                type="text"
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                error={errors.phosphate}
              />
              <Input
                label="Result"
                name="phosphate_result"
                onChange={handleInputChange}
                value={values.phosphate_result}
                labelOn={true}
                type="text"
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                error={errors.phosphate_result}
              />
            </div>
          </div>

          <div className="sm:flex-no-wrap space-y-2">
            <div className="my-auto flex items-center">
              <button
                onClick={() => onCalculate()}
                className="inline-flex justify-center mt-3 py-35-custom px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-main-purple hover:bg-indigo-700 items-center"
              >
                Calculate
              </button>
              <button
                onClick={() => {
                  if (validate()) {
                    actionCall(values);
                  }
                }}
                className="ml-2 inline-flex justify-center mt-3 py-35-custom px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 items-center"
              >
                Print
              </button>
            </div>
            <p className="">
              Minimum levels of nitrate (100 ppm) - Maximum allowable limit (200
              ppm)
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
