import React, { useRef } from "react";
import { Dialog } from "@headlessui/react";

import { ExclamationCircleIcon } from "@heroicons/react/24/outline";

export default function DeleteConfirmationForm(props) {
  const { buttonMessage, confirmAction, message, setOpen, title } = props;

  const cancelButtonRef = useRef(null);

  return (
    <div>
      <div>
        <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100">
          <ExclamationCircleIcon
            className="h-11 w-11 text-red-600"
            aria-hidden="true"
          />
        </div>
        <div className="mt-3 text-center sm:mt-5">
          <Dialog.Title
            as="h3"
            className="text-base font-normal leading-6 text-gray-800 family-didact"
          >
            {title}
          </Dialog.Title>
          <div className="mt-2">
            <p className="text-sm text-gray-500 family-didact">{message}</p>
          </div>
        </div>
      </div>
      <div className="mt-5 sm:mt-6 flex items-center justify-center sm:grid sm:grid-flow-row-dense sm:grid-cols-2 gap-3">
        <div className="col-span-1 text-right">
          <button
            type="button"
            className="inline-flex w-fit justify-center rounded-md bg-white px-3 py-2 text-sm font-light family-didact text-gray-900 shadow-sm hover:bg-gray-50 shadow-sm ring-1 ring-inset ring-gray-300"
            onClick={() => setOpen({ childComponent: null, open: false })}
          >
            Cancel
          </button>
        </div>
        <div className="col-span-1">
          <button
            type="button"
            className="inline-flex w-fit justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-light family-didact text-white shadow-sm hover:bg-red-500 col-span-1 sm:mt-0"
            onClick={confirmAction}
            ref={cancelButtonRef}
          >
            {buttonMessage}
          </button>
        </div>
      </div>
    </div>
  );
}
