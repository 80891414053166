import { Dialog } from "@headlessui/react";

import { XMarkIcon } from "@heroicons/react/24/outline";

export default function ModelErrorComponent(props) {
  const { setOpen, subtitle, title } = props;

  return (
    <div>
      <div>
        {/* <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100">
          <XMarkIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
        </div> */}
        <div className="mt-3 text-center sm:mt-5">
          <Dialog.Title
            as="h3"
            className="text-base font-semibold leading-6 text-gray-900"
          >
            {title}
          </Dialog.Title>
          <div className="mt-2">
            <p className="text-sm text-gray-500">{subtitle}</p>
          </div>
        </div>
      </div>
      <div className="mt-5 sm:mt-6 text-center">
        <button
          type="button"
          className="inline-flex w-24 justify-center rounded-md bg-main-purple px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-600"
          onClick={() =>
            setOpen({
              childComponent: null,
              open: false,
            })
          }
        >
          OK
        </button>
      </div>
    </div>
  );
}
