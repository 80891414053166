import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import { classNames, convertToDefEventPara } from "../../shared/utility";
import { BackspaceIcon } from "@heroicons/react/24/outline";
import Input from "./Input";

export default function WeightScale(props) {
  const { newWeight } = props;

  return (
    <div>
      <Input
        name="scale_weight" // not used anywhere
        value={newWeight}
        labelOn={false}
        type="text"
        className="scale-digital-font mt-1 block w-full border border-gray-300 text-white rounded-md shadow-sm py-6 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-7xl bg-gray-500"
        //error={errors.supervisor}
      />
    </div>
  );
}
