import React, { useRef } from "react";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";

export default function InvoiceRecordsSearch(props) {
  const { getReceivingInvoiceIndex } = props;

  var currentPage = 1;

  const searchRef = useRef();

  const handleSearch = () => {
    getReceivingInvoiceIndex(searchRef.current.value, currentPage);
  };

  const handlePagination = (newPage) => {
    currentPage = newPage;
    getReceivingInvoiceIndex(searchRef.current.value, newPage);
  };

  return (
    <div className="hide-for-print col-span-2">
      <div className="mt-2 sm:flex sm:items-center bg-white px-4 py-1 border-b border-gray-200 sm:px-4 rounded-xl">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900 my-auto">
            Receiving Index
          </h1>
          <div className="mt-3 flex rounded-md w-128">
            <div className="relative flex items-stretch flex-grow focus-within:z-10">
              <input
                type="search"
                name="searchInput"
                id="searchInput"
                className="block w-full rounded-lg rounded-l-md pl-2 sm:text-sm border border-gray-300 rounded-tr-none rounded-br-none"
                placeholder="Search by invoice #, product name, manufacturer or supplier"
                ref={searchRef}
                onKeyDown={(e) => {
                  if (e.key === "Enter") handleSearch();
                }}
              />
            </div>
            <button
              type="button"
              className="-ml-px relative inline-flex items-center space-x-2 px-4 py-1 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1"
              onClick={() => handleSearch()}
            >
              <MagnifyingGlassIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
