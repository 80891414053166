import React from "react";

import Input from "../../../controls/Input";
import SelectMenu from "../../../controls/SelectMenu";
import { yesOrNoOptions } from "../../../../shared/utility";

export default function ManufactureNewDetails2(props) {
  const { isEdit, handleInputChange, values, manufacturedProduct } = props;

  return (
    <div className="p-4 card-custom">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
        <div className="col-span-2">
          <Input
            label="Plu"
            name="plu"
            onChange={handleInputChange}
            value={values.plu}
            labelOn={true}
            type="text"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-1 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            //error={errors.plu}
          />
        </div>
        <div className="col-span-2">
          <Input
            label="Product Name"
            name="product_name"
            onChange={handleInputChange}
            value={values.product_name}
            labelOn={true}
            type="text"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-1 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            //error={errors.product_name}
          />
        </div>
        <div className="col-span-2">
          <Input
            label="Description"
            name="description_en"
            onChange={handleInputChange}
            value={values.description_en}
            labelOn={true}
            type="text"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-1 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            //error={errors.description_en}
          />
        </div>
        <div className="col-span-2">
          <Input
            label="Lot Number"
            name="new_lot_number"
            onChange={handleInputChange}
            value={values.new_lot_number}
            labelOn={true}
            type="text"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-1 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            //error={errors.new_lot_number}
          />
        </div>
        <div className="col-span-1">
          <SelectMenu
            handleInputChange={handleInputChange}
            name="export_eligible"
            options={yesOrNoOptions}
            defaultValue={isEdit ? manufacturedProduct.export_eligible : null}
            title={"Export Eligible"}
            showLabel={true}
          />
        </div>
      </div>
    </div>
  );
}
