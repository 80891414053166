import { useEffect, useRef, useState } from "react";
import { AllReceivingInvoicesViewModel } from "../../../viewmodels/receiving/invoices/AllReceivingInvoicesViewModel";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";

import { connect } from "react-redux";

import * as actions from "../../../store/actions/index";

import { formatDate, withRouter } from "../../../shared/utility";

import EmptyModal from "../../../components/modals/EmptyModal";
import SlideOver from "../../../components/slideOver/SlideOver";
import SimpleBanner from "../../../components/SimpleBanner";

import { useNavigate } from "react-router-dom";

import { Paginator } from "primereact/paginator";
import ReportsDropdown from "../../../components/filterDropdown/ReportsDropdown";

function AllReceivingInvoices(props) {
  const { logout } = props;
  const {
    askDeleteReceivingInvoice,
    emptyModal,
    getReceivingInvoices,
    loading,
    receivingInvoices,
    setSlideOver,
    slideOver,
    setEmptyModelOpen,
    setShowSimpleNotification,
    showSimpleNotification,
    generateReceivingReport,
    reportComponents,
    showReports,
    setShowReports,
    reportInProgress,
  } = AllReceivingInvoicesViewModel(logout);

  const navigate = useNavigate();

  var currentPage = 1;
  useEffect(() => {
    getReceivingInvoices(null, currentPage);
  }, []);

  const [first, setFirst] = useState(1);
  const [rows, setRows] = useState(20);

  const searchRef = useRef();

  const handleSearch = () => {
    getReceivingInvoices(searchRef.current.value, currentPage);
  };

  const handlePagination = (event) => {
    currentPage = event.page + 1;
    setFirst(event.first);
    setRows(event.rows);
    getReceivingInvoices(searchRef.current.value, currentPage);
  };

  function navigateTo(target) {
    navigate(target);
  }

  return (
    <div>
      <SimpleBanner
        setShow={setShowSimpleNotification}
        showSimpleNotification={showSimpleNotification}
      />
      <div className="mt-2">
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="sm:flex sm:items-center px-4 py-5 sm:px-4 card-custom">
            <div className="sm:flex-auto">
              <h1 className="text-base font-semibold leading-6 text-gray-900">
                Receiving Invoices
              </h1>
              <div className="mt-5 flex rounded-md">
                <div className="relative flex items-stretch flex-grow focus-within:z-10">
                  <input
                    type="search"
                    name="searchReceivingInvoice"
                    id="searchReceivingInvoice"
                    className="block w-full rounded-lg rounded-l-md pl-2 sm:text-sm border border-gray-300 rounded-tr-none rounded-br-none"
                    placeholder="Search by manufacturer name"
                    ref={searchRef}
                    onKeyDown={(e) =>
                      e.key === "Enter" ? handleSearch() : null
                    }
                  />
                </div>
                <button
                  type="button"
                  className="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1"
                  onClick={() => handleSearch()}
                >
                  <MagnifyingGlassIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </button>

                <ReportsDropdown
                  actionCall={generateReceivingReport}
                  reportComponents={reportComponents}
                  showReports={showReports}
                  setShowReports={setShowReports}
                  title="Reports"
                  reportInProgress={reportInProgress}
                />

                <div>
                  <button
                    className="ml-2 rounded-md bg-main-purple px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    onClick={() => navigateTo("/receiving-invoices/new")}
                  >
                    Add receiving
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-8 flow-root">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                {loading ? (
                  <div>
                    <div className="border h-10 shadow rounded-md p-4 w-full mx-auto">
                      <div className="animate-pulse flex space-x-4">
                        Loading
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="overflow-hidden card-custom">
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead className="bg-white">
                        <tr>
                          <th
                            scope="col"
                            className="py-3 pl-3 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                          >
                            Invoice #
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3 text-left text-sm font-semibold text-gray-900"
                          >
                            Supplier
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3 text-left text-sm font-semibold text-gray-900"
                          >
                            Date
                          </th>
                          <th
                            scope="col"
                            className="relative py-3 pl-3 pr-4 sm:pr-6"
                          ></th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {receivingInvoices.data.length > 0 ? (
                          receivingInvoices.data.map(
                            (receivingInvoice, index) => (
                              <tr key={receivingInvoice.id}>
                                <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                  {receivingInvoice.invoice_number}
                                </td>
                                <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                                  {receivingInvoice.supplier_name}
                                </td>
                                <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                                  {formatDate(receivingInvoice.date)}
                                </td>
                                <td className="relative whitespace-nowrap py-2 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                  <button
                                    className="ml-5 rounded-md bg-main-purple px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                    onClick={() =>
                                      navigateTo(
                                        "/receiving-invoices/" +
                                          receivingInvoice.id
                                      )
                                    }
                                  >
                                    Edit
                                  </button>
                                  <button
                                    onClick={() => {
                                      askDeleteReceivingInvoice(
                                        receivingInvoice,
                                        index
                                      );
                                    }}
                                    className="ml-2 rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                                  >
                                    Delete
                                  </button>
                                </td>
                              </tr>
                            )
                          )
                        ) : (
                          <tr>
                            <td
                              className="whitespace-nowrap py-3 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 text-center"
                              colSpan={4}
                            >
                              No receiving found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
              <div className="w-full">
                <Paginator
                  first={first}
                  rows={rows}
                  totalRecords={receivingInvoices.total}
                  onPageChange={handlePagination}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <EmptyModal
        childComponent={emptyModal.childComponent}
        open={emptyModal.open}
        setOpen={setEmptyModelOpen}
      />
      <SlideOver
        childComponent={slideOver.childComponent}
        slideOver={slideOver}
        setSlideOver={setSlideOver}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(actions.logout()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AllReceivingInvoices)
);
