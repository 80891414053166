import { useEffect } from "react";
import { DashboardViewModel } from "../../viewmodels/dashboard/DashboardViewModel";

import OpenOrdersCard from "./cards/OpenOrdersCard";
import ShippedOrdersCard from "./cards/ShippedOrdersCard";
import InStockTwoMonthsCard from "./cards/InStockTwoMonthsCard";
import RecentProductionCard from "./cards/RecentProductionCard";
import RecentlyPackagedCard from "./cards/RecentlyPackagedCard";
import LowRawInventoryCard from "./cards/LowRawInventoryCard";
import SummaryCards from "./summaryCards/SummaryCards";
import BelowMaxThresholdCard from "./cards/BelowMaxThresholdCard";

export default function Dashboard(props) {
  const { logout } = props;

  const {
    getInfo1,
    getInfo2,
    getInfo3,
    getInfo4,
    getInfo5,
    getInfo6,
    getInfo7,
    info1,
    info2,
    info3,
    info4,
    info5,
    info6,
    info7,
    info1Loading,
    info2Loading,
    info3Loading,
    info4Loading,
    info5Loading,
    info6Loading,
    info7Loading,
  } = DashboardViewModel(logout);

  useEffect(() => {
    // getInfo1();
    // getInfo2();
    // getInfo3();
    // getInfo4();
    // getInfo5();
    // getInfo6();
    // getInfo7();
  }, []);

  return (
    <div>
      <div className="mt-2 pb-4 px-4 sm:px-6 lg:px-8">
        <div>{/* <SummaryCards /> */}</div>
        <div className="mt-8">
          <ul className="grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8">
            {/* <InStockTwoMonthsCard info={info3} loading={info3Loading} />
            <RecentProductionCard info={info4} loading={info4Loading} />
            <RecentlyPackagedCard info={info5} loading={info5Loading} />
            <LowRawInventoryCard info={info6} loading={info6Loading} />
            <BelowMaxThresholdCard info={info7} loading={info7Loading} />
            <OpenOrdersCard info={info1} loading={info1Loading} />
            <ShippedOrdersCard info={info2} loading={info2Loading} /> */}
          </ul>
        </div>
      </div>
    </div>
  );
}
