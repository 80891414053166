import React from "react";

export default function ListOfBrandsTable(props) {
  const { brands, setItemValues } = props;

  return (
    <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
      <div className="min-h-0 overflow-y-scroll py-6 grid grid-cols-2 gap-2 p-5">
        <div className="col-span-2">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            Please select a brand
          </h1>
        </div>
        {brands.data.map((brand, index) => (
          <div key={index} onClick={() => setItemValues(brand.id, brand.name)}>
            <img
              src={brand.image1}
              alt={brand.name}
              title={brand.name}
              className="mx-auto media-image rounded-md"
              width="100"
              height="100"
            />
          </div>
        ))}
      </div>
    </div>
  );
}
