import React from "react";

import Input from "../../controls/Input";

export default function CreateRecipePackagingTable(props) {
  const { askDeleteRecipe, packagingItems, openUpdateIngredientQuantity } =
    props;

  return (
    <div className="flex min-h-0 flex-1 flex-col overflow-y-scroll py-2 px-1">
      <div>
        <div className="flex items-start justify-between">
          <h3 className="text-base font-semibold leading-6 text-gray-900">
            Other items
          </h3>
          <div className="ml-3 flex h-7 items-center"></div>
        </div>
      </div>
      <div className="relative mt-2 flex-1">
        <div id="product-table" className="overflow-hidden card-custom">
          <table className="min-w-full divide-y divide-gray-300">
            <thead className="bg-white">
              <tr>
                <th
                  scope="col"
                  className="py-3 pl-3 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                >
                  Plu
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Name
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Quantity
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Unit of measure
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Recipe/kg
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                ></th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {packagingItems.map((packagingItem, index) => (
                <tr key={packagingItem.id} className="cursor-pointer">
                  <td className="whitespace-wrap py-35-custom pl-4 pr-3 text-sm font-medium text-gray-500 sm:pl-6">
                    {packagingItem.bom_plu}
                  </td>
                  <td className="whitespace-wrap px-3 py-35-custom text-sm text-gray-500">
                    {packagingItem.bom_name}
                  </td>
                  <td
                    className="whitespace-wrap px-3 py-35-custom text-sm text-gray-500"
                    onClick={() => {
                      openUpdateIngredientQuantity(packagingItem, index);
                    }}
                  >
                    <div className="block w-20 border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm bg-gray-50">
                      <p className="mt-1.5">{packagingItem.quantity}</p>
                    </div>
                  </td>
                  <td className="whitespace-wrap px-3 py-35-custom text-sm text-gray-500">
                    <div className="block w-20 border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm bg-gray-50">
                      <p className="mt-1.5">
                        {packagingItem.unit_of_measure_label}
                      </p>
                    </div>
                  </td>
                  <td className="whitespace-wrap px-3 py-35-custom text-sm text-gray-500">
                    <Input
                      value={packagingItem.recipe_per_kg}
                      labelOn={false}
                      disabled={true}
                      type="text"
                      className="block w-24 border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                      // error={errors.quantity}
                    />
                  </td>
                  <td className="relative whitespace-wrap py-1.5 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                    <button
                      onClick={() => {
                        askDeleteRecipe(packagingItem, 1);
                      }}
                      className="ml-2 rounded-md bg-red-600 px-3 py-35-custom text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
