import { useState } from "react";

import { makePostApiCall, makePutApiCall } from "../../shared/makeApiCall";
import { BannerNotificationType } from "../../components/SimpleBanner";
import DeleteConfirmationForm from "../../components/forms/DeleteConfirmationForm";
import ImportMediaCsvDropzone from "../../components/importMedia/ImportMediaCsvDropzone";
import CostcoSkuForm from "../../components/forms/setup/costcoSku/CostcoSkusForm";
import SelectMenu from "../../components/controls/SelectMenu";
import ModelErrorComponent from "../../components/modals/ModelErrorComponent";

export function CostcoPlusViewModel(logout) {
  const [emptyModal, setEmptyModelOpen] = useState({
    open: false,
    childComponent: null,
  });
  const [loading, setLoading] = useState(false);
  const [skus, setSkus] = useState({ data: [] });
  var orderType = -1;

  const [showSimpleNotification, setShowSimpleNotification] = useState({
    show: false,
    message: null,
    title: null,
    type: BannerNotificationType.Success,
  });
  const [slideOver, setSlideOver] = useState({
    childComponent: null,
    open: false,
  });

  const getSkus = async function (search, page) {
    setLoading(true);
    const payload = {
      page: page,
      search: search,
    };
    let callResult = await makePostApiCall(`costcoSkus`, payload);
    setLoading(false);
    if (callResult.success) {
      setSkus(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const createSku = async function (values) {
    const payload = { ...values };
    let callResult = await makePostApiCall("createCostcoSku", payload);

    if (callResult.success) {
      setShowSimpleNotification({
        show: true,
        title: "Import Sku added",
        type: BannerNotificationType.Success,
      });
      setSlideOver({
        childComponent: null,
        open: false,
      });
      setTimeout(function () {
        getSkus("", 1);
      }, 1000);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const updateSku = async function (sku, values, index) {
    let callResult = await makePutApiCall(`costcoSkus/${sku.id}`, values);

    if (callResult.success) {
      setShowSimpleNotification({
        show: true,
        title: "Import Sku updated",
        type: BannerNotificationType.Success,
      });
      setSlideOver({
        childComponent: null,
        open: false,
      });
      setTimeout(function () {
        getSkus("", 1);
      }, 1000);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const openAddSkuSlideOver = () => {
    setSlideOver({
      childComponent: (
        <CostcoSkuForm
          isEdit={false}
          setOpen={setSlideOver}
          actionCall={createSku}
        />
      ),
      open: true,
    });
  };

  const viewSkuDetails = async function (sku, index) {
    setSlideOver({
      childComponent: (
        <CostcoSkuForm
          sku={sku}
          index={index}
          isEdit={true}
          setOpen={setSlideOver}
          actionCall={updateSku}
        />
      ),
      open: true,
    });
  };

  const deleteCostcoSku = async function (id, index) {
    setLoading(true);

    let callResult = await makePostApiCall(`deleteCostcoSku/${id}`, {});
    setLoading(false);
    if (callResult.success) {
      setEmptyModelOpen({
        open: false,
        childComponent: null,
      });
      getSkus(null, 0);
      setShowSimpleNotification({
        show: true,
        title: callResult.data.message,
        type: BannerNotificationType.Success,
      });
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const askdeleteCostcoSku = function (sku, index) {
    setEmptyModelOpen({
      childComponent: (
        <DeleteConfirmationForm
          buttonMessage="Delete"
          confirmAction={() => deleteCostcoSku(sku.id, index)}
          message={`Are you sure you want to delete  ${sku.costco_sku}?`}
          setOpen={setEmptyModelOpen}
        />
      ),
      open: true,
    });
  };

  const showErrorModal = (title, subtitle) => {
    setEmptyModelOpen({
      childComponent: (
        <ModelErrorComponent
          setOpen={setEmptyModelOpen}
          subtitle={subtitle}
          title={title}
        />
      ),
      open: true,
    });
  };

  const uploadOrderCsv = async function (uploadedFile, passedOrderType) {
    if (orderType >= 0) {
      const { name, value } = uploadedFile.target;
      const payload = { [name]: value, orderType: passedOrderType };
      let callResult = await makePostApiCall(
        "importSpsOrder",
        payload,
        "multipart/form-data"
      );

      if (callResult.success) {
        window.location = `/orders/${callResult.data.order_id}`;
      } else {
        if (callResult.errorStatus === 401) {
          logout();
        }
      }
    } else {
      showErrorModal("Please select an order type");
    }
  };

  const importOrderOptions = [
    { value: 0, label: "Costco SPS Order" },
    { value: 1, label: "Sun Distribution SPS Order" },
  ];

  const openImportOrder = () => {
    setEmptyModelOpen({
      childComponent: (
        <ImportMediaCsvDropzone
          childComponent={
            <div className="mb-3">
              <SelectMenu
                handleInputChange={(e) => {
                  orderType = e.target.value.value;
                }}
                name="orderType"
                options={importOrderOptions}
              />
            </div>
          }
          fileName="orderCsv"
          actionCall={(file) => uploadOrderCsv(file, orderType)}
          setOpen={setEmptyModelOpen}
          title="Orders"
        />
      ),
      open: true,
    });
  };

  return {
    askdeleteCostcoSku,
    emptyModal,
    getSkus,
    loading,
    skus,
    openAddSkuSlideOver,
    setEmptyModelOpen,
    setSlideOver,
    setShowSimpleNotification,
    slideOver,
    showSimpleNotification,
    viewSkuDetails,
    openImportOrder,
  };
}
