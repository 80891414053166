import { useState } from "react";

import { makePostApiCall } from "../../../shared/makeApiCall";
import { BannerNotificationType } from "../../../components/SimpleBanner";
import InvoiceDetailsForm from "../../../components/forms/receiving/invoices/InvoiceDetailsForm";

export function ReceivingInvoiceIndexViewModel(logout) {
  const [barcodeDetails, setBarcodeDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [receivingIndex, setReceivingIndex] = useState({
    data: [],
  });
  const [showSimpleNotification, setShowSimpleNotification] = useState({
    show: false,
    message: null,
    title: null,
    type: BannerNotificationType.Success,
  });
  const [slideOver, setSlideOver] = useState({
    childComponent: null,
    open: false,
  });

  const getReceivingInvoiceIndex = async function (search, page) {
    setLoading(true);
    const payload = {
      page: page,
      search: search,
    };
    let callResult = await makePostApiCall(`receivingInvoiceIndex`, payload);
    setLoading(false);
    if (callResult.success) {
      setReceivingIndex(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const showInvoiceDetails = async function (invoiceId) {
    let callResult = await makePostApiCall(`receivingInvoices/${invoiceId}`);
    if (callResult.success) {
      setSlideOver({
        childComponent: (
          <InvoiceDetailsForm
            invoice={callResult.data.receiving_invoice}
            isEdit={false}
            setOpen={setSlideOver}
          />
        ),
        open: true,
      });
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const fetchInvoiceDetails = async function (itemId) {
    let callResult = await makePostApiCall(`receivingInvoiceIndex/${itemId}`);
    if (callResult.success) {
      return callResult.data.receiving_invoice;
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const onGenerateBarcodePdf = async function (itemId) {
    const payload = { ...itemId };

    let callResult = await makePostApiCall("createBarcodePdf", payload);

    if (callResult.success) {
      setBarcodeDetails(callResult.data);
      return callResult;
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      } else {
        setShowSimpleNotification({
          show: true,
          title: "Please fill out all fields",
          type: BannerNotificationType.Error,
        });
      }
      return callResult;
    }
  };

  return {
    barcodeDetails,
    fetchInvoiceDetails,
    getReceivingInvoiceIndex,
    loading,
    receivingIndex,
    setSlideOver,
    showInvoiceDetails,
    slideOver,
    setShowSimpleNotification,
    showSimpleNotification,
    onGenerateBarcodePdf,
  };
}
