import React, { useEffect } from "react";

import { useForm } from "../../useForm";

import Input from "../../../controls/Input";

const initialFValues = {
  quantity: "",
};

export default function AddBomToIngredientPopup(props) {
  const { bomItem, confirmAction, index, isEdit, setOpen } = props;

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    setErrors({
      ...temp,
    });

    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === null);
    }
  };

  const { values, setValues, errors, setErrors, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );

  useEffect(() => {
    if (isEdit) {
      setValues({ ...bomItem }, index);
    } else {
      setValues({ ...bomItem, quantity: 0 });
    }
  }, []);

  const onSubmit = () => {
    if (isEdit) {
      confirmAction(values, index);
    } else {
      confirmAction(values);
    }
  };

  return (
    <div className="justify-between">
      <div>
        <div className="flex items-center justify-between flex-wrap sm:flex-nowrap">
          <div className="mt-2">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              {bomItem.name}
            </h3>
          </div>
        </div>

        <div className="py-2 grid grid-cols-2 gap-4">
          <div>
            <Input
              label="Quantity to use"
              name="quantity"
              onChange={handleInputChange}
              value={values.quantity}
              labelOn={true}
              type="number"
              step="0.0"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
              error={errors.quantity}
            />
          </div>
        </div>

        <div className="sm:flex-no-wrap">
          <div className="my-auto flex items-center">
            <button
              onClick={() => onSubmit()}
              className="inline-flex justify-center mt-3 py-35-custom px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-main-purple hover:bg-indigo-700 items-center"
            >
              OK
            </button>
            <button
              onClick={() =>
                setOpen({
                  open: false,
                })
              }
              className="ml-2 inline-flex justify-center mt-3 py-35-custom px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 items-center"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
