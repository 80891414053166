import { classNames, formatDate } from "../../../../shared/utility";

export default function ReceivingBarcodeLabel(props) {
  const { barcodeDetails } = props;

  return (
    <div>
      <div className="hidden label-section-to-print">
        <div className="grid grid-cols-2 my-2 ml-5">
          <div>
            <p className="text-barcode-slip">
              <span className="font-bold">Invoice:</span>
              {barcodeDetails.invoice_number}
            </p>
            <p className="text-barcode-slip">
              <span className="font-bold">Supplier:</span>{" "}
              {barcodeDetails.supplier}
            </p>
            <p className="text-barcode-slip">
              <span className="font-bold">Manufacturer:</span>{" "}
              {barcodeDetails.manufacturer}
            </p>
            <p className="text-barcode-slip">
              <span className="font-bold">Received Date:</span>{" "}
              {formatDate(barcodeDetails.received_date)}
            </p>
            <p className="text-barcode-slip">
              <span className="font-bold">Product Name:</span>{" "}
              {barcodeDetails.product_name}
            </p>
            <p className="text-barcode-slip">
              <span className="font-bold">Total Unit:</span>{" "}
              {barcodeDetails.total_unit}
            </p>
            <p className="text-barcode-slip">
              <span className="font-bold">Lot Number:</span>{" "}
              {barcodeDetails.lot_number}
            </p>
            <p className="text-barcode-slip">
              <span className="font-bold">Received By:</span>{" "}
              {barcodeDetails.received_by}
            </p>
          </div>
          <div>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p
              className={classNames(
                barcodeDetails.pack_unit ? null : "hidden",
                "text-barcode-slip"
              )}
            >
              <span className="font-bold">Pack Unit:</span>{" "}
              {barcodeDetails.pack_unit}
            </p>
            <p></p>
            <p></p>
          </div>
          <div className="col-span-2 mx-5 my-2">
            <img
              src={"data:image/png;base64, " + barcodeDetails.barcodeHtml}
              alt="Supplier item barcode"
              width="100%"
              height="auto"
            />
            <p className="text-barcode-slip text-center">
              {barcodeDetails.barcode}
            </p>
          </div>
          <div className="col-span-2 mb-2">
            <p className="text-barcode-slip">
              {barcodeDetails.certification_type}
            </p>
            <p className="text-barcode-slip">
              ITEM RECEIVED WITHOUT BARCODE INTERNAL USE ONLY
            </p>
            <p className="text-barcode-slip">
              Allergens: {barcodeDetails.allergens}
            </p>
            <p
              className={classNames(
                barcodeDetails.preservatives ? null : "hidden",
                "text-barcode-slip"
              )}
            >
              Preservatives: {barcodeDetails.preservatives}
            </p>
            <p
              className={classNames(
                barcodeDetails.claims ? null : "hidden",
                "text-barcode-slip"
              )}
            >
              Claims: {barcodeDetails.claims}
            </p>
          </div>
          <div className="align-text-bottom">
            <p className="text-barcode-slip">AUTHORIZED PERSONNEL ONLY!</p>
            <p className="text-barcode-slip">Designed by: EGETECH Inc.</p>
          </div>
        </div>
      </div>
    </div>
  );
}
