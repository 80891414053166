import Input from "../controls/Input";

export default function FilterInput(props) {
  const { header, onChange, resetFilter, valueName, value } = props;

  return (
    <div className="p-3">
      <div className="flex justify-between">
        <p className="text-sm text-gray-700 font-semibold">{header}</p>
        <button
          onClick={() => resetFilter()}
          className="text-sm font-semibold main-purple"
        >
          Reset
        </button>
      </div>
      <div className="mt-1">
        <Input
          onChange={(e) => {
            onChange(e.target.value);
          }}
          name={valueName}
          value={value}
        />
      </div>
    </div>
  );
}
