import React, { useEffect } from "react";

import { useForm } from "../../useForm";

import Input from "../../../controls/Input";

const initialFValues = {
  in_stock: "",
  pack_unit: "",
  unit_cost: "",
  product_name: "",
  supplier_name: "",
};

export default function ManualEntryForManufacturingPopup(props) {
  const { confirmAction, setOpen } = props;

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    setErrors({
      ...temp,
    });

    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === null);
    }
  };

  const { values, setValues, errors, setErrors, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );

  const onSubmit = () => {
    if (validate()) {
      confirmAction(values);
    } else {
    }
  };

  return (
    <div className="justify-between">
      <div>
        <div className="flex items-center justify-between flex-wrap sm:flex-nowrap">
          <div className="mt-2">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Manual entry
            </h3>
          </div>
        </div>

        {/* here */}
        <div className="pt-6 pb-5 gap-4">
          <div className="bg-white sm:rounded-xl">
            <div className="mt-3 grid grid-cols-2 gap-4">
              <div>
                <Input
                  label="Supplier"
                  name="supplier_name"
                  onChange={handleInputChange}
                  value={values.supplier_name}
                  labelOn={true}
                  type="text"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                  error={errors.supplier_name}
                />
              </div>
              <div>
                <Input
                  label="Product"
                  name="product_name"
                  onChange={handleInputChange}
                  value={values.product_name}
                  labelOn={true}
                  type="text"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                  error={errors.product_name}
                />
              </div>
            </div>
            <div className="mt-3 grid grid-cols-2 gap-4">
              <div>
                <Input
                  label="Product lot"
                  name="product_lot"
                  onChange={handleInputChange}
                  value={values.product_lot}
                  labelOn={true}
                  type="text"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                  error={errors.product_lot}
                />
              </div>
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <Input
                    label="Used weight"
                    name="used_weight"
                    onChange={handleInputChange}
                    value={values.used_weight}
                    labelOn={true}
                    type="text"
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                    error={errors.used_weight}
                  />
                </div>
                <div>
                  <Input
                    label="Unit price"
                    name="unit_cost"
                    onChange={handleInputChange}
                    value={values.unit_cost}
                    labelOn={true}
                    type="text"
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                    error={errors.unit_cost}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="sm:flex-no-wrap">
            <div className="my-auto flex items-center">
              <button
                onClick={() => onSubmit()}
                className="inline-flex justify-center mt-3 py-35-custom px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-main-purple hover:bg-indigo-700 items-center"
              >
                OK
              </button>
              <button
                onClick={() =>
                  setOpen({
                    open: false,
                  })
                }
                className="ml-2 inline-flex justify-center mt-3 py-35-custom px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 items-center"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
