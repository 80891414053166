import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { classNames, convertToDefEventPara } from "../../shared/utility";
import { CalendarIcon } from "@heroicons/react/24/outline";

export default function DatePickerInput(props) {
  const {
    classes,
    label,
    name,
    onChange,
    value,
    labelOn,
    error,
    explanation,
    labelDivClass,
    labelClass,
    dateFormat,
    ...other
  } = props;

  return (
    <div>
      {labelOn ? (
        <div className={classNames("flex space-x-2 mb-1", labelDivClass)}>
          <label
            htmlFor={name}
            className={classNames(
              "block text-sm font-medium text-gray-900",
              labelClass
            )}
          >
            {label}
          </label>
        </div>
      ) : null}
      <div>
        <DatePicker
          wrapperClassName="w-full"
          selected={value ? new Date(value) : null}
          onChange={(e) => onChange(convertToDefEventPara(name, e))}
          dateFormat={dateFormat ? dateFormat : "LLL dd, YYY h:mm aa"}
          className={
            classes
              ? classes
              : "mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none sm:text-sm sm:max-w-xs md:max-w-full"
          }
          {...other}
          // icon={<CalendarIcon className="h-5 w-5" />}
          // showIcon={true}
        />
        {error ? <p className="font-medium text-red-500">{error}</p> : null}
      </div>
    </div>
  );
}
