import { useEffect } from "react";

import { AddReceivingInvoiceViewModel } from "../../../viewmodels/receiving/invoices/AddReceivingInvoiceViewModel";

import { connect } from "react-redux";

import * as actions from "../../../store/actions/index";

import { withRouter } from "../../../shared/utility";

import SlideOver from "../../../components/slideOver/SlideOver";
import AddReceivingInvoiceForm from "../../../components/forms/receiving/invoices/AddReceivingInvoiceForm";
import SimpleBanner from "../../../components/SimpleBanner";
import EmptyModal from "../../../components/modals/EmptyModal";

function AddReceivingInvoice(props) {
  const { logout } = props;
  const {
    addItemToReceivingInvoice,
    barcodeDetails,
    createReceivingInvoice,
    getAllAssemblyItems,
    getAssemblyItemByPlu,
    invoice,
    invoiceItems,
    onGenerateBarcodePdf,
    productTypeOptions,
    claimsOptions,
    allergensOptions,
    loading,
    setSlideOver,
    setShowSimpleNotification,
    slideOver,
    showSimpleNotification,
    setEmptyModelOpen,
    emptyModal,
    showErrorModal,
    openReceivingItemDetails,
    deleteReceivingItem,
    getEmployeesForDropdown,
    employeeOptions,
    getReceivingInvoiceItems,
  } = AddReceivingInvoiceViewModel(logout);

  useEffect(() => {
    getEmployeesForDropdown();
  }, []);

  return (
    <div>
      <SimpleBanner
        setShow={setShowSimpleNotification}
        showSimpleNotification={showSimpleNotification}
      />
      <div className="mt-2 px-4 sm:px-8">
        <AddReceivingInvoiceForm
          addItemToReceivingInvoice={addItemToReceivingInvoice}
          barcodeDetails={barcodeDetails}
          createReceivingInvoice={createReceivingInvoice}
          invoice={invoice}
          onGenerateBarcodePdf={onGenerateBarcodePdf}
          productTypeOptions={productTypeOptions}
          claimsOptions={claimsOptions}
          allergensOptions={allergensOptions}
          getAllAssemblyItems={getAllAssemblyItems}
          getAssemblyItemByPlu={getAssemblyItemByPlu}
          setSlideOver={setSlideOver}
          isEdit={false}
          invoiceItems={invoiceItems}
          showErrorModal={showErrorModal}
          openReceivingItemDetails={openReceivingItemDetails}
          deleteReceivingItem={deleteReceivingItem}
          setEmptyModelOpen={setEmptyModelOpen}
          employeeOptions={employeeOptions}
          getReceivingInvoiceItems={getReceivingInvoiceItems}
        />
      </div>

      <EmptyModal
        childComponent={emptyModal.childComponent}
        open={emptyModal.open}
        setOpen={setEmptyModelOpen}
      />
      <SlideOver
        childComponent={slideOver.childComponent}
        customClass="max-w-3xl"
        slideOver={slideOver}
        setSlideOver={setSlideOver}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(actions.logout()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddReceivingInvoice)
);
