import { Dialog } from "@headlessui/react";
import Input from "../../../controls/Input";
import { useForm } from "../../useForm";
import { useEffect } from "react";

export default function ConfirmPricePopup(props) {
  const { assemblyItem, setNewCostForItem, setOpen, newValues } = props;

  const initialFValues = {
    cost: 0,
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("cost" in fieldValues) {
      temp.cost = null;
      if (fieldValues.cost.value < 1) {
        temp.cost = "Please set cost.";
      }
    }

    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === null);
  };

  const { values, setValues, errors, setErrors, handleInputChange } = useForm(
    initialFValues,
    false,
    validate
  );

  const onPriceSet = () => {
    if (validate()) {
      setNewCostForItem({ ...newValues, unit_cost: values.cost });
      setOpen({
        childComponent: null,
        open: false,
      });
    }
  };

  useEffect(() => {
    setValues(assemblyItem);
  }, []);

  return (
    <div>
      <div>
        <div className="mt-3 text-center sm:mt-5">
          <Dialog.Title
            as="h3"
            className="text-sm font-semibold leading-6 text-gray-900"
          >
            {assemblyItem.manufacturer.label} - {assemblyItem.manufacturer_plu}
          </Dialog.Title>
          <Dialog.Title
            as="h3"
            className="text-sm font-semibold leading-6 text-gray-900"
          >
            {assemblyItem.product_name}
          </Dialog.Title>

          <div className="mt-2 grid grid-cols-2 gap-2">
            <div>
              <Input
                label="Unit cost"
                name="cost"
                onChange={handleInputChange}
                value={values.cost}
                labelOn={true}
                type="text"
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                error={errors.cost}
              />
            </div>
            <div>
              <Input
                label="Unit of measure"
                name="cost"
                onChange={handleInputChange}
                value={values.unit_of_measure}
                labelOn={true}
                disabled={true}
                type="text"
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                error={errors.cost}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
        <button
          type="button"
          className="mt-3 inline-flex w-full justify-center rounded-md bg-main-purple hover:bg-indigo-700 px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-300  sm:col-start-1 sm:mt-0 focus:outline-none"
          onClick={() => {
            onPriceSet();
          }}
        >
          OK
        </button>
      </div>
    </div>
  );
}
