import { config } from "../../../../../constants";

export default function ProductSpecSheet(props) {
  const { product } = props;

  return (
    <div className="regular-page-to-print ml-2">
      <div className="grid grid-cols-3 gap-2 p-2">
        <div className="col-span-2">
          <div className="text-center mb-4">
            <h3 className="font-bold text-xl">PRODUCT SPECIFICATION SHEET</h3>
          </div>
          <div className="">
            <p className="text-lg font-bold underline">1. PRODUCT NAME</p>
            <p className="text-lg">{product.name_en}</p>
          </div>
          <div className="mt-1">
            <p className="text-lg font-bold underline">2. PRODUCT CODE</p>
            <p className="text-lg">{product.plu}</p>
          </div>
          <div className="mt-1">
            <p className="text-lg font-bold underline">3. PRODUCT WEIGHT</p>
            <p className="text-lg">
              {product.weight} {product.unit_of_measure.label}
            </p>
          </div>
          <div className="mt-1">
            <p className="text-lg font-bold underline">4. BOX WEIGHT</p>
            <p className="text-lg">{product.unit_by_case}</p>
          </div>
          <div className="mt-1">
            <p className="text-lg font-bold underline">
              5. STORAGE DESCRIPTION
            </p>
            <p className="text-lg">{product.storage_description_en}</p>
          </div>
          <div className="mt-1">
            <p className="text-lg font-bold underline">6. SHELF LIFE</p>
            <p className="text-lg">{product.shelf_life} days</p>
          </div>
          <div className="mt-1">
            <p className="text-lg font-bold underline">7. ALLERGENS</p>
            <p className="text-lg">{product.allergens_en}</p>
          </div>
          <div className="mt-1">
            <p className="text-lg font-bold underline">8. INGREDIENTS</p>
            <p className="text-lg">{product.ingredients_en}</p>
          </div>
          <div className="mt-3">
            <p className="text-md font-bold underline">
              This product was made in a facility that makes other products that
              may contain soy, wheat, sulphite, mustard and dairy.
            </p>
          </div>
          <div className="mt-3">
            <p className="text-lg font-bold underline">
              Approved by: Solmaz Foods Inc. Management
            </p>
            <p className="text-lg font-bold underline">Name: Yurt Gedik</p>
            <p className="text-lg font-bold underline">
              Designation: Plant Manager
            </p>
            <p className="text-lg font-bold underline">Date: April 22, 2024</p>
            <p className="text-lg font-bold underline">Sign / Initial</p>
          </div>
        </div>
        <div className="col-span-1">
          <div className="mt-3">
            <img
              src={
                "https://solmazfoods.ca/wp-content/uploads/2024/04/solmaz-logo-180.png"
              }
              alt="Brand logo"
              width="150px"
              height="70px"
            />
          </div>
          <div className="mt-3">
            <p className="text-lg font-bold underline">9. NUTRITION FACTS</p>
            <img
              src={config.url.STORAGE_URL + product.nutrition_image_url}
              alt="Nutrition facts"
              width="auto"
              height="70px"
            />
          </div>
        </div>
      </div>
      <button
        type="button"
        className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-main-purple hover:bg-indigo-700 items-center mt-3 hide-for-print"
        onClick={() => window.print()}
      >
        Print
      </button>
    </div>
  );
}
