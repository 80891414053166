import { classNames } from "../../../../shared/utility";

export default function ExportLabelToPrint(props) {
  const { certImage, label, showOnScreen } = props;

  return (
    <div
      className={classNames(
        showOnScreen ? null : "hidden",
        "export-label-section-to-print ml-auto mr-auto"
      )}
    >
      <div className="pl-5 order-2 border-gray-500 text-center">
        <img
          src={certImage}
          alt="Certification image"
          width="200px"
          height="200px"
          className="ml-auto mr-auto"
        />

        <p className="mt-2">Cert. No. Cert</p>

        <p className="mt-2 text-2xl">{label}</p>
      </div>
    </div>
  );
}
