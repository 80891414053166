import axios from "axios";
import { config } from "./constants";

export const axiosApiInstance = axios.create({
  baseURL: config.url.API_URL,
});

export const axiosStorageInstance = axios.create({
  baseURL: config.url.STORAGE_URL,
});
