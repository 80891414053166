import { classNames } from "../../shared/utility";
import ButtonSpinner from "../controls/ButtonSpinner";

export default function ReportsDropdown(props) {
  const {
    actionCall,
    reportComponents,
    showReports,
    setShowReports,
    title,
    reportInProgress,
  } = props;

  return (
    <div className="relative -ml-px block">
      <button
        className="ml-2 relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm text-gray-900 font-semibold ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
        onClick={() => setShowReports(true)}
      >
        {title ? title : ""}
      </button>

      <div
        className={classNames(
          showReports ? null : "hidden",
          "absolute right-0 z-10 -mr-1 mt-2 w-80 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none divide-y divide-gray-300"
        )}
      >
        <div className="p-3 flex justify-between">
          <p className="text-sm text-gray-700 font-semibold">
            {title ? title : ""}
          </p>
          <button onClick={() => setShowReports(false)}>X</button>
        </div>
        {reportComponents.map((item, index) => (
          <div key={index}>{item}</div>
        ))}
        <div className="p-3 flex justify-between">
          <button
            onClick={() => setShowReports(false)}
            className="rounded-md px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          >
            Close
          </button>
          <button
            onClick={() => {
              actionCall();
            }}
            className="ml-5 rounded-md bg-main-purple px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            <ButtonSpinner spin={reportInProgress} text="Apply" />
          </button>
        </div>
      </div>
    </div>
  );
}
