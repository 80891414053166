import { useEffect } from "react";
import { useForm } from "../useForm";

import { Dialog } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";

import Input from "../../controls/Input";

import SelectMenu from "../../controls/SelectMenu";
import { classNames } from "../../../shared/utility";

const initialFValues = {
  name: "",
  email: "",
  user_type: { value: -1, label: "Select..." },
  current_password: "",
  new_password: "",
  new_password_confirmation: "",
};

const userOptions = [
  { value: 0, label: "Employee" },
  { value: 1, label: "Admin" },
  { value: 2, label: "CFIA Inspector" },
];

export default function UserDetailsForm(props) {
  const { user, setOpen, actionCall, isEdit, onChangePassword } = props;

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, false);

  const onValidatePasswords = (fieldValues = values) => {
    let temp = { ...errors };

    if ("current_password" in fieldValues) {
      temp.current_password = null;
      if (fieldValues.current_password === "") {
        temp.current_password = "This field is required.";
      }
    }

    if ("new_password" in fieldValues) {
      temp.new_password = null;
      if (fieldValues.new_password === "") {
        temp.new_password = "This field is required.";
      }

      if (fieldValues.new_password_confirmation !== values.new_password) {
        temp.new_password = "The passwords do not match.";
      }
    }

    if ("new_password_confirmation" in fieldValues) {
      temp.new_password_confirmation = null;
      if (fieldValues.new_password_confirmation === "") {
        temp.new_password_confirmation = "This field is required.";
      }

      if (fieldValues.new_password_confirmation !== values.new_password) {
        temp.new_password = "The passwords do not match.";
      }
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === null);
    }
  };

  const onChangePasswordClick = () => {
    if (onValidatePasswords()) {
      onChangePassword(values);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    actionCall(values);
  };

  useEffect(() => {
    if (isEdit) {
      setValues(user);
    }
  }, [user, setValues]);

  return (
    <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
      <div className="flex min-h-0 flex-1 flex-col overflow-y-scroll py-6">
        <div className="px-4 sm:px-6">
          <div className="flex items-start justify-between">
            <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
              {isEdit ? `Edit ${user.name}` : "Add new user"}
            </Dialog.Title>
            <div className="ml-3 flex h-7 items-center">
              <button
                type="button"
                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                onClick={() => setOpen({ childComponent: null, open: false })}
              >
                <span className="sr-only">Close panel</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
        <div className="relative mt-6 flex-1 px-4 sm:px-6">
          <div>
            <div className="mt-2">
              <Input
                label="Name"
                name="name"
                onChange={handleInputChange}
                value={values.name}
                labelOn={true}
                type="text"
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
              />
            </div>
            <div className="mt-2">
              <Input
                label="Email"
                name="email"
                onChange={handleInputChange}
                value={values.email}
                labelOn={true}
                type="text"
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
              />
            </div>
            <div className="mt-2">
              <SelectMenu
                handleInputChange={handleInputChange}
                name="user_type"
                options={userOptions}
                defaultValue={isEdit ? user.user_type : null}
                title={"Type"}
                error={errors.user_type}
              />
            </div>
            <div>
              <div className="mt-2">
                <Input
                  label="Current Password"
                  name="current_password"
                  onChange={handleInputChange}
                  value={values.current_password}
                  error={errors.current_password}
                  labelOn={true}
                  type="password"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                />
              </div>

              <div className="mt-2">
                <Input
                  label="New Password"
                  name="new_password"
                  onChange={handleInputChange}
                  value={values.new_password}
                  error={errors.new_password}
                  labelOn={true}
                  type="password"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                />
              </div>
              <div className="mt-2">
                <Input
                  label="Confirm password"
                  name="new_password_confirmation"
                  onChange={handleInputChange}
                  value={values.new_password_confirmation}
                  error={errors.new_password_confirmation}
                  labelOn={true}
                  type="text"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                />
              </div>

              <div>
                <button
                  type="button"
                  onClick={onChangePasswordClick}
                  className={classNames(
                    isEdit
                      ? "mt-2 inline-flex justify-center rounded-md bg-gray-400 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                      : "hidden"
                  )}
                >
                  Change password
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-shrink-0 justify-end px-4 py-4">
        <button
          type="button"
          className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400"
          onClick={() => setOpen({ childComponent: null, open: false })}
        >
          Cancel
        </button>
        <button
          type="submit"
          className="ml-4 inline-flex justify-center rounded-md bg-main-purple px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          onClick={handleSubmit}
        >
          {isEdit ? "Save" : "Create"}
        </button>
      </div>
    </div>
  );
}
