import React, { useEffect, useRef, useState } from "react";

import { useForm } from "../../useForm";

import Input from "../../../controls/Input";
import CreatableSelectMenu from "../../../controls/CreatableSelectMenu";
import SelectMenu from "../../../controls/SelectMenu";

import { format } from "date-fns";

import {
  classNames,
  convertToDefEventPara,
  ingestBarcodeScan,
  makeBeepNoise,
} from "../../../../shared/utility";
import AssemblyItemsTableComponent from "../../../../viewmodels/receiving/invoices/AssemblyItemsTableComponent";
import DatePickerInput from "../../../controls/DatePickerInput";

import ConfirmPricePopup from "./ConfirmPricePopup";
import ReceivingBarcodeLabel from "./ReceivingBarcodeLabel";

const initialFValues = {
  invoice_number: "",
  date: "",
  assembly_item_id: "",
  isBarcode: { value: 0, label: "" },
  received_by: { value: 0, label: "" },
  manufacturer_id: "",
  supplier_name: "",
  product_name: "",
  product_lot: "",
  unit: "",
  total_unit: "",
  unit_cost: "",
  total_cost: "",
  product_condition: { value: 0, label: "" },
  product_quality: { value: 0, label: "" },
  pallet_condition: { value: 0, label: "" },
  box_barcode: "",
  box_date: "",
  note: "",
  certification_type: 0,
  allergens: "",
  preservatives: "",
  product_type: 0,
  is_non_meat: 0,
  product_claims: 0,
  product_temperature: 0,
  truck_temperature: 0,
  truck_condition: { value: 0, label: "" },
  location: "",
};

const barcodeOptions = [
  { value: 1, label: "No Barcode" },
  { value: 2, label: "Barcode" },
];

const conditionTypes = [
  { value: 0, label: "Choose Condition" },
  { value: 1, label: "Satisfactory" },
  { value: 2, label: "NOT Satisfactory" },
];

export default function AddReceivingInvoiceForm(props) {
  const {
    addItemToReceivingInvoice,
    barcodeDetails,
    createReceivingInvoice,
    getAllAssemblyItems,
    getAssemblyItemByPlu,
    invoice,
    invoiceItems,
    onGenerateBarcodePdf,
    isEdit,
    setSlideOver,
    updateReceivingInvoiceApiCall,
    showErrorModal,
    openReceivingItemDetails,
    deleteReceivingItem,
    setEmptyModelOpen,
    employeeOptions,
    getReceivingInvoiceItems,
  } = props;

  const [barcodeScanInProgress, setBarcodeScanInProgress] = useState(false);
  const [createBarcodeInProgress, setCreateBarcodeInProgress] = useState(false);
  const [createInvoiceInProgress, setCreateInvoiceInProgress] = useState(false);
  const [updateInvoiceInProgress, setUpdateInvoiceInProgress] = useState(false);
  const [currentBarcodeItemPlu, setCurrentBarcodeItemPlu] = useState(null);
  const [shouldShowBarcode, setShouldShowBarcode] = useState(false);

  const validate = (fieldValues) => {
    let temp = { ...errors };

    temp.supplier_name = null;
    if (values.supplier_name.value === "") {
      temp.supplier_name = "Please fill out field.";
    }

    if ("isBarcode" in fieldValues) {
      temp.isBarcode = null;
      if (fieldValues.isBarcode.value < 1) {
        temp.isBarcode = "Please fill out field.";
      }
    }

    if ("invoice_number" in fieldValues) {
      temp.invoice_number = null;
      if (fieldValues.invoice_number === "") {
        temp.invoice_number = "Please fill out field.";
      }
    }

    if ("received_by" in fieldValues || fieldValues.received_by === 0) {
      temp.received_by = null;
      if (fieldValues.received_by === "") {
        temp.received_by = "Please fill out field.";
      }
    }

    if ("truck_temperature" in fieldValues) {
      temp.truck_temperature = null;
      if (fieldValues.truck_temperature === "") {
        temp.truck_temperature = "Please fill out field.";
      }
    }

    if ("truck_condition" in fieldValues) {
      temp.truck_condition = null;
      if (
        fieldValues.truck_condition === "" ||
        fieldValues.truck_condition === 0
      ) {
        temp.truck_condition = "Please fill out field.";
      }
    }

    if ("total_unit" in fieldValues) {
      temp.total_unit = null;
      if (fieldValues.total_unit === "" && values.isBarcode.value === 1) {
        temp.total_unit = "Please fill out field.";
      }
    }

    if ("unit_cost" in fieldValues) {
      temp.unit_cost = null;
      if (fieldValues.unit_cost === "") {
        temp.unit_cost = "Please set a price.";
      }
    }

    if ("product_lot" in fieldValues) {
      temp.product_lot = null;
      if (fieldValues.product_lot === "") {
        temp.product_lot = "Please fill out field.";
        if (fieldValues.isBarcode.value === 2) {
          showErrorModal("Please choose a supplier item.");
        }
      }
    }

    if (
      "product_temperature" in fieldValues ||
      fieldValues.product_temperature < 1
    ) {
      temp.product_temperature = null;
      if (fieldValues.product_temperature === "") {
        temp.product_temperature = "Please fill out field.";
      }
    }

    if (
      "product_condition" in fieldValues ||
      fieldValues.product_condition === 0
    ) {
      temp.product_condition = null;
      if (fieldValues.product_condition === "") {
        temp.product_condition = "Please fill out field.";
      }
    }

    if ("product_quality" in fieldValues || fieldValues.product_quality === 0) {
      temp.product_quality = null;
      if (fieldValues.product_quality === "") {
        temp.product_quality = "Please fill out field.";
      }
    }

    if ("pallet_condition" in fieldValues) {
      temp.pallet_condition = null;
      if (
        fieldValues.pallet_condition === "" ||
        fieldValues.pallet_condition === 0
      ) {
        temp.pallet_condition = "Please fill out field.";
      }
    }

    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === null);
  };

  const { values, setValues, errors, setErrors, handleInputChange } = useForm(
    initialFValues,
    false,
    validate
  );

  const barcodeInputRef = useRef(null);

  useEffect(() => {
    if (isEdit && invoice) {
      setValues({
        ...invoice,
        invoice_id: invoice.id,
        product_condition: { value: 0, label: "" },
        product_quality: { value: 0, label: "" },
        pallet_condition: { value: 0, label: "" },
      });
    } else {
      setValues({ ...values, date: format(new Date(), "LLL dd, YYY") });
    }
  }, [isEdit, invoice]);

  useEffect(() => {
    setShouldShowBarcode(
      values.isBarcode.value === 2 &&
        values.invoice_number !== "" &&
        values.supplier !== "" &&
        values.received_by.value > 0 &&
        values.zone !== "" &&
        values.location !== "" &&
        values.product_temperature !== "" &&
        values.pallet_condition.value > 0 &&
        values.product_condition.value > 0 &&
        values.product_quality.value > 0 &&
        values.truck_temperature !== ""
    );
  }, [values]);

  const temperatureIsRequired = (productType) => {
    if (
      productType === 1 ||
      productType === 2 ||
      productType === 3 ||
      productType === 5 ||
      productType === 6 ||
      productType === 7
    ) {
      return true;
    }
    return false;
  };

  const openSearchAssemblyItems = async function () {
    let callResult = await getAllAssemblyItems();
    if (callResult.success) {
      setSlideOver({
        childComponent: (
          <AssemblyItemsTableComponent
            assemblyItemsList={callResult.data}
            setItemValues={(assemblyItem) => {
              // if (assemblyItem.supplier_id == values.supplier.value) {
              setValues({
                ...values,
                box_barcode: "",
                assembly_item_id: assemblyItem.id,
                manufacturer_id: assemblyItem.manufacturer_id,
                manufacturer_name: assemblyItem.manufacturer_name,
                pack_unit: assemblyItem.pack_unit,
                product_name: assemblyItem.product_name,
                product_code: assemblyItem.manufacturer_plu,
                unit_cost: assemblyItem.cost,
                product_lot: "",
                certification_type: assemblyItem.certification_type,
                product_type: assemblyItem.product_type,
                is_non_meat: assemblyItem.is_non_meat,
                total_cost: values.total_unit * assemblyItem.cost,
                unit_of_measure: assemblyItem.unit_of_measure_text,
                ingredient_id: assemblyItem.ingredient_id,
                product_temperature: temperatureIsRequired(
                  assemblyItem.product_type
                )
                  ? ""
                  : "N/A",
                truck_temperature: temperatureIsRequired(
                  assemblyItem.product_type
                )
                  ? ""
                  : "N/A",
              });
              // } else {
              //   showErrorModal("Suppliers do not match");
              // }
            }}
            setOpen={setSlideOver}
          />
        ),
        open: true,
      });
    } else {
    }
  };

  const continueAddingItemToInvoice = (newValues) => {
    if (invoice.id > 0) {
      addItemToInvoice(newValues);
    } else {
      onCreateInvoice(newValues);
    }
  };

  const setNewCostForItem = (newValues) => {
    continueAddingItemToInvoice(newValues);
  };

  const handleBarcodeScan = async function (scannedBarcode) {
    if (scannedBarcode || scannedBarcode !== "") {
      setBarcodeScanInProgress(true);

      const barcodeInfo = ingestBarcodeScan(scannedBarcode);

      const barcodePlu = barcodeInfo.plu;
      if (currentBarcodeItemPlu !== barcodePlu) {
        setCurrentBarcodeItemPlu(barcodePlu);
      }

      if (barcodePlu || barcodePlu !== "") {
        let callResult = await getAssemblyItemByPlu(barcodePlu);

        if (callResult) {
          // if (callResult.supplier["value"] == values.supplier.value) {
          const newValues = {
            ...values,
            product_code: barcodePlu,
            box_barcode: scannedBarcode,
            box_date: barcodeInfo.date,
            pack_unit: callResult.pack_unit,
            total_unit: barcodeInfo.weight,
            certification_type: callResult.certification_type["value"],
            manufacturer_id: callResult.manufacturer["value"],
            manufacturer_name: callResult.manufacturer["label"],
            unit_cost: callResult.cost,
            product_lot: barcodeInfo.serial,
            product_name: callResult.product_name,
            assembly_item_id: callResult.id,
            product_type: callResult.product_type["value"],
            is_non_meat: callResult.is_non_meat["value"],
            total_cost: callResult.cost * barcodeInfo.weight,
            unit_of_measure: callResult.unit_of_measure,
            ingredient_id: callResult.ingredient_id,
          };

          // We show the cost popup then don't show it again unless plu changes
          if (currentBarcodeItemPlu === barcodePlu) {
            continueAddingItemToInvoice(newValues);
          } else {
            setValues(newValues);
            setEmptyModelOpen({
              childComponent: (
                <ConfirmPricePopup
                  assemblyItem={callResult}
                  newValues={newValues}
                  setNewCostForItem={setNewCostForItem}
                  setOpen={setEmptyModelOpen}
                />
              ),
              open: true,
            });
          }
        }
      }
      setBarcodeScanInProgress(false);
    }
  };

  const checkAssemblyItemChosen = (newValues) => {
    if (newValues.assembly_item_id < 1) {
      showErrorModal("Please choose a supplier item.");
      return false;
    } else {
      return true;
    }
  };

  const clearAssemblyItemValues = (newValues) => {
    setValues({
      ...values,
      ...newValues,
      receiving_item_id: "",
      allergens: "",
      box_barcode: "",
      barcode_url: "",
      box_date: "",
      certification_type: "",
      manufacturer_id: "",
      manufacturer_name: "",
      pack_unit: "",
      total_unit: "",
      preservatives: "",
      product_claims: "",
      product_condition: { value: 0, label: "" },
      product_temperature: "",
      unit_cost: "",
      product_quality: { value: 0, label: "" },
      assembly_item_id: "",
      product_lot: "",
      product_code: "",
      product_name: "",
      product_type: "",
      is_non_meat: "",
      total_cost: "",
    });
  };

  const addItemToInvoice = async function (newValues) {
    var newValuesCopy = { ...newValues };
    if (!newValues.invoice_id > 0) {
      newValuesCopy = { ...newValuesCopy, invoice_id: invoice.id };
    }
    const validateResult = validate(newValuesCopy);
    if (validateResult && checkAssemblyItemChosen(newValuesCopy)) {
      var callResult = await addItemToReceivingInvoice(newValuesCopy);

      if (callResult.success) {
        setValues({
          ...values,
          scanned_box_label: "",
          total_unit: "",
          receiving_item_id: callResult.data.receiving_item.receiving_item_id,
        });
        getReceivingInvoiceItems(newValuesCopy.invoice_id);
        makeBeepNoise();
        if (barcodeInputRef.current) {
          barcodeInputRef.current.focus();
        }
      } else {
        setValues({ ...values, scanned_box_label: "" });
        if (barcodeInputRef.current) {
          barcodeInputRef.current.focus();
        }
      }
    } else {
      if (!validateResult) {
        showErrorModal("Please make sure you filled out all fields.");
      } else if (values.assembly_item_id < 1) {
        showErrorModal("Please select a supplier item.");
      } else {
        showErrorModal("Please make sure you filled out all fields.");
      }
    }
  };

  const onCreateInvoice = async function (newValues) {
    if (validate(newValues)) {
      setCreateInvoiceInProgress(true);
      var callResult = await createReceivingInvoice(newValues);

      if (callResult.success) {
        const newInvoiceId = callResult.data.receiving_invoice.id;
        setValues({
          ...values,
          invoice_id: newInvoiceId,
        });
        addItemToInvoice({
          ...newValues,
          invoice_id: newInvoiceId,
        });
      }
      setCreateInvoiceInProgress(false);
    } else {
      if (values.assembly_item_id < 1) {
        showErrorModal("Please select a supplier item.");
      } else {
        showErrorModal("Please make sure you filled out all fields.");
      }
    }
  };

  const updateReceivingInvoice = async function () {
    setUpdateInvoiceInProgress(true);
    var callResult = await updateReceivingInvoiceApiCall(values, invoice.id);

    setUpdateInvoiceInProgress(false);
  };

  const generateBarcodeStringClicked = async function () {
    setCreateBarcodeInProgress(true);
    const callResult = await onGenerateBarcodePdf({
      receiving_item_id: values.receiving_item_id,
    });
    setCreateBarcodeInProgress(false);

    if (callResult.success) {
      setTimeout(() => {
        window.print();
      }, 1500);
    }
  };

  const handleTotalUnitKeyDown = (event) => {
    if (event.key === "Enter") {
      handleInputChange(
        convertToDefEventPara("total_unit", eval(values.total_unit))
      );
    }
  };

  return (
    <div className="justify-between">
      <div className="hide-for-print">
        <div className="flex items-center justify-between flex-wrap sm:flex-nowrap">
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              {isEdit
                ? "Editing invoice " + invoice.invoice_number
                : "Add new receiving invoice"}
            </h3>
          </div>
        </div>

        {/* here */}
        <div className="pt-6 pb-5 grid grid-cols-1 sm:grid-cols-2 gap-2">
          <div id="formGrid" className="order-2 sm:order-1">
            <div className="p-4 card-custom">
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <SelectMenu
                    handleInputChange={(e) => {
                      clearAssemblyItemValues({ isBarcode: e.target.value });
                    }}
                    name="isBarcode"
                    options={barcodeOptions}
                    title={"Is barcode"}
                    error={errors.isBarcode}
                  />
                </div>
                <div>
                  <DatePickerInput
                    label="Date"
                    name="date"
                    onChange={handleInputChange}
                    value={values.date}
                    labelOn={true}
                    type="text"
                    dateFormat={"LLL dd, YYY"}
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                  />
                </div>
              </div>
              <div className="grid mt-3 grid-cols-2 gap-4">
                <div>
                  <Input
                    label="Invoice #"
                    name="invoice_number"
                    onChange={handleInputChange}
                    value={values.invoice_number}
                    labelOn={true}
                    type="text"
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                    error={errors.invoice_number}
                  />
                </div>
                <div>
                  <Input
                    label="Supplier"
                    name="supplier_name"
                    onChange={handleInputChange}
                    value={values.supplier_name}
                    labelOn={true}
                    type="text"
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                    error={errors.supplier_name}
                  />
                </div>
              </div>
              <div className="mt-3 grid grid-cols-2 gap-4">
                <div>
                  <CreatableSelectMenu
                    handleInputChange={handleInputChange}
                    name="received_by"
                    options={employeeOptions}
                    defaultValue={isEdit ? invoice.received_by : null}
                    title={"Received by"}
                  />
                </div>
                <div>
                  <Input
                    label="Location"
                    name="location"
                    onChange={handleInputChange}
                    value={values.location}
                    labelOn={true}
                    type="text"
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm w-48"
                    error={errors.location}
                  />
                </div>
                <div>
                  <Input
                    label="Zone"
                    name="zone"
                    onChange={handleInputChange}
                    value={values.zone}
                    labelOn={true}
                    type="text"
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm w-48"
                    error={errors.zone}
                  />
                </div>
              </div>
            </div>
            <div
              className={classNames(
                values.isBarcode.value === 2 ? "hidden" : "mt-5 p-4 card-custom"
              )}
            >
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <Input
                    label="Product name"
                    name="product_name"
                    onChange={handleInputChange}
                    value={values.product_name}
                    labelOn={true}
                    type="text"
                    disabled={true}
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                  />
                </div>
                <div>
                  <Input
                    label="Manufacturer"
                    name="manufacturer_name"
                    onChange={handleInputChange}
                    value={values.manufacturer_name}
                    labelOn={true}
                    disabled={true}
                    type="text"
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                  />
                </div>
              </div>
              <div className="mt-3 grid grid-cols-2 gap-4">
                <div>
                  <Input
                    label="Product lot"
                    name="product_lot"
                    onChange={handleInputChange}
                    value={values.product_lot}
                    labelOn={true}
                    type="text"
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm w-48"
                    error={errors.product_lot}
                  />
                </div>
                <div>
                  <Input
                    label="Pack unit"
                    name="pack_unit"
                    onChange={handleInputChange}
                    value={`${values.pack_unit} ${values.unit_of_measure}`}
                    labelOn={true}
                    disabled={true}
                    type="text"
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm w-48"
                    error={errors.pack_unit}
                  />
                </div>
              </div>
              <div className="mt-3 flex gap-4">
                <div>
                  <Input
                    label="Total unit"
                    name="total_unit"
                    onChange={handleInputChange}
                    onKeyDown={handleTotalUnitKeyDown}
                    value={values.total_unit}
                    labelOn={true}
                    type="text"
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm w-22-custom"
                    error={errors.total_unit}
                  />
                </div>
                <div>
                  <Input
                    label="Unit of measure"
                    name="unit_of_measure"
                    onChange={handleInputChange}
                    value={values.unit_of_measure}
                    labelOn={true}
                    type="text"
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm w-22-custom"
                    error={errors.unit_of_measure}
                    disabled={true}
                  />
                </div>
                <div>
                  <Input
                    label="Unit cost"
                    name="unit_cost"
                    onChange={handleInputChange}
                    value={values.unit_cost}
                    labelOn={true}
                    type="text"
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm w-22-custom"
                    error={errors.unit_cost}
                  />
                </div>
              </div>
            </div>
            <div className="mt-5 p-4 card-custom">
              <div className="grid grid-cols-1">
                <div className="cols-3">
                  <p>Quality Control</p>
                </div>
                <div className="mt-3 grid grid-cols-2 sm:grid-cols-5 gap-2">
                  <div>
                    <Input
                      label="Product Temperature"
                      name="product_temperature"
                      onChange={handleInputChange}
                      value={values.product_temperature}
                      labelOn={true}
                      type="text"
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                      error={errors.product_temperature}
                    />
                  </div>
                  <div className="sm:col-span-2">
                    <SelectMenu
                      handleInputChange={handleInputChange}
                      name="product_condition"
                      options={conditionTypes}
                      defaultValue={values.product_condition}
                      value={values.product_condition}
                      title="Product Condition"
                      error={errors.product_condition}
                    />
                  </div>
                  <div className="hidden sm:block sm:col-span-2">
                    <SelectMenu
                      handleInputChange={handleInputChange}
                      name="product_quality"
                      options={conditionTypes}
                      defaultValue={values.product_quality}
                      value={values.product_quality}
                      title="Product quality"
                      error={errors.product_quality}
                    />
                  </div>
                  <div>
                    <Input
                      label="Truck Temperature"
                      name="truck_temperature"
                      onChange={handleInputChange}
                      value={values.truck_temperature}
                      labelOn={true}
                      type="text"
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                      error={errors.truck_temperature}
                    />
                  </div>
                  <div className="sm:col-span-2">
                    <SelectMenu
                      handleInputChange={handleInputChange}
                      name="truck_condition"
                      options={conditionTypes}
                      defaultValue={isEdit ? invoice.truck_condition : null}
                      title="Truck Condition"
                      error={errors.truck_condition}
                    />
                  </div>
                  <div className="sm:hidden sm:col-span-2">
                    <SelectMenu
                      handleInputChange={handleInputChange}
                      name="product_quality"
                      options={conditionTypes}
                      defaultValue={values.product_quality}
                      value={values.product_quality}
                      title="Product quality"
                      error={errors.product_quality}
                    />
                  </div>
                  <div className="sm:col-span-2">
                    <SelectMenu
                      handleInputChange={handleInputChange}
                      name="pallet_condition"
                      defaultValue={values.pallet_condition}
                      value={values.pallet_condition}
                      options={conditionTypes}
                      title="Pallet Condition"
                      error={errors.pallet_condition}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="sm:flex-no-wrap">
              <div className="my-auto flex items-center">
                <button
                  onClick={() => updateReceivingInvoice()}
                  className={classNames(
                    isEdit ? null : "hidden",
                    "inline-flex justify-center mt-3 py-35-custom px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-600 hover:bg-yellow-700 items-center mr-2"
                  )}
                >
                  {createInvoiceInProgress ? (
                    <svg
                      className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  ) : (
                    "Update receiving"
                  )}
                </button>
                <button
                  onClick={() => onCreateInvoice(values)}
                  className={classNames(
                    invoice.id > 0 ? "hidden" : null,
                    "inline-flex justify-center mt-3 py-35-custom px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-main-purple hover:bg-indigo-700 items-center"
                  )}
                >
                  {createInvoiceInProgress ? (
                    <svg
                      className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  ) : (
                    "Create receiving"
                  )}
                </button>
                <button
                  onClick={() => addItemToInvoice(values)}
                  className={classNames(
                    invoice.id > 0 ? null : "hidden",
                    "inline-flex justify-center mt-3 py-35-custom px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-main-purple hover:bg-indigo-700 items-center"
                  )}
                >
                  {createInvoiceInProgress ? (
                    <svg
                      className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  ) : (
                    "Add item"
                  )}
                </button>
                <button
                  onClick={() => generateBarcodeStringClicked(values)}
                  className={classNames(
                    invoice.id > 0 &&
                      values.isBarcode.value === 1 &&
                      values.receiving_item_id > 0
                      ? "inline-flex justify-center mt-3 ml-5 py-1.5 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 items-center"
                      : "hidden"
                  )}
                >
                  {createBarcodeInProgress ? (
                    <svg
                      className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  ) : (
                    "Print barcode"
                  )}
                </button>
              </div>
            </div>
          </div>
          <div id="assemblyItems" className="order-1 sm:oder-2">
            <div
              id="buttons"
              className={classNames(
                values.isBarcode.value === 1 ? null : "hidden",
                "my-auto flex items-center mb-2"
              )}
            >
              <button
                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-500 hover:bg-green-600 items-center"
                onClick={() => {
                  openSearchAssemblyItems();
                }}
              >
                Search supplier items
              </button>
            </div>
            {shouldShowBarcode ? (
              <div id="barcode-scan-text-input-parent" className="mb-3">
                <input
                  placeholder="Scan box label"
                  name="scanned_box_label"
                  disabled={barcodeScanInProgress}
                  ref={barcodeInputRef}
                  onChange={handleInputChange}
                  value={values.scanned_box_label}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") handleBarcodeScan(e.target.value);
                  }}
                  type="text"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                />
              </div>
            ) : null}
            {invoiceItems.length > 0 ? (
              <div id="barcode-scanned-items-parent">
                <div
                  id="product-table"
                  className="overflow-hidden card-custom overflow-x-auto"
                >
                  <table className="min-w-full divide-y divide-gray-300 overflow-x-auto">
                    <thead className="bg-white">
                      <tr>
                        <th
                          scope="col"
                          className="py-3 pl-3 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                          // width="20%"
                        >
                          Product Name
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          // width="20%"
                        >
                          Serial/Lot
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          // width="20%"
                        >
                          Unit
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-right text-sm font-semibold text-gray-900"
                          // width="20%"
                        >
                          Unit Cost
                        </th>
                        <th
                          scope="col"
                          className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                          // width="20%"
                        ></th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {invoiceItems.map((invoiceItem) => (
                        <tr key={invoiceItem.receiving_item_id}>
                          <td className="whitespace-nowrap py-1 pl-4 pr-3 text-sm font-medium text-gray-500 sm:pl-6">
                            {invoiceItem.product_name}
                          </td>
                          <td className="whitespace-nowrap px-3 py-1 text-sm text-gray-500">
                            {invoiceItem.is_last_item
                              ? invoiceItem.plu_number_of_products
                              : invoiceItem.product_lot}
                          </td>
                          <td className="whitespace-nowrap px-3 py-1 text-sm text-gray-500">
                            {invoiceItem.is_last_item
                              ? invoiceItem.plu_total_unit
                              : invoiceItem.total_unit}{" "}
                            {invoiceItem.plu_total_unit ||
                            invoiceItem.total_unit
                              ? invoiceItem.unit_of_measure
                              : null}
                          </td>
                          <td className="whitespace-nowrap px-3 py-1 text-right text-sm text-gray-500">
                            $
                            {invoiceItem.is_last_item
                              ? invoiceItem.plu_total_cost
                              : invoiceItem.unit_cost}
                          </td>
                          <td className="relative whitespace-nowrap py-1 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                            <button
                              onClick={() =>
                                openReceivingItemDetails(
                                  invoiceItem.receiving_item_id
                                )
                              }
                              className={classNames(
                                invoiceItem.is_last_item
                                  ? "hidden"
                                  : "rounded-md bg-main-purple px-3 py-1 text-sm font-semibold text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                              )}
                            >
                              View
                            </button>
                            <button
                              onClick={() => {
                                deleteReceivingItem(
                                  invoiceItem.receiving_item_id
                                );
                              }}
                              className={classNames(
                                invoiceItem.is_last_item
                                  ? "hidden"
                                  : "ml-2 rounded-md bg-red-600 px-3 py-1 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                              )}
                            >
                              Delete
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <ReceivingBarcodeLabel barcodeDetails={barcodeDetails} />
    </div>
  );
}
