import React, { useState } from "react";

export function useForm(initialFValues, validateOnChange = false, validate) {
  const [values, setValues] = useState(initialFValues);
  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });
    if (validateOnChange) validate({ [name]: value });
  };

  const handleMultipleInputChange = (
    newValue1,
    newValue2,
    newValue3,
    newValue4,
    newValue5,
    newValue6,
    newValue7,
    newValue8,
    newValue9,
    newValue10,
    newValue11,
    newValue12,
    newValue13,
    newValue14,
    newValue15,
    newValue16,
    newValue17,
    newValue18,
    newValue19,
    newValue20,
    newValue21,
    newValue22,
    newValue23,
    newValue24
  ) => {
    setValues({
      ...values,
      ...newValue1,
      ...newValue2,
      ...newValue3,
      ...newValue4,
      ...newValue5,
      ...newValue6,
      ...newValue7,
      ...newValue8,
      ...newValue9,
      ...newValue10,
      ...newValue11,
      ...newValue12,
      ...newValue13,
      ...newValue14,
      ...newValue15,
      ...newValue16,
      ...newValue17,
      ...newValue18,
      ...newValue19,
      ...newValue20,
      ...newValue21,
      ...newValue22,
      ...newValue23,
      ...newValue24,
    });
  };

  const resetForm = () => {
    setValues(initialFValues);
    setErrors({});
  };

  return {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    handleMultipleInputChange,
    resetForm,
  };
}

export function Form(props) {
  const { classes, children, ...other } = props;
  return (
    <form
      className={
        classes
          ? classes
          : "h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl"
      }
      {...other}
    >
      {props.children}
    </form>
  );
}
