import { useState } from "react";

import { makePostApiCall } from "../../shared/makeApiCall";
import { BannerNotificationType } from "../../components/SimpleBanner";

export function TemperatureSensorsViewModel(logout) {
  const [emptyModal, setEmptyModelOpen] = useState({
    open: false,
    childComponent: null,
  });
  const [loading, setLoading] = useState(true);
  const [showSimpleNotification, setShowSimpleNotification] = useState({
    show: false,
    message: null,
    title: null,
    type: BannerNotificationType.Success,
  });
  const [sensors, setSensors] = useState({ data: [] });

  const getTemperatureSensors = async function () {
    setLoading(true);
    let callResult = await makePostApiCall(`getTemperatureSensors`, {
      page: 1,
      filters: [],
    });

    if (callResult.success) {
      setSensors(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
    setLoading(false);
  };

  return {
    emptyModal,
    loading,
    setEmptyModelOpen,
    setShowSimpleNotification,
    showSimpleNotification,
    getTemperatureSensors,
    sensors,
  };
}
