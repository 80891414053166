import React from "react";

export default function TextArea(props) {
  const {
    parentClass,
    classes,
    label,
    labelOn,
    name,
    onChange,
    value,
    error,
    rows,
    explanation,
    ...other
  } = props;

  return (
    <div className={parentClass ? parentClass : null}>
      {labelOn ? (
        <div className="flex space-x-2">
          <label
            htmlFor={name}
            className="block text-sm font-medium text-gray-900"
          >
            {label}
          </label>
        </div>
      ) : null}
      <div className="mt-1">
        <textarea
          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm max-w-lg"
          name={name}
          value={value}
          onChange={onChange}
          rows={rows}
          {...other}
        />
        {error ? <p className="font-medium text-red-500">{error}</p> : null}
      </div>
    </div>
  );
}
