import { useEffect } from "react";
import { ManufactureNewProductViewModel } from "../../viewmodels/production/ManufactureNewProductViewModel";

import { connect } from "react-redux";

import * as actions from "../../store/actions/index";

import { withRouter } from "../../shared/utility";

import SlideOver from "../../components/slideOver/SlideOver";

import ManufactureNewProductForm from "../../components/forms/production/ManufactureNewProductForm";
import EmptyModal from "../../components/modals/EmptyModal";
import ManufacturedProductLabel from "../../components/forms/production/barcode-label/ManufacturedProductLabel";
import SimpleBanner from "../../components/SimpleBanner";

function ManufactureNewProduct(props) {
  const { logout } = props;
  const {
    addIngredientToManufacturedProduct,
    makeApiCallToStartManufacturing,
    certifiedByOptions,
    emptyModal,
    getAssemblyItemReceivingByBarcode,
    getCertifiedByOptionsForDropdown,
    getManufacturedProductIngredients,
    getProductsList,
    setEmptyModelOpen,
    setShowSimpleNotification,
    setSlideOver,
    showSimpleNotification,
    slideOver,
    updateManufacturedProduct,
    getReceivingAssemblyItemInStockByName,
    deleteIngredientFromManufacturedProduct,
    addManualEntryForManufacturingProduct,
    getManufacturedProductLabel,
    printLabelDetails,
    employeeOptions,
    getEmployeesForDropdown,
    fetchRecipesForDropdown,
    recipes,
    fetchRecipeItems,
    selectedRecipe,
    setSelectedRecipe,
    showErrorModal,
    manufacturedProduct,
    claimsOptions,
    getClaimsForDropdown,
    supervisorOptions,
  } = ManufactureNewProductViewModel(logout);

  useEffect(() => {
    getClaimsForDropdown();
    getCertifiedByOptionsForDropdown();
    getEmployeesForDropdown(2, [1, 3]);
    getEmployeesForDropdown(1, [1, 3]);
    fetchRecipesForDropdown();
  }, []);

  return (
    <div>
      <SimpleBanner
        setShow={setShowSimpleNotification}
        showSimpleNotification={showSimpleNotification}
      />
      <div className="hide-for-print mt-2">
        <ManufactureNewProductForm
          loading={false}
          deleteIngredientFromManufacturedProduct={
            deleteIngredientFromManufacturedProduct
          }
          addIngredientToManufacturedProduct={
            addIngredientToManufacturedProduct
          }
          certifiedByOptions={certifiedByOptions}
          employeeOptions={employeeOptions}
          claimsOptions={claimsOptions}
          getAssemblyItemReceivingByBarcode={getAssemblyItemReceivingByBarcode}
          getManufacturedProductIngredients={getManufacturedProductIngredients}
          getProductsList={getProductsList}
          updateManufacturedProduct={updateManufacturedProduct}
          setEmptyModelOpen={setEmptyModelOpen}
          setSlideOver={setSlideOver}
          getReceivingAssemblyItemInStockByName={
            getReceivingAssemblyItemInStockByName
          }
          makeApiCallToStartManufacturing={makeApiCallToStartManufacturing}
          addManualEntryForManufacturingProduct={
            addManualEntryForManufacturingProduct
          }
          getManufacturedProductLabel={getManufacturedProductLabel}
          recipes={recipes}
          fetchRecipeItems={fetchRecipeItems}
          selectedRecipe={selectedRecipe}
          setSelectedRecipe={setSelectedRecipe}
          showErrorModal={showErrorModal}
          manufacturedProduct={manufacturedProduct}
          supervisorOptions={supervisorOptions}
        />
      </div>

      <EmptyModal
        childComponent={emptyModal.childComponent}
        open={emptyModal.open}
        setOpen={setEmptyModelOpen}
      />
      <SlideOver
        childComponent={slideOver.childComponent}
        customClass="max-w-7xl"
        slideOver={slideOver}
        setSlideOver={setSlideOver}
      />
      <ManufacturedProductLabel printLabelDetails={printLabelDetails} />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(actions.logout()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ManufactureNewProduct)
);
