import React from "react";

import { formatDate } from "../../../../shared/utility";
import Input from "../../../../components/controls/Input";

export default function InvoiceRecordDetails(props) {
  const { invoiceDetails } = props;

  return (
    <div>
      <div className="px-4 py-5 sm:p-2 grid grid-cols-1 overflow-hidden rounded-lg bg-white shadow md:grid-cols-1">
        <div className="col-span-1">
          <h3 className="text-base font-semibold leading-6 text-gray-900">
            Invoice Details
          </h3>
        </div>

        <div className="mt-2 col-span-1">
          <Input
            label="Date"
            name="date"
            value={formatDate(invoiceDetails.created_at)}
            labelOn={true}
            type="text"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-0 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
          />
        </div>
        <div className="mt-2 col-span-1">
          <Input
            label="Invoice"
            name="invoice_number"
            value={invoiceDetails.invoice_number}
            labelOn={true}
            type="text"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-0 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
          />
        </div>
        <div className="mt-2 col-span-1">
          <Input
            label="Supplier"
            name="supplier_name"
            value={invoiceDetails.supplier_name}
            labelOn={true}
            type="text"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-0 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
          />
        </div>
        <div className="mt-2 col-span-1"></div>

        <div className="mt-2 col-span-1">
          <Input
            label="Unit Price"
            name="unit_cost"
            value={invoiceDetails.unit_cost}
            labelOn={true}
            type="text"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-0 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
          />
        </div>
        <div className="mt-2 col-span-1">
          <Input
            label="Location"
            name="location"
            value={invoiceDetails.location}
            labelOn={true}
            type="text"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-0 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
          />
        </div>
        <div className="mt-2 col-span-1">
          <Input
            label="Zone"
            name="zone"
            value={invoiceDetails.zone}
            labelOn={true}
            type="text"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-0 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
          />
        </div>
        <div className="mt-2 col-span-1">
          <Input
            label="Used for lot:"
            name="used_for"
            value={invoiceDetails.used_for}
            labelOn={true}
            type="text"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-0 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
          />
        </div>
      </div>
    </div>
  );
}
