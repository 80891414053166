import React from "react";
import { classNames } from "../../../../shared/utility";

export default function ManufactureIngredientsTable(props) {
  const {
    productAssemblyItems,
    openAssemblyItemPopUpToAddToProduct,
    openReceivingItemInformation,
    askDeleteProductionIngredient,
    isTraceability,
  } = props;

  return (
    <div className="mt-5">
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <h3 className="text-lg mb-2">Current ingredients</h3>
            <div className="overflow-hidden card-custom">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-white">
                  <tr>
                    <th
                      scope="col"
                      className="py-3 pl-3 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Product Lot/Serial
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Supplier
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Item Plu
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Item Name
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Used Unit
                    </th>
                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                    ></th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {productAssemblyItems.length > 0 ? (
                    productAssemblyItems.map((assemblyItem, index) => (
                      <tr
                        key={assemblyItem.id}
                        className={classNames(
                          assemblyItem.in_stock === 0
                            ? "bg-red-100"
                            : assemblyItem.is_used
                            ? "bg-cyan-100"
                            : null
                        )}
                      >
                        <td className="whitespace-nowrap py-1.5 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          {assemblyItem.product_lot}
                        </td>
                        <td className="whitespace-nowrap px-3 py-1.5 text-sm text-gray-500">
                          {assemblyItem.supplier_name}
                        </td>
                        <td className="whitespace-nowrap px-3 py-1.5 text-sm text-gray-500">
                          {assemblyItem.product_code}
                        </td>
                        <td className="whitespace-nowrap px-3 py-1.5 text-sm text-gray-500">
                          {assemblyItem.product_name}
                        </td>
                        <td className="whitespace-nowrap px-3 py-1.5 text-sm text-gray-500">
                          {assemblyItem.ingredient_amount_used}{" "}
                          {assemblyItem.unit_of_measure}
                        </td>
                        <td className="relative whitespace-nowrap py-1.5 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                          <button
                            onClick={() => {
                              openAssemblyItemPopUpToAddToProduct(
                                assemblyItem,
                                true
                              );
                            }}
                            className={classNames(
                              isTraceability ? "hidden" : null,

                              "rounded-md bg-yellow-600 px-3 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-yellow-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-600"
                            )}
                          >
                            Edit
                          </button>
                          <button
                            onClick={() => {
                              openReceivingItemInformation(assemblyItem);
                            }}
                            className="ml-2 rounded-md bg-main-purple px-3 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                          >
                            Receiving Info
                          </button>
                          <button
                            onClick={() =>
                              askDeleteProductionIngredient(assemblyItem)
                            }
                            className={classNames(
                              isTraceability ? "hidden" : null,
                              "ml-2 rounded-md bg-red-600 px-3 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                            )}
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={6} className="py-5 text-center">
                        Please choose an ingredient
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
