import { useState } from "react";

import { makePostApiCall } from "../../../shared/makeApiCall";
import UserDetailsForm from "../../../components/forms/user/UserDetailsForm";
import { BannerNotificationType } from "../../../components/SimpleBanner";

export function UsersViewModel(logout) {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState({
    data: [],
  });

  const [showSimpleNotification, setShowSimpleNotification] = useState({
    show: false,
    message: null,
    title: null,
    type: BannerNotificationType.Success,
  });
  const [slideOver, setSlideOver] = useState({
    childComponent: null,
    open: false,
  });

  const user_id = localStorage.getItem("id") * 1;

  const getUsers = async function (search, page) {
    setLoading(true);
    const payload = {
      page: page,
      search: search,
    };
    let callResult = await makePostApiCall(`users`, payload);
    setLoading(false);
    if (callResult.success) {
      const usersMapped = callResult.data.data.map((user) => {
        var userType = null;
        switch (user.user_type) {
          case 0:
            userType = "Employee";
            break;
          case 1:
            userType = "Admin";
            break;
          case 2:
            userType = "CFIA Inspector";
            break;
          default:
            break;
        }

        return {
          ...user,
          user_type_text: userType,
        };
      });
      setUsers({ ...callResult.data, data: usersMapped });
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const createUser = async function (values) {
    const payload = { ...values, user_id: user_id };
    let callResult = await makePostApiCall("createUser", payload);

    if (callResult.success) {
      setShowSimpleNotification({
        show: true,
        title: "User added",
        type: BannerNotificationType.Success,
      });
      setSlideOver({
        childComponent: null,
        open: false,
      });
      setTimeout(function () {
        getUsers("", 1);
      }, 1000);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const updateUser = async function (user, values) {
    let callResult = await makePostApiCall(`users/${user.id}`, values);

    if (callResult.success) {
      setShowSimpleNotification({
        show: true,
        title: "User updated",
        type: BannerNotificationType.Success,
      });
      setSlideOver({
        childComponent: null,
        open: false,
      });
      setTimeout(function () {
        getUsers("", 1);
      }, 1000);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const onChangePassword = async function (user, values) {
    let callResult = await makePostApiCall(
      `changeUserPassword/${user.id}`,
      values
    );

    if (callResult.success) {
      setShowSimpleNotification({
        show: true,
        title: "User password updated",
        type: BannerNotificationType.Success,
      });
      setSlideOver({
        childComponent: null,
        open: false,
      });
      setTimeout(function () {
        getUsers("", 1);
      }, 1000);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const openAddUserSlideOver = () => {
    setSlideOver({
      childComponent: (
        <UserDetailsForm
          isEdit={false}
          setOpen={setSlideOver}
          actionCall={(newValues) => createUser(newValues)}
        />
      ),
      open: true,
    });
  };

  const viewUserDetails = async function (user) {
    setSlideOver({
      childComponent: (
        <UserDetailsForm
          user={user}
          isEdit={true}
          setOpen={setSlideOver}
          actionCall={(newValues) => updateUser(user, newValues)}
          onChangePassword={(newValues) => onChangePassword(user, newValues)}
        />
      ),
      open: true,
    });
  };

  const deleteUser = async function (user) {
    let callResult = await makePostApiCall(`deleteUser/${user.id}`);

    if (callResult.success) {
      setShowSimpleNotification({
        show: true,
        title: "User updated",
        type: BannerNotificationType.Success,
      });
      const newList = users.data.filter((el) => el.id !== user.id);
      setUsers({ ...users, data: newList });
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  return {
    openAddUserSlideOver,
    getUsers,
    loading,
    users,
    setSlideOver,
    slideOver,
    setShowSimpleNotification,
    showSimpleNotification,
    viewUserDetails,
    deleteUser,
  };
}
