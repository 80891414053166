import { Dialog } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";

import Input from "../../controls/Input";
import { formatDate } from "../../../shared/utility";

export default function ReceivingItemInformation(props) {
  const { receivingItem, setOpen } = props;

  return (
    <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
      <div className="flex min-h-0 flex-1 flex-col overflow-y-scroll py-6">
        <div className="px-4 sm:px-6">
          <div className="flex items-start justify-between">
            <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
              Receiving Record
            </Dialog.Title>
            <div className="ml-3 flex h-7 items-center">
              <button
                type="button"
                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                onClick={() => setOpen({ childComponent: null, open: false })}
              >
                <span className="sr-only">Close panel</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
        <div className="relative mt-6 flex-1 px-4 sm:px-6">
          <div className="grid grid-cols-5 gap-4">
            <div>
              <Input
                label="Invoice Number"
                name="invoice_number"
                value={receivingItem.invoice_number}
                labelOn={true}
                disabled={true}
                type="text"
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
              />
            </div>
            <div>
              <Input
                label="Unit Cost"
                name="unit_cost"
                value={receivingItem.unit_cost}
                labelOn={true}
                disabled={true}
                type="text"
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
              />
            </div>
            <div>
              <Input
                label="Received Date"
                name="date"
                value={formatDate(receivingItem.received_date)}
                labelOn={true}
                disabled={true}
                type="text"
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
              />
            </div>
            <div>
              <Input
                label="Supplier"
                name="supplier_name"
                value={receivingItem.supplier_name}
                labelOn={true}
                type="text"
                disabled={true}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
              />
            </div>
            <div>
              <Input
                label="Manufacturer"
                name="manufacturer_name"
                value={receivingItem.manufacturer_name}
                labelOn={true}
                type="text"
                disabled={true}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
              />
            </div>
          </div>
          <div className="mt-3 grid grid-cols-3 gap-4">
            <div>
              <Input
                label="Received by"
                name="received_by"
                value={receivingItem.received_by}
                labelOn={true}
                type="text"
                disabled={true}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
              />
            </div>
            <div>
              <Input
                label="Location"
                name="location"
                value={receivingItem.location}
                labelOn={true}
                type="text"
                disabled={true}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
              />
            </div>
            <div>
              <Input
                label="Zone"
                name="zone"
                value={receivingItem.zone}
                labelOn={true}
                disabled={true}
                type="text"
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
              />
            </div>
          </div>
          <div className="mt-3 grid grid-cols-2 gap-4">
            <div>
              <Input
                label="Product name"
                name="product_name"
                value={receivingItem.product_name}
                labelOn={true}
                type="text"
                disabled={true}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
              />
            </div>
            <div>
              <Input
                label="Product Lot"
                name="product_lot"
                value={receivingItem.product_lot}
                labelOn={true}
                disabled={true}
                type="text"
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
              />
            </div>
          </div>
          <div className="mt-3 grid grid-cols-4 gap-4">
            <div>
              <Input
                label="Total unit (received)"
                name="total_unit"
                value={`${receivingItem.total_unit} ${receivingItem.unit_of_measure}`}
                labelOn={true}
                disabled={true}
                type="text"
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
              />
            </div>
            <div>
              <Input
                label="Used Unit for this product"
                name="amount_used"
                value={`${receivingItem.ingredient_amount_used} ${receivingItem.unit_of_measure}`}
                disabled={true}
                labelOn={true}
                type="text"
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
              />
            </div>
            <div>
              <Input
                label="Used Unit overall"
                name="amount_used"
                value={`${receivingItem.receiving_item_total_used_unit} ${receivingItem.unit_of_measure}`}
                disabled={true}
                labelOn={true}
                type="text"
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
              />
            </div>
            <div>
              <Input
                label="Leftover"
                name="in_stock"
                value={`${receivingItem.in_stock} ${receivingItem.unit_of_measure}`}
                disabled={true}
                labelOn={true}
                type="text"
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
              />
            </div>
          </div>
          <div className="mt-3 grid grid-cols-7 gap-2">
            <div className="col-span-1">
              <Input
                label="Box Weight"
                name="total_unit"
                value={`${receivingItem.total_unit} ${receivingItem.unit_of_measure}`}
                labelOn={true}
                type="text"
                disabled={true}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
              />
            </div>
            <div className="col-span-3">
              <Input
                label="Box Barcode"
                name="box_barcode"
                value={receivingItem.box_barcode}
                labelOn={true}
                type="text"
                disabled={true}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
              />
            </div>
            <div className="col-span-1">
              <Input
                label="Box PLU"
                name="product_code"
                value={receivingItem.product_code}
                labelOn={true}
                type="text"
                disabled={true}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
              />
            </div>
            <div className="col-span-1">
              <Input
                label="Box Lot"
                name="product_lot"
                value={receivingItem.product_lot}
                labelOn={true}
                type="text"
                disabled={true}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
              />
            </div>
            <div className="col-span-1">
              <Input
                label="Box Date"
                name="box_date"
                value={formatDate(receivingItem.box_date)}
                labelOn={true}
                type="text"
                disabled={true}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
              />
            </div>
          </div>
          <div className="mt-3 grid grid-cols-7 gap-2">
            <div className="col-span-1">
              <Input
                label="Product Temperature &deg;"
                name="product_temperature"
                value={receivingItem.product_temperature}
                labelOn={true}
                type="text"
                disabled={true}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
              />
            </div>
            <div className="col-span-1">
              <Input
                label="Product Condition"
                name="product_condition"
                value={receivingItem.product_condition}
                labelOn={true}
                type="text"
                disabled={true}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
              />
            </div>
            <div className="col-span-1">
              <Input
                label="Product Quality"
                name="product_quality"
                value={receivingItem.product_quality}
                labelOn={true}
                type="text"
                disabled={true}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
              />
            </div>
            <div className="col-span-1">
              <Input
                label="Truck Temperature &deg;"
                name="truck_temperature"
                value={receivingItem.truck_temperature}
                labelOn={true}
                type="text"
                disabled={true}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
              />
            </div>
            <div className="col-span-1">
              <Input
                label="Truck Condition"
                name="truck_condition"
                value={receivingItem.truck_condition}
                labelOn={true}
                type="text"
                disabled={true}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
              />
            </div>
          </div>
          <div className="mt-3 grid grid-cols-7 gap-2">
            <div className="col-span-1">
              <Input
                label="Certification"
                name="certifications"
                value={receivingItem.certifications}
                labelOn={true}
                type="text"
                disabled={true}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
              />
            </div>
            <div className="col-span-1">
              <Input
                label="Claims"
                name="claims"
                value={receivingItem.claims}
                labelOn={true}
                type="text"
                disabled={true}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
              />
            </div>
            <div className="col-span-1">
              <Input
                label="Allergens"
                name="allergens"
                value={receivingItem.allergens}
                labelOn={true}
                type="text"
                disabled={true}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
              />
            </div>
            <div className="col-span-1">
              <Input
                label="Preservatives"
                name="preservatives"
                value={receivingItem.preservatives}
                labelOn={true}
                type="text"
                disabled={true}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-shrink-0 justify-end px-4 py-4">
        <button
          type="button"
          className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400"
          onClick={() => setOpen({ childComponent: null, open: false })}
        >
          Close
        </button>
      </div>
    </div>
  );
}
