import { useEffect, useState } from "react";
import { useForm } from "../../useForm";

import { Dialog } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";

import Input from "../../../controls/Input";

import ImportMediaDropzone from "../../../importMedia/ImportMediaDropzone";

const initialFValues = {
  name: "",
  address: "",
  city: "",
  zip: "",
  state: "",
  country: "",
  phone: "",
  fax: "",
  image1: "",
  image2: "",
  image3: "",
  image4: "",
  website: "",
  google: "",
  instagram: "",
  twitter: "",
  tiktok: "",
};

export default function BrandDetailsForm(props) {
  const { index, brand, setOpen, actionCall, isEdit } = props;

  const [logoImage1, setLogoImage1] = useState(null);
  const [logoImage2, setLogoImage2] = useState(null);
  const [logoImage3, setLogoImage3] = useState(null);

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isEdit) {
      actionCall(brand, values, index);
    } else {
      actionCall(values);
    }
  };

  useEffect(() => {
    if (isEdit) {
      setValues(brand);
    }
  }, [brand, setValues]);

  return (
    <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
      <div className="flex min-h-0 flex-1 flex-col overflow-y-scroll py-6">
        <div className="px-4 sm:px-6">
          <div className="flex items-start justify-between">
            <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
              {isEdit ? "Edit brand" : "Add new brand"}
            </Dialog.Title>
            <div className="ml-3 flex h-7 items-center">
              <button
                type="button"
                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                onClick={() => setOpen({ childComponent: null, open: false })}
              >
                <span className="sr-only">Close panel</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
        <div className="relative mt-6 flex-1 px-4 sm:px-6">
          <div>
            <div className="mt-2">
              <Input
                label="Name"
                name="name"
                onChange={handleInputChange}
                value={values.name}
                labelOn={true}
                type="text"
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
              />
            </div>
            <div className="mt-3 grid grid-cols-2 gap-4">
              <div>
                <Input
                  label="Address"
                  name="address"
                  onChange={handleInputChange}
                  value={values.address}
                  labelOn={true}
                  type="text"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                />
              </div>
              <div>
                <Input
                  label="City"
                  name="city"
                  onChange={handleInputChange}
                  value={values.city}
                  labelOn={true}
                  type="text"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                />
              </div>
            </div>
            <div className="mt-3 grid grid-cols-2 gap-4">
              <div>
                <Input
                  label="Zip"
                  name="zip"
                  onChange={handleInputChange}
                  value={values.zip}
                  labelOn={true}
                  type="text"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                />
              </div>
            </div>
            <div className="mt-3 grid grid-cols-2 gap-4">
              <div>
                <Input
                  label="State"
                  name="state"
                  onChange={handleInputChange}
                  value={values.state}
                  labelOn={true}
                  type="text"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                />
              </div>
              <div>
                <Input
                  label="Country"
                  name="country"
                  onChange={handleInputChange}
                  value={values.country}
                  labelOn={true}
                  type="text"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                />
              </div>
            </div>
            <div className="mt-3 grid grid-cols-2 gap-4">
              <div>
                <Input
                  label="Phone"
                  name="phone"
                  onChange={handleInputChange}
                  value={values.phone}
                  labelOn={true}
                  type="text"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                />
              </div>
              <div>
                <Input
                  label="Fax"
                  name="fax"
                  onChange={handleInputChange}
                  value={values.fax}
                  labelOn={true}
                  type="text"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                />
              </div>
            </div>
            <div className=" mt-3 grid grid-cols-3">
              <div className="grid-col-1">
                <div className="flex space-x-2">
                  <label
                    htmlFor="image1"
                    className="block text-sm font-medium text-gray-900"
                  >
                    Brand image
                  </label>
                </div>
                {logoImage1 ? (
                  <div>
                    <img
                      src={logoImage1}
                      height="140"
                      width="140"
                      alt="New logo"
                    />
                  </div>
                ) : values.image1 ? (
                  <img
                    src={values.image1}
                    alt={values.image1}
                    title={values.image1}
                    className="media-image rounded-md"
                    height="140"
                    width="140"
                  />
                ) : null}
                <ImportMediaDropzone
                  acceptedType={{ "image/png": [".png"] }}
                  fileName="image1"
                  handleInputChange={handleInputChange}
                  setUploadedImage={setLogoImage1}
                />
              </div>
              <div className="grid-col-1">
                <div className="flex space-x-2">
                  <label
                    htmlFor="image2"
                    className="block text-sm font-medium text-gray-900"
                  >
                    Certification
                  </label>
                </div>
                {logoImage2 ? (
                  <div>
                    <img
                      src={logoImage2}
                      height="140"
                      width="140"
                      alt="New logo"
                    />
                  </div>
                ) : values.image2 ? (
                  <img
                    src={values.image2}
                    alt={values.image2}
                    title={values.image2}
                    className="media-image rounded-md"
                    height="140"
                    width="140"
                  />
                ) : null}
                <ImportMediaDropzone
                  acceptedType={{ "image/png": [".png"] }}
                  fileName="image2"
                  handleInputChange={handleInputChange}
                  setUploadedImage={setLogoImage2}
                />
              </div>
              <div>
                <div className="flex space-x-2">
                  <label
                    htmlFor="image3"
                    className="block text-sm font-medium text-gray-900"
                  >
                    Certification 2 (Optional)
                  </label>
                </div>
                {logoImage3 ? (
                  <div>
                    <img
                      src={logoImage3}
                      height="140"
                      width="140"
                      alt="New logo"
                    />
                  </div>
                ) : values.image3 ? (
                  <img
                    src={values.image3}
                    alt={values.image3}
                    title={values.image3}
                    className="media-image rounded-md"
                    height="140"
                    width="140"
                  />
                ) : null}
                <ImportMediaDropzone
                  acceptedType={{ "image/png": [".png"] }}
                  fileName="image3"
                  handleInputChange={handleInputChange}
                  setUploadedImage={setLogoImage3}
                />
              </div>
            </div>
            <div className="mt-3 grid grid-cols-2 gap-4">
              <div>
                <Input
                  label="Website"
                  name="website"
                  onChange={handleInputChange}
                  value={values.website}
                  labelOn={true}
                  type="text"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                />
              </div>
              <div>
                <Input
                  label="Google"
                  name="google"
                  onChange={handleInputChange}
                  value={values.google}
                  labelOn={true}
                  type="text"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                />
              </div>
            </div>
            <div className="mt-3 grid grid-cols-2 gap-4">
              <div>
                <Input
                  label="Instagram"
                  name="instagram"
                  onChange={handleInputChange}
                  value={values.instagram}
                  labelOn={true}
                  type="text"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                />
              </div>
              <div>
                <Input
                  label="Tiktok"
                  name="tiktok"
                  onChange={handleInputChange}
                  value={values.tiktok}
                  labelOn={true}
                  type="text"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-shrink-0 justify-end px-4 py-4">
        <button
          type="button"
          className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400"
          onClick={() => setOpen({ childComponent: null, open: false })}
        >
          Cancel
        </button>
        <button
          type="submit"
          className="ml-4 inline-flex justify-center rounded-md bg-main-purple px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          onClick={handleSubmit}
        >
          {isEdit ? "Save" : "Create"}
        </button>
      </div>
    </div>
  );
}
