import React, { useEffect } from "react";

import { useForm } from "../useForm";

import Input from "../../controls/Input";

const initialFValues = {
  original_on_hand: 0,
  inventory_on_hand: 0,
  quantity: 0,
};

export default function QuickScanAddToShippingForm(props) {
  const {
    confirmAction,
    item,
    orderQuantity,
    setOpen,
    currQuantity,
    selling_unit_of_measure,
  } = props;

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("quantity" in fieldValues) {
      temp.quantity = null;
      if (fieldValues.quantity < 1) {
        temp.quantity = "Quantity must be more than 0.";
      }

      if (
        fieldValues.quantity >
        parseFloat(item.inventory_on_hand) + parseFloat(fieldValues.quantity)
      ) {
        temp.quantity = "Quantity cannot be greater than inventory on hand.";
      }
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === null);
    }
  };

  const { values, setValues, errors, setErrors, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );

  const onSubmit = () => {
    if (validate()) {
      confirmAction(values);
    }
  };

  useEffect(() => {
    setValues({
      original_on_hand: item.inventory_on_hand,
      inventory_on_hand: item.inventory_on_hand,
      quantity: currQuantity,
    });
  }, [currQuantity]);

  return (
    <div className="justify-between">
      <div>
        <div className="flex items-center justify-between flex-wrap sm:flex-nowrap">
          <div className="mt-2">
            <h3 className="text-lg leading-6 font-medium text-gray-700">
              Add:{" "}
              <span className="text-gray-900 font-heavy">
                {item.product_name}
              </span>
            </h3>
            <h3 className="text-md leading-6 font-medium text-gray-700">
              Description:
              <span className="text-gray-900 font-heavy">
                {item.description_en}
              </span>
            </h3>
            <h3 className="text-md leading-6 font-medium text-gray-700">
              Lot:{" "}
              <span className="text-gray-900 font-heavy">
                {item.product_lot}
              </span>
            </h3>
          </div>
        </div>

        {/* here */}
        <div className="pt-2 pb-5 gap-4">
          <div className="bg-white sm:rounded-xl">
            <div className="mt-3 grid grid-cols-1 sm:grid-cols-3 gap-4">
              <Input
                label="Inventory on hand"
                name="inventory_on_hand"
                onChange={handleInputChange}
                value={values.inventory_on_hand}
                labelOn={true}
                type="text"
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                disabled={true}
              />
              <Input
                label="Selling unit"
                name="inventory_on_hand"
                onChange={handleInputChange}
                value={selling_unit_of_measure}
                labelOn={true}
                type="text"
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                disabled={true}
              />
              <div>
                <Input
                  label="Quantity"
                  name="quantity"
                  onChange={(e) => {
                    const newVal = e.target.value;
                    var tempInventoryOnHand =
                      newVal > 0
                        ? parseFloat(values.inventory_on_hand) +
                          parseFloat(values.quantity) -
                          parseFloat(newVal)
                        : values.inventory_on_hand;
                    setValues({
                      ...values,
                      inventory_on_hand: tempInventoryOnHand,
                      quantity: newVal,
                    });
                  }}
                  value={values.quantity}
                  labelOn={true}
                  type="number"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                  error={errors.quantity}
                />
                <p
                  className="cursor-pointer text-indigo-600 font-bold"
                  onClick={() => {
                    setValues({
                      ...values,
                      inventory_on_hand:
                        parseFloat(values.inventory_on_hand) +
                        parseFloat(values.quantity) -
                        parseFloat(orderQuantity),
                      quantity: orderQuantity,
                    });
                  }}
                >
                  Set order quantity
                </p>
              </div>
            </div>
          </div>

          <div className="sm:flex-no-wrap">
            <div className="my-auto flex items-center">
              <button
                onClick={() => onSubmit()}
                className="inline-flex justify-center mt-3 py-35-custom px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-main-purple hover:bg-indigo-700 items-center"
              >
                OK
              </button>
              <button
                onClick={() =>
                  setOpen({
                    open: false,
                  })
                }
                className="ml-2 inline-flex justify-center mt-3 py-35-custom px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 items-center"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
