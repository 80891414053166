import React, { useEffect } from "react";

import Input from "../../controls/Input";

import { useForm, Form } from "../useForm";

const initialFValues = {
  name: "",
  email: "",
};

export default function MyAccountForm(props) {
  const { updateAccountDetails, userDetails } = props;

  var loading = false;

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("name" in fieldValues) {
      temp.name = null;
      if (fieldValues.name === "") {
        temp.name = "This field is required.";
      }
    }

    if ("email" in fieldValues) {
      temp.email = null;
      if (fieldValues.email === "") {
        temp.email = "This field is required.";
      }
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === null);
    }
  };

  const { values, setValues, errors, setErrors, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      updateAccountDetails(values);
    }
  };

  useEffect(() => {
    setValues({
      name: userDetails.name,
      email: userDetails.email,
    });
  }, [userDetails]);

  return (
    <Form
      classes="divide-y divide-gray-200 lg:col-span-9"
      onSubmit={handleSubmit}
    >
      <div className="py-6 px-4 sm:p-6 lg:pb-8">
        <div className="mt-6 grid grid-cols-12 gap-6">
          <div className="col-span-12 sm:col-span-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Account details
            </h3>
          </div>
        </div>

        <div className="mt-3 grid grid-cols-12 gap-6">
          <div className="col-span-12 sm:col-span-6">
            <Input
              label="Name"
              name="name"
              onChange={handleInputChange}
              value={values.name}
              labelOn={true}
              type="text"
              error={errors.name}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            />
          </div>
        </div>

        <div className="mt-6 grid grid-cols-12 gap-6">
          <div className="col-span-12 sm:col-span-6">
            <Input
              label="Email"
              name="email"
              onChange={handleInputChange}
              value={values.email}
              labelOn={true}
              type="email"
              error={errors.email}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            />
          </div>
        </div>
      </div>

      <div className="pt-6 divide-y divide-gray-200">
        <div className="grid grid-cols-1">
          <div className="px-4 col-span-1 sm:col-span-1 flex justify-end font-medium text-red-500">
            {/* {error ? error.subtitle : null} */}
          </div>
          <div className="mt-4 py-4 px-4 sm:px-6 col-span-1 sm:col-span-1 flex justify-end">
            <button
              type="submit"
              className="ml-5 bg-sky-700 border border-gray-300 rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white bg-main-purple hover:bg-sky-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
              disabled={loading}
            >
              {loading ? (
                <svg
                  className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              ) : null}
              Save
            </button>
          </div>
        </div>
      </div>
    </Form>
  );
}
