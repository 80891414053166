import React, { useState } from "react";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { classNames, formatDateWithTime } from "../../../shared/utility";

export default function OrderItemsForShipping(props) {
  const {
    askDeleteShippingItem,
    orderItems,
    openQuickScanPopupWithFetchLatest,
  } = props;

  const [expandedRows, setExpandedRows] = useState(null);

  const allowExpansion = (rowData) => {
    const shippingItems = rowData.shippingItems ? rowData.shippingItems : [];
    return shippingItems.length > 0;
  };

  const shippingItemActionTemplate = (rowData, row) => {
    console.log("rowData", rowData);
    return (
      <div className="ml-10">
        <button
          className={classNames(
            rowData.is_quick_scan ? null : "hidden",
            "rounded-md bg-main-purple px-3 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          )}
          onClick={() => openQuickScanPopupWithFetchLatest(rowData)}
        >
          Edit
        </button>
        {/*  <button
          onClick={() => {
          }}
          className="ml-2 rounded-md bg-main-purple px-3 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Receiving Info
        </button> */}
        <button
          onClick={() => askDeleteShippingItem(rowData)}
          className="ml-2 rounded-md bg-red-600 px-3 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
        >
          Delete
        </button>
      </div>
    );
  };

  const packagingDateTemplate = (rowData) => {
    return <p>{formatDateWithTime(rowData.packaging_date)}</p>;
  };

  const packagingSizeTemplate = (rowData) => {
    return <p>{rowData.packing_size} kg</p>;
  };

  const barcodeBody = (rowData) => {
    let fontColor = null;
    let text = rowData.shipping_barcode;

    if (rowData.is_lot) {
      fontColor = "text-indigo-800";
      text = rowData.product_lot;
    }
    return <p className={classNames(fontColor, "font-medium")}>{text}</p>;
  };

  const productDetailsBody = (rowData) => {
    return (
      <p>
        <span className="text-gray-900 font-semibold">
          {rowData.product_name}
        </span>
        <br />
        <span className="text-gray-700">PLU:{rowData.plu}</span>
      </p>
    );
  };

  const orderQuantityBody = (rowData) => {
    return <p>{rowData.quantity}</p>;
  };

  const shippingItemQuantityBody = (rowData) => {
    let text = 1;
    if (rowData.is_lot) {
      text = rowData.shippingItems.length;
    }
    return <p>{text}</p>;
  };

  const rowExpansionTemplate = (data) => {
    return (
      <div className="p-3">
        {/* <h5>Orders for {data.name}</h5> */}
        <DataTable
          value={data.shippingItems}
          tableClassName="min-w-full bg-white divide-y divide-gray-300"
          cellClassName="py-2 bg-gray-100 border-1-bottom-gray px-2"
        >
          <Column header="Barcode/Lot" body={barcodeBody}></Column>
          <Column header="Quantity" body={shippingItemQuantityBody}></Column>
          <Column header="Packing Size" body={packagingSizeTemplate}></Column>
          <Column header="Packaged date" body={packagingDateTemplate}></Column>
          <Column header="" body={shippingItemActionTemplate} />
        </DataTable>
      </div>
    );
  };

  const parentItemActionBody = (rowData, row) => {
    return null;
  };

  return (
    <div className="mt-5 flow-root">
      <div className="sm:-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div className="overflow-hidden">
            <DataTable
              value={orderItems}
              expandedRows={expandedRows}
              onRowToggle={(e) => {
                setExpandedRows(e.data);
              }}
              rowExpansionTemplate={rowExpansionTemplate}
              dataKey="id"
              tableClassName="min-w-full bg-white divide-y divide-gray-300 text-sm"
              cellClassName="p-2 bg-white border-1-bottom-gray text-sm"
            >
              <Column
                header="Product"
                body={productDetailsBody}
                style={{ width: "40%" }}
              />
              <Column
                header="Order quantity/Cs"
                body={orderQuantityBody}
                style={{ width: "15%" }}
              />
              <Column
                field="shipped_amount"
                header="Shipped quantity"
                style={{ width: "25%" }}
              />
              <Column
                header="Unit of measure"
                field="selling_unit_of_measure"
                style={{ width: "10%" }}
              />
              <Column
                header=""
                body={parentItemActionBody}
                style={{ width: "10%" }}
              />
              <Column expander={allowExpansion} style={{ width: "5rem" }} />
            </DataTable>
          </div>
        </div>
      </div>
    </div>
  );
}
