import React from "react";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { formatDate } from "../../../../shared/utility";

export default function TracePackagedProductsTable(props) {
  const { packagedProducts } = props;

  const productNameTemplate = (rowData) => {
    return (
      <p>
        {rowData.product_name} <br />
        {rowData.product_code}
      </p>
    );
  };

  const packagingDateTemplate = (rowData) => {
    return formatDate(rowData.packaging_date);
  };

  return (
    <div className="flow-root">
      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          {/* <h3 className="text-lg mb-2">Packaged Products</h3> */}
          <div className="overflow-hidden card-custom bg-white">
            <DataTable
              value={packagedProducts}
              dataKey="id"
              tableClassName="min-w-full bg-white divide-y divide-gray-300"
              cellClassName="py-2 bg-gray-100 border-1-bottom-gray"
            >
              <Column header="Product Name" body={productNameTemplate} />
              <Column field="product_lot" header="Product Lot" />
              <Column field="brand_name" header="Brand Name" />
              <Column header="Packaging Date" body={packagingDateTemplate} />
              <Column field="description_en" header="Description" />
              <Column field="best_before_date" header="Best Before Date" />
              <Column field="packing_size" header="Packing Size" />
              <Column field="supervisor_name" header="Supervisor Name" />
            </DataTable>
          </div>
        </div>
      </div>
    </div>
  );
}
