import { useEffect } from "react";

export default function CostCalcution(props) {
  const printNoDialog = () => {
    window.print();
  };

  useEffect(() => {
    printNoDialog();
    window.close();
  }, []);

  return (
    <div>
      <div className="invoice-box">
        <table cellpadding="0" cellspacing="0">
          <tr class="top">
            <td colspan="2">
              <table>
                <tr>
                  <td class="title"></td>

                  <td>
                    Invoice #: 123
                    <br />
                    Created: January 1, 2023
                    <br />
                    Due: February 1, 2023
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
      </div>
    </div>
  );
}
