import { useState } from "react";

import { makePostApiCall } from "../../shared/makeApiCall";
import DeleteConfirmationForm from "../../components/forms/DeleteConfirmationForm";
import { BannerNotificationType } from "../../components/SimpleBanner";
import DateRangeFilter from "../../components/filterDropdown/DateRangeFilter";
import FilterSingleSelectDropdown from "../../components/filterDropdown/FilterSingleSelectDropdown";
import { orderStatusOptions } from "../../shared/utility";
import ModelErrorComponent from "../../components/modals/ModelErrorComponent";

export function ManageOrdersViewModel(logout) {
  const [customers, setCustomers] = useState([]);

  const [filters, setFilters] = useState({
    customer: { value: -1, label: "All Customers" },
    orderStatus: { value: -1, label: "All Orders" },
    startDate: null,
    endDate: null,
  });
  const [emptyModal, setEmptyModelOpen] = useState({
    open: false,
    childComponent: null,
  });
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState({ data: [] });
  const [showFilters, setShowFilters] = useState(false);
  const [showSimpleNotification, setShowSimpleNotification] = useState({
    show: false,
    message: null,
    title: null,
    type: BannerNotificationType.Success,
  });
  const [slideOver, setSlideOver] = useState({
    childComponent: null,
    open: false,
  });

  const getOrders = async function (search, page, filterArg) {
    setLoading(true);
    const payload = {
      page: page,
      filters: { ...filterArg, search: search },
    };
    let callResult = await makePostApiCall("fetchOrders", payload);

    if (callResult.success) {
      setOrders(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
    setLoading(false);
  };

  const showErrorModal = (title, subtitle) => {
    setEmptyModelOpen({
      childComponent: (
        <ModelErrorComponent
          setOpen={setEmptyModelOpen}
          subtitle={subtitle}
          title={title}
        />
      ),
      open: true,
    });
  };

  const deleteOrder = async function (id) {
    let callResult = await makePostApiCall(`deleteOrder/${id}`);

    if (callResult.success) {
      const filteredItems = orders.data.filter((order) => order.id !== id);
      setOrders({ ...orders, data: filteredItems });

      setEmptyModelOpen({
        open: false,
        childComponent: null,
      });
      setShowSimpleNotification({
        show: true,
        title: callResult.data.message,
        type: BannerNotificationType.Success,
      });
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
      showErrorModal(callResult.data.message);
    }
  };

  const askDeleteOrder = function (order) {
    setEmptyModelOpen({
      childComponent: (
        <DeleteConfirmationForm
          buttonMessage="Delete"
          confirmAction={() => deleteOrder(order.id)}
          message={`Are you sure you want to delete ${order.po_number}?`}
          setOpen={setEmptyModelOpen}
        />
      ),
      open: true,
    });
  };

  const getCustomersForDropdown = async function () {
    let callResult = await makePostApiCall(`getCustomersForDropdown`);
    if (callResult.success) {
      setCustomers(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const resetFilters = () => {
    const filterReset = {
      customer: { value: -1, label: "All Customers" },
      orderStatus: { value: -1, label: "All Orders" },
      startDate: null,
      endDate: null,
    };
    setFilters(filterReset);

    setShowFilters(false);
    getOrders(null, 1, filterReset);
  };

  const filterComponents = [
    <DateRangeFilter
      endDate={filters.endDate}
      onChange={(name, value) =>
        setFilters({
          ...filters,
          [name]: value,
        })
      }
      resetFilter={() =>
        setFilters({
          ...filters,
          startDate: null,
          endDate: null,
        })
      }
      startDate={filters.startDate}
    />,
    <FilterSingleSelectDropdown
      header="Order Status"
      onChange={(value) =>
        setFilters({
          ...filters,
          orderStatus: value,
        })
      }
      options={orderStatusOptions}
      resetFilter={() =>
        setFilters({
          ...filters,
          orderStatus: { value: -1, label: "All Orders" },
        })
      }
      valueName="orderStatus"
      value={filters.orderStatus}
    />,
    <FilterSingleSelectDropdown
      header="Customer"
      onChange={(value) =>
        setFilters({
          ...filters,
          customer: value,
        })
      }
      options={customers}
      resetFilter={() =>
        setFilters({
          ...filters,
          customer: { value: -1, label: "All Customers" },
        })
      }
      valueName="customer"
      value={filters.customer}
    />,
  ];

  return {
    customers,
    filterComponents,
    getCustomersForDropdown,
    getOrders,
    loading,
    orders,
    showSimpleNotification,
    setEmptyModelOpen,
    setShowSimpleNotification,
    setSlideOver,
    slideOver,
    emptyModal,
    askDeleteOrder,
    filters,
    resetFilters,
    setFilters,
    setShowFilters,
    showFilters,
  };
}
