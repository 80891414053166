import { useState } from "react";

import { makePostApiCall } from "../../../shared/makeApiCall";
import { BannerNotificationType } from "../../../components/SimpleBanner";
import DeleteConfirmationForm from "../../../components/forms/DeleteConfirmationForm";
import FilterSingleSelectDropdown from "../../../components/filterDropdown/FilterSingleSelectDropdown";
import DateRangeFilter from "../../../components/filterDropdown/DateRangeFilter";

const reportTypeOptions = [
  { value: 1, label: "Meat Receiving Report" },
  { value: 2, label: "Non-Meat Receiving Report" },
];

export function AllReceivingInvoicesViewModel(logout) {
  const [emptyModal, setEmptyModelOpen] = useState({
    open: false,
    childComponent: null,
  });
  const [loading, setLoading] = useState(false);
  const [receivingInvoices, setReceivingInvoices] = useState({
    data: [],
  });
  const [showSimpleNotification, setShowSimpleNotification] = useState({
    show: false,
    message: null,
    title: null,
    type: BannerNotificationType.Success,
  });
  const [slideOver, setSlideOver] = useState({
    childComponent: null,
    open: false,
  });
  const [reportFilters, setReportFilters] = useState({
    type: { value: -1, label: "Select report" },
    startDate: null,
    endDate: null,
  });
  const [reportInProgress, setReportInProgress] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [showReports, setShowReports] = useState(false);

  const getReceivingInvoices = async function (search, page) {
    setLoading(true);
    const payload = {
      page: page,
      search: search,
    };
    let callResult = await makePostApiCall(`receivingInvoices`, payload);
    setLoading(false);
    if (callResult.success) {
      setReceivingInvoices(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const deleteReceivingInvoice = async function (invoiceId, index) {
    let callResult = await makePostApiCall(
      `deleteReceivingInvoice/${invoiceId}`,
      {}
    );
    if (callResult.success) {
      setEmptyModelOpen({
        open: false,
        childComponent: null,
      });
      getReceivingInvoices(null, 0);
      setShowSimpleNotification({
        show: true,
        title: callResult.data.message,
        type: BannerNotificationType.Success,
      });
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const askDeleteReceivingInvoice = function (invoice, index) {
    setEmptyModelOpen({
      childComponent: (
        <DeleteConfirmationForm
          buttonMessage="Delete"
          confirmAction={() => deleteReceivingInvoice(invoice.id, index)}
          message={`Are you sure you want to delete invoice ${invoice.invoice_number} from ${invoice.supplier_name}?`}
          setOpen={setEmptyModelOpen}
        />
      ),
      open: true,
    });
  };

  const generateReceivingReport = async function () {
    setReportInProgress(true);
    let callResult = await makePostApiCall(
      `generateReceivingReport`,
      reportFilters
    );
    if (callResult.success) {
      setEmptyModelOpen({
        open: false,
        childComponent: null,
      });
      window.open(callResult.data.location, "_blank");
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
    setReportInProgress(false);
  };

  const reportComponents = [
    <FilterSingleSelectDropdown
      header="Report type"
      onChange={(value) =>
        setReportFilters({
          ...reportFilters,
          type: value,
        })
      }
      options={reportTypeOptions}
      resetFilter={() =>
        setReportFilters({
          ...reportFilters,
          type: { value: -1, label: "Select report" },
        })
      }
      valueName="type"
      value={reportFilters.type}
    />,
    <DateRangeFilter
      endDate={reportFilters.endDate}
      onChange={(name, value) =>
        setReportFilters({
          ...reportFilters,
          [name]: value,
        })
      }
      maxDate={new Date()}
      resetFilter={() =>
        setReportFilters({
          ...reportFilters,
          startDate: null,
          endDate: null,
        })
      }
      startDate={reportFilters.startDate}
    />,
  ];

  return {
    askDeleteReceivingInvoice,
    emptyModal,
    getReceivingInvoices,
    loading,
    receivingInvoices,
    setSlideOver,
    slideOver,
    setEmptyModelOpen,
    setShowSimpleNotification,
    showSimpleNotification,
    generateReceivingReport,
    reportComponents,
    showReports,
    setShowReports,
    reportInProgress,
  };
}
