import React from "react";

import { classNames } from "../../shared/utility";

import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";

export default function InputWithSearch(props) {
  const {
    parentClass,
    className,
    label,
    name,
    onChange,
    type,
    value,
    labelOn,
    error,
    explanation,
    ref,
    labelClass,
    placeholder,
    actionCall,
    disableSearch,
    ...other
  } = props;

  return (
    <div className={parentClass ? parentClass : null}>
      {labelOn ? (
        <div className="flex space-x-2 mb-1">
          <label
            htmlFor={name}
            className={classNames(
              labelClass ? labelClass : "text-sm",
              "block font-medium text-gray-900"
            )}
          >
            {label}
          </label>
        </div>
      ) : null}
      <div className="sm:items-center flex relative flex items-stretch flex-grow focus-within:z-10">
        <input
          type={type}
          name={name}
          onChange={onChange}
          className="block w-full border border-gray-300 rounded-l-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
          placeholder={placeholder}
          value={value}
          ref={ref}
          onKeyDown={(e) => (e.key === "Enter" ? actionCall() : null)}
        />
        <button
          type="button"
          className="-ml-px relative inline-flex items-center space-x-2 px-4 py-35-custom border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1"
          onClick={() => actionCall()}
          disabled={disableSearch}
        >
          <MagnifyingGlassIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </button>
      </div>
      {error ? <p className="font-medium text-red-500">{error}</p> : null}
    </div>
  );
}
