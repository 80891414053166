import { useState } from "react";

import { makePostApiCall, makePutApiCall } from "../../../shared/makeApiCall";
import MyIngredientsForm from "../../../components/forms/setup/myIngredients/MyIngredientsForm";
import { BannerNotificationType } from "../../../components/SimpleBanner";
import DeleteConfirmationForm from "../../../components/forms/DeleteConfirmationForm";

export function MyIngredientsViewModel(logout) {
  const [emptyModal, setEmptyModelOpen] = useState({
    open: false,
    childComponent: null,
  });
  const [loading, setLoading] = useState(false);
  const [myIngredients, setMyIngredients] = useState({ data: [] });
  const [productTypeOptions, setProductTypeOptions] = useState([]);
  const [unitOfMeasureOptions, setUnitOfMeasureOptions] = useState([]);

  const [showSimpleNotification, setShowSimpleNotification] = useState({
    show: false,
    message: null,
    title: null,
    type: BannerNotificationType.Success,
  });
  const [slideOver, setSlideOver] = useState({
    childComponent: null,
    open: false,
  });

  const user_id = localStorage.getItem("id");

  const getMyIngredients = async function (search, page) {
    setLoading(true);
    const payload = {
      page: page,
      search: search,
    };
    let callResult = await makePostApiCall(`myIngredients`, payload);
    setLoading(false);
    if (callResult.success) {
      setMyIngredients(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const createMyIngredient = async function (values) {
    const payload = { ...values, user_id: user_id };
    let callResult = await makePostApiCall("createMyIngredient", payload);

    if (callResult.success) {
      setShowSimpleNotification({
        show: true,
        title: "MyIngredient added",
        type: BannerNotificationType.Success,
      });
      setSlideOver({
        childComponent: null,
        open: false,
      });
      setTimeout(function () {
        getMyIngredients("", 1);
      }, 1000);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const updateMyIngredient = async function (myIngredient, values, index) {
    let callResult = await makePutApiCall(
      `myIngredients/${myIngredient.id}`,
      values
    );

    if (callResult.success) {
      setShowSimpleNotification({
        show: true,
        title: "MyIngredient updated",
        type: BannerNotificationType.Success,
      });
      setSlideOver({
        childComponent: null,
        open: false,
      });
      setTimeout(function () {
        getMyIngredients("", 1);
      }, 1000);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const openAddMyIngredientSlideOver = () => {
    setSlideOver({
      childComponent: (
        <MyIngredientsForm
          productTypeOptions={productTypeOptions}
          isEdit={false}
          setOpen={setSlideOver}
          actionCall={createMyIngredient}
          unitOfMeasureOptions={unitOfMeasureOptions}
        />
      ),
      open: true,
    });
  };

  const viewMyIngredientDetails = async function (myIngredient, index) {
    setSlideOver({
      childComponent: (
        <MyIngredientsForm
          myIngredient={myIngredient}
          productTypeOptions={productTypeOptions}
          index={index}
          isEdit={true}
          setOpen={setSlideOver}
          actionCall={updateMyIngredient}
          unitOfMeasureOptions={unitOfMeasureOptions}
        />
      ),
      open: true,
    });
  };

  const getProductTypes = async function () {
    let callResult = await makePostApiCall(`productTypesForDropdown`);

    if (callResult.success) {
      setProductTypeOptions(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const deleteMyIngredient = async function (id, index) {
    setLoading(true);

    let callResult = await makePostApiCall(`deleteMyIngredient/${id}`, {});
    setLoading(false);
    if (callResult.success) {
      setEmptyModelOpen({
        open: false,
        childComponent: null,
      });
      getMyIngredients(null, 0);
      setShowSimpleNotification({
        show: true,
        title: callResult.data.message,
        type: BannerNotificationType.Success,
      });
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const askDeleteMyIngredient = function (myIngredient, index) {
    setEmptyModelOpen({
      childComponent: (
        <DeleteConfirmationForm
          buttonMessage="Delete"
          confirmAction={() => deleteMyIngredient(myIngredient.id, index)}
          message={`Are you sure you want to delete ${myIngredient.name}?`}
          setOpen={setEmptyModelOpen}
        />
      ),
      open: true,
    });
  };

  const getUnitOfMeasuresForDropdown = async function () {
    let callResult = await makePostApiCall(`unitOfMeasuresForDropdown`);

    if (callResult.success) {
      setUnitOfMeasureOptions(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  return {
    askDeleteMyIngredient,
    emptyModal,
    setEmptyModelOpen,
    getMyIngredients,
    getProductTypes,
    loading,
    myIngredients,
    openAddMyIngredientSlideOver,
    setSlideOver,
    setShowSimpleNotification,
    slideOver,
    showSimpleNotification,
    viewMyIngredientDetails,
    getUnitOfMeasuresForDropdown,
  };
}
