import React, { useState } from "react";
import SelectMenu from "../../../controls/SelectMenu";
import DatePickerInput from "../../../controls/DatePickerInput";
import ButtonSpinner from "../../../controls/ButtonSpinner";

const receivingReportOptions = [{ value: 1, label: "Sensor logs" }];

export default function TemperatureSensorsReportModal(props) {
  const { confirmAction, setOpen } = props;

  const [reportFilters, setReportFilters] = useState({
    type: { value: -1, label: "Select report" },
    startDate: null,
    endDate: null,
  });
  const [actionInProgress, setActionInProgress] = useState(false);

  return (
    <div className="justify-between pt-20 mt-20">
      <div>
        <div className="flex items-center justify-between flex-wrap sm:flex-nowrap">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Generate Sensor history records
          </h3>
        </div>

        <div className="py-1 gap-4 bg-white sm:rounded-xl">
          <div className="grid grid-cols-1 gap-2">
            <div>
              <SelectMenu
                handleInputChange={(e) => {
                  setReportFilters({
                    ...reportFilters,
                    type: e.target.value,
                  });
                }}
                name="type"
                options={receivingReportOptions}
                value={reportFilters.type}
                title="Report type"
                showLabel={true}
              />
            </div>
            <div className="grid grid-cols-2 gap-3 mt-1">
              <DatePickerInput
                label="From"
                name="startDate"
                onChange={(e) => {
                  const { name, value } = e.target;
                  setReportFilters({ ...reportFilters, [name]: value });
                }}
                value={reportFilters.startDate}
                labelOn={true}
                type="text"
                dateFormat={"LLL dd, YYY"}
              />
              <DatePickerInput
                label="To"
                name="endDate"
                onChange={(e) => {
                  const { name, value } = e.target;
                  setReportFilters({ ...reportFilters, [name]: value });
                }}
                minDate={reportFilters.startDate}
                value={reportFilters.endDate}
                labelOn={true}
                type="text"
                dateFormat={"LLL dd, YYY"}
              />
            </div>
          </div>

          <div className="sm:flex-no-wrap my-auto flex items-center">
            <button
              onClick={() => {
                confirmAction(reportFilters);
                setActionInProgress(true);
              }}
              className="inline-flex justify-center mt-3 py-35-custom px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-main-purple hover:bg-indigo-700 items-center"
              disabled={actionInProgress}
            >
              <ButtonSpinner spin={actionInProgress} text="Generate" />
            </button>
            <button
              onClick={() =>
                setOpen({
                  open: false,
                })
              }
              className="ml-2 inline-flex justify-center mt-3 py-35-custom px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 items-center"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
