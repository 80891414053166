import { XMarkIcon } from "@heroicons/react/20/solid";
import { useEffect } from "react";
import { classNames } from "../shared/utility";

export default function SimpleBanner(props) {
  const { setShow, showSimpleNotification } = props;

  useEffect(() => {
    if (showSimpleNotification.show) {
      setTimeout(function () {
        setShow({ show: false });
      }, 4000);
    }
  }, [setShow, showSimpleNotification]);

  let bgColor = "bg-red-600";
  if (showSimpleNotification.type === BannerNotificationType.Success) {
    bgColor = "bg-green-600";
  }

  return (
    <div
      className={classNames(
        showSimpleNotification.show ? bgColor : "hidden",
        "flex items-center gap-x-6 mx-4 sm:mx-6 lg:mx-8 py-2.5 sm:px-3.5 sm:before:flex-1 border-1-custom"
      )}
    >
      <p className="text-sm leading-6 text-white">
        {showSimpleNotification.title}
      </p>
      <div className="flex flex-1 justify-end">
        <button
          type="button"
          className="-m-3 p-3 focus-visible:outline-offset-[-4px]"
        >
          <span className="sr-only">Dismiss</span>
          <XMarkIcon className="h-5 w-5 text-white" aria-hidden="true" />
        </button>
      </div>
    </div>
  );
}

export class BannerNotificationType {
  static Success = "success";
  static Error = "error";
}
