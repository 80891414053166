import CreatableSelect from "react-select/creatable";
import { convertToDefEventPara } from "../../shared/utility";

export default function CreatableSelectMenu(props) {
  const {
    error,
    handleInputChange,
    menuPlacement,
    name,
    options,
    defaultValue,
    isMulti,
    title,
    value,
    isClearable,
  } = props;

  const customStyles = {
    control: (base) => ({
      ...base,
      height: 34,
      minHeight: 34,
    }),
  };

  return (
    <div>
      <div className="flex space-x-2">
        <label
          htmlFor="type"
          className="block text-sm font-medium text-gray-900"
        >
          {title}
        </label>
      </div>
      <div className="mt-1">
        <CreatableSelect
          isMulti={isMulti}
          name={name}
          options={options}
          placeholder=""
          defaultValue={defaultValue}
          menuPlacement={menuPlacement}
          noOptionsMessage={() => `Choose a ${title}`}
          onChange={(newVal) => {
            handleInputChange(convertToDefEventPara(name, newVal));
          }}
          isClearable={isClearable}
          styles={customStyles}
        />
        {error ? <p className="font-medium text-red-500">{error}</p> : null}
      </div>
    </div>
  );
}
