import React, { useState } from "react";

import Input from "../../../../components/controls/Input";

export default function InvoiceRecordInventory(props) {
  const { invoiceDetails, onGenerateBarcodePdf } = props;

  const [createBarcodeInProgress, setCreateBarcodeInProgress] = useState(false);

  const generateBarcodeStringClicked = async function () {
    setCreateBarcodeInProgress(true);
    const callResult = await onGenerateBarcodePdf({
      receiving_item_id: invoiceDetails.receiving_item_id,
    });
    setCreateBarcodeInProgress(false);

    if (callResult.success) {
      setTimeout(() => {
        window.print();
      }, 1500);
    }
  };

  return (
    <div>
      <div className="mt-5 px-4 py-5 sm:p-2 grid grid-cols-1 overflow-hidden rounded-lg bg-white shadow md:grid-cols-3">
        <div className="col-span-3">
          <h3 className="text-base font-semibold leading-6 text-gray-900">
            Inventory details
          </h3>
        </div>

        <div className="mt-2 col-span-1">
          <Input
            label="Used Unit"
            name="used_weight"
            value={invoiceDetails.used_weight}
            labelOn={true}
            type="text"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-0 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
          />
        </div>
        <div className="mt-2 ml-2 col-span-1">
          <Input
            label="In Stock"
            name="in_stock"
            value={invoiceDetails.in_stock}
            labelOn={true}
            type="text"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-0 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
          />
        </div>
      </div>
      <div>
        <button
          type="button"
          className="mt-5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400"
          onClick={() => generateBarcodeStringClicked()}
        >
          {createBarcodeInProgress ? (
            <svg
              className="animate-spin -ml-1 mr-3 h-5 w-5 text-black"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          ) : (
            "Print barcode"
          )}
        </button>
      </div>
    </div>
  );
}
