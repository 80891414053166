import React from "react";
import { classNames } from "../../shared/utility";

export default function Input(props) {
  const {
    parentClass,
    className,
    label,
    name,
    onChange,
    type,
    value,
    labelOn,
    error,
    explanation,
    ref,
    labelClass,
    ...other
  } = props;

  return (
    <div className={parentClass ? parentClass : null}>
      {labelOn ? (
        <div className="flex space-x-2 mb-1">
          <label
            htmlFor={name}
            className={classNames(
              labelClass ? labelClass : "text-sm",
              "block font-medium text-gray-900"
            )}
          >
            {label}
          </label>
        </div>
      ) : null}
      <div>
        <input
          type={type}
          name={name}
          id={name}
          className={
            className
              ? className
              : "mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none sm:text-sm sm:max-w-xs md:max-w-full"
          }
          value={value}
          onChange={onChange}
          ref={ref}
          {...other}
        />
        {error ? <p className="font-medium text-red-500">{error}</p> : null}
      </div>
    </div>
  );
}
