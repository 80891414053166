import React, { useRef } from "react";

import {
  MagnifyingGlassIcon,
  PlusCircleIcon,
} from "@heroicons/react/24/outline";

export default function RecipeBomItemsTable(props) {
  const {
    bomItems,
    bomItemsLoading,
    getBomItems,
    onAddBomToIngredient,
    recipe,
  } = props;

  const searchRef = useRef();

  const handleSearch = () => {
    getBomItems(searchRef.current.value, 0);
  };

  const handlePagination = (newPage) => {
    getBomItems(searchRef.current.value, newPage);
  };
  return (
    <div className="flex min-h-0 flex-1 flex-col overflow-y-scroll px-1">
      <div>
        <div className="flex items-start justify-between">
          <h3 className="text-base font-semibold leading-6 text-gray-900">
            Choose a bom item
          </h3>
          <div className="ml-3 flex h-7 items-center"></div>
        </div>
        <div className="mt-2 flex rounded-md">
          <div className="relative flex items-stretch flex-grow focus-within:z-10">
            <input
              type="search"
              name="searchInput"
              id="searchInput"
              className="block w-full rounded-lg rounded-l-md pl-2 sm:text-sm border border-gray-300 rounded-tr-none rounded-br-none"
              placeholder="Search by name or plu"
              ref={searchRef}
              disabled={recipe.id < 1}
              onKeyDown={(e) => (e.key === "Enter" ? handleSearch() : null)}
            />
          </div>
          <button
            type="button"
            className="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1"
            onClick={() => handleSearch()}
            disabled={recipe.id < 1}
          >
            <MagnifyingGlassIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </button>
        </div>
      </div>
      <div className="relative mt-6 flex-1 mb-2">
        <div id="product-table" className="overflow-hidden card-custom">
          <table className="min-w-full divide-y divide-gray-300">
            <thead className="bg-white">
              <tr>
                <th
                  scope="col"
                  className="py-1 pl-3 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                >
                  Bom Plu
                </th>
                <th
                  scope="col"
                  className="px-3 py-1 text-left text-sm font-semibold text-gray-900"
                >
                  Name
                </th>
                <th
                  scope="col"
                  className="px-3 py-1 text-left text-sm font-semibold text-gray-900"
                >
                  Type
                </th>
                <th
                  scope="col"
                  className="px-3 py-1 text-left text-sm font-semibold text-gray-900"
                ></th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {bomItemsLoading ? (
                <td
                  className="whitespace-wrap py-1 pl-4 pr-3 text-sm font-medium text-gray-500 sm:pl-6 text-center"
                  colSpan={4}
                >
                  Loading ...
                </td>
              ) : bomItems.data.length === 0 ? (
                <tr>
                  <td
                    className="whitespace-wrap py-1 pl-4 pr-3 text-sm font-medium text-gray-500 sm:pl-6 text-center"
                    colSpan={4}
                  >
                    {searchRef.current
                      ? "We could not find that BOM item"
                      : " Please search for a BOM item"}
                  </td>
                </tr>
              ) : (
                bomItems.data.map((singleBomItem) => (
                  <tr key={singleBomItem.id}>
                    <td className="whitespace-wrap py-1 pl-4 pr-3 text-sm font-medium text-gray-500 sm:pl-6">
                      {singleBomItem.plu}
                    </td>
                    <td className="whitespace-wrap px-3 py-1 text-sm text-gray-500">
                      {singleBomItem.name}
                    </td>
                    <td className="whitespace-wrap px-3 py-1 text-sm text-gray-500">
                      {singleBomItem.product_type.label}
                    </td>
                    <td className="whitespace-wrap px-3 py-1 text-sm text-gray-500">
                      <button
                        type="button"
                        className="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-indigo-700 bg-indigo-50 hover:bg-indigo-100 focus:outline-none focus:ring-1"
                        onClick={() => {
                          onAddBomToIngredient(singleBomItem);
                        }}
                      >
                        <PlusCircleIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </button>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
