import React, { useState } from "react";

import { useForm } from "../useForm";

import Input from "../../controls/Input";

import ManufactureNewDetails from "./components/ManufactureNewDetails";
import ManufactureNewDetails2 from "./components/ManufactureNewDetails2";
import ManufactureNewDetails3 from "./components/ManufactureNewDetails3";
import ProductsTableComponent from "./components/ProductsTableComponent";
import { format, parseISO } from "date-fns";
import {
  classNames,
  convertToTwoDecimals,
  formatDate,
  ingestBarcodeScan,
  updateObjectInArray,
} from "../../../shared/utility";
import AddAssemblyItemToProductForm from "./components/AddAssemblyItemToProductForm";

import ModelErrorComponent from "../../modals/ModelErrorComponent";
import PrintBarcodeOrBatchModal from "./components/PrintBarcodeOrBatchModal";
import ManufactureNewReceivingItemsTableComponent from "./ManufactureNewReceivingItemsTableComponent";
import ReceivingItemInformation from "./ReceivingItemInformation";
import { useEffect } from "react";
import ManualEntryForManufacturingPopup from "./components/ManualEntryForManufacturingPopup";
import ManufacturingRecipeSelect from "./components/ManufacturingRecipeSelect";
import ManufactureIngredientsTable from "./components/ManufactureIngredientsTable";
import ManufactureRecipeItemsTable from "./components/ManufactureRecipeItemsTable";
import DeleteConfirmationForm from "../DeleteConfirmationForm";
import DatePickerInput from "../../controls/DatePickerInput";
import { is } from "date-fns/locale";

const initialFValues = {
  production_date: new Date(),
  manufactured_product_id: 0,
  created_date: "",
  supervisor: "",
  employee_1: "",
  employee_2: "",
  production_room: "",
  production_room_temperature: "",
  raw_product_temperature: "",
  product_id: "",
  plu: "",
  product_name: "",
  ingredient_amount_used: convertToTwoDecimals(0),
  ingredient_amount_used_old: convertToTwoDecimals(0),
  description_en: "",
  description_fr: "",
  new_weight: convertToTwoDecimals(0),
  new_lot_number: "",
  new_product_temperature: "",
  allergens: "",
  corrective_action: "",
  scanned_assembly_barcode: "",
  quick_scan: "",
  ingredients: [],
};

export default function ManufactureNewProductForm(props) {
  const {
    loading,
    addIngredientToManufacturedProduct,
    deleteIngredientFromManufacturedProduct,
    makeApiCallToStartManufacturing,
    employeeOptions,
    isEdit,
    certifiedByOptions,
    claimsOptions,
    getAssemblyItemReceivingByBarcode,
    getManufacturedProductIngredients,
    getProductsList,
    updateManufacturedProduct,
    manufacturedProduct,
    setEmptyModelOpen,
    setSlideOver,
    getReceivingAssemblyItemInStockByName,
    addManualEntryForManufacturingProduct,
    getManufacturedProductLabel,
    recipes,
    fetchRecipeItems,
    selectedRecipe,
    setSelectedRecipe,
    showErrorModal,
    printProductionCostReport,
    getProductionBatchReport,
    getLabelCheckReport,
    getVacuumPackReport,
    getCookingAndCoolingReport,
    getMetalDetectorReport,
    openNitrateAndPhosphateCalculator,
    supervisorOptions,
  } = props;

  const [barcodeScanInProgress, setBarcodeScanInProgress] = useState(false);
  const [manufactureInProgress, setManufactureInProgress] = useState(false);
  const [productAssemblyItems, setProductAssemblyItems] = useState([]);

  const validate = (isStart, fieldValues = values) => {
    let temp = { ...errors };

    if (!isStart) {
      if ("supervisor" in fieldValues) {
        temp.supervisor = null;
        if (fieldValues.supervisor === "") {
          temp.supervisor = "This field is required.";
        }
      }

      if ("employee_1" in fieldValues) {
        temp.employee_1 = null;
        if (fieldValues.employee_1 === "") {
          temp.employee_1 = "This field is required.";
        }
      }

      if ("production_room" in fieldValues) {
        temp.production_room = null;
        if (fieldValues.production_room === "") {
          temp.production_room = "This field is required.";
        }
      }
    }
    // temp.production_room_temperature = null;
    // if (values.production_room_temperature === "") {
    //   temp.production_room_temperature = "Please fill out field.";
    // }

    // temp.raw_product_temperature = null;
    // if (values.raw_product_temperature === "") {
    //   temp.raw_product_temperature = "Please fill out field.";
    // }

    // temp.plu = null;
    // if (values.plu === "") {
    //   temp.plu = "Please fill out field.";
    // }

    // temp.product_name = null;
    // if (values.product_name === "") {
    //   temp.product_name = "Please fill out field.";
    // }

    // temp.description_en = null;
    // if (values.description_en === "") {
    //   temp.description_en = "Please fill out field.";
    // }

    // temp.new_lot_number = null;
    // if (values.new_lot_number === "") {
    //   temp.new_lot_number = "Please fill out field.";
    // }

    // temp.new_product_temperature = null;
    // if (values.new_product_temperature === "") {
    //   temp.new_product_temperature = "Please fill out field.";
    // }

    setErrors({
      ...temp,
    });

    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === null);
    }
  };

  const { values, setValues, errors, setErrors, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );

  useEffect(() => {
    if (manufacturedProduct) {
      setValues({ ...values, ...manufacturedProduct });
      setProductAssemblyItems(
        manufacturedProduct.ingredients.map((assemblyItem) => {
          return {
            ...assemblyItem,
            is_used: true,
            in_stock: parseFloat(assemblyItem.in_stock),
          };
        })
      );
    }
  }, [manufacturedProduct]);

  useEffect(() => {
    if (selectedRecipe.recipe && values.id > 0) {
      let required_total_raw_material = (
        parseFloat(values.new_weight) *
        parseFloat(selectedRecipe.recipe.target_production_carpan)
      ).toFixed(2);

      if (isNaN(required_total_raw_material)) {
        required_total_raw_material = 0.0;
      }
      setValues({
        ...values,
        required_total_raw_material: required_total_raw_material,
      });
    }
  }, [selectedRecipe]);

  const openSearchProductsList = async function () {
    let callResult = await getProductsList("");
    if (callResult.success) {
      setSlideOver({
        childComponent: (
          <ProductsTableComponent
            getProductsList={getProductsList}
            productsList={callResult.data.data}
            setItemValues={(product) => {
              const description_en =
                product.description_en.length > 0 ? product.description_en : "";
              setValues({
                ...values,
                product_id: product.id,
                plu: product.plu,
                product_name: product.name_en,
                description_en: description_en,
                allergens_en: product.allergens_en,
                packing_size: product.weight,
              });
            }}
            setOpen={setSlideOver}
          />
        ),
        open: true,
      });
    } else {
    }
  };

  const openAssemblyItemPopUpToAddToProduct = (fetchedItem, isEdit) => {
    var suggestedAmount = 0;

    if (isRecipeUsed()) {
      var recipeItemIndex = findIndexForBomItem(
        selectedRecipe.recipeItems,
        fetchedItem,
        isEdit
      );

      if (recipeItemIndex >= 0) {
        const recipe_per_kg = parseFloat(
          selectedRecipe.recipeItems[recipeItemIndex].recipe_per_kg
        );

        suggestedAmount = parseFloat(
          (recipe_per_kg * values.new_weight).toFixed(2)
        );
      }
    }

    setEmptyModelOpen({
      childComponent: (
        <AddAssemblyItemToProductForm
          assemblyItem={fetchedItem}
          confirmAction={addAssemblyItemToProduct}
          isEdit={isEdit}
          setOpen={setEmptyModelOpen}
          suggestedAmount={suggestedAmount}
        />
      ),
      open: true,
    });
  };

  const handleBarcodeScan = async function (scannedBarcode) {
    if (scannedBarcode || scannedBarcode !== "") {
      setBarcodeScanInProgress(true);

      const barcodeInfo = ingestBarcodeScan(scannedBarcode);

      const barcodePlu = barcodeInfo.plu;

      if (barcodePlu || barcodePlu !== "") {
        const fetchedItem = await getAssemblyItemReceivingByBarcode(
          scannedBarcode,
          0
        );

        if (fetchedItem) {
          if (values.quick_scan) {
            const newUsedWeight =
              parseFloat(fetchedItem.receiving_item_total_used_unit) +
              parseFloat(fetchedItem.in_stock);
            const newInStock = 0;
            const valuesCopy = {
              ...fetchedItem,
              receiving_item_total_used_unit:
                Math.round(newUsedWeight * 100) / 100,
              in_stock: Math.round(newInStock * 100) / 100,
              ingredient_amount_used: fetchedItem.in_stock,
              is_used: true,
            };
            addAssemblyItemToProduct(valuesCopy);
          } else {
            openAssemblyItemPopUpToAddToProduct(fetchedItem, false);
          }
        } else {
          setEmptyModelOpen({
            childComponent: (
              <ModelErrorComponent
                setOpen={setEmptyModelOpen}
                subtitle="We could not find that product!"
                title="Error"
              />
            ),
            open: true,
          });
        }
        setValues({ ...values, scanned_assembly_barcode: "" });
      }

      setBarcodeScanInProgress(false);
    }
  };

  const onUpdateManufacturedProduct = async function () {
    setManufactureInProgress(true);

    if (validate()) {
      const callResult = await updateManufacturedProduct(values);
      if (callResult.success && !isRecipeUsed()) {
        setValues({
          ...values,
          barcode_details: callResult.data.barcode_details,
          production_report: callResult.data.production_report,
        });
      }
    }
    //  else {
    //   var subtitle = "Please complete all fields.";
    //   if (!values.ingredients.length > 0) {
    //     subtitle = "Please choose an ingredient.";
    //   }
    //   setEmptyModelOpen({
    //     childComponent: (
    //       <ModelErrorComponent
    //         setOpen={setEmptyModelOpen}
    //         subtitle={subtitle}
    //         title="Error"
    //       />
    //     ),
    //     open: true,
    //   });
    // }
    setManufactureInProgress(false);
  };

  const startManufacturingProcess = async function () {
    const callResult = await makeApiCallToStartManufacturing(values);

    if (callResult.success) {
      setValues({
        ...values,
        manufactured_product_id: callResult.data.id,
        production_date: callResult.data.production_date,
      });
    }
  };

  const askDeleteProductionIngredient = function (ingredient) {
    setEmptyModelOpen({
      childComponent: (
        <DeleteConfirmationForm
          buttonMessage="Delete"
          confirmAction={() =>
            ondDeleteIngredientFromManufacturedProduct(ingredient)
          }
          message={`Are you sure you want to delete ${ingredient.product_name}?`}
          setOpen={setEmptyModelOpen}
        />
      ),
      open: true,
    });
  };

  const ondDeleteIngredientFromManufacturedProduct = async function (
    ingredient
  ) {
    const isRecipeFlow = isRecipeUsed();
    if (isRecipeFlow) {
      var currentRecipesItems = [...selectedRecipe.recipeItems];
      var recipeItemIndex = findIndexForBomItem(
        currentRecipesItems,
        ingredient,
        isEdit
      );

      if (recipeItemIndex >= 0) {
        let recipeAtIndex = currentRecipesItems[recipeItemIndex];

        var itemReceivingItems = [];

        if (currentRecipesItems[recipeItemIndex]) {
          if (currentRecipesItems[recipeItemIndex].receivingItems) {
            itemReceivingItems =
              currentRecipesItems[recipeItemIndex].receivingItems;

            const receivingItemIndex = itemReceivingItems.findIndex(
              (element) => parseInt(element.id) == parseInt(ingredient.id)
            );

            if (receivingItemIndex >= 0) {
              let newReceivingItems = itemReceivingItems.filter(
                (element) => parseInt(element.id) !== parseInt(ingredient.id)
              );

              const currentItem = {
                ...recipeAtIndex,
                receivingItems: newReceivingItems,
                amount_in_use: (
                  parseFloat(recipeAtIndex.amount_in_use) -
                  parseFloat(ingredient.ingredient_amount_used)
                ).toFixed(2),
                total_cost: convertToTwoDecimals(
                  parseFloat(recipeAtIndex.total_cost) -
                    parseFloat(ingredient.ingredient_amount_used) *
                      parseFloat(ingredient.unit_cost)
                ),
              };

              const ingredientBeingEdited = {
                ...currentRecipesItems[recipeItemIndex],
                ...currentItem,
              };
              var payload = {
                index: recipeItemIndex,
                item: ingredientBeingEdited,
              };
              const newItems = updateObjectInArray(
                currentRecipesItems,
                payload
              );

              setSelectedRecipe({ ...selectedRecipe, recipeItems: newItems });

              if (ingredient.manufactured_product_recipe_id > 0) {
                const callResult =
                  await deleteIngredientFromManufacturedProduct(ingredient.id);
                if (callResult.success) {
                } else {
                }
              }
              setEmptyModelOpen({
                open: false,
                childComponent: null,
              });
            }
          }
        }
      }
    } else {
      const callResult = await deleteIngredientFromManufacturedProduct(
        ingredient.id
      );

      if (callResult.success) {
        setValues({
          ...values,
          new_weight: convertToTwoDecimals(
            parseFloat(values.new_weight) - ingredient.ingredient_amount_used
          ),
        });
        const filtereditems = productAssemblyItems.filter(
          (item) => item.id !== ingredient.id
        );
        setProductAssemblyItems(filtereditems);
      }
    }
  };

  function isRecipeUsed() {
    return selectedRecipe.recipe != null;
  }

  function findIndexForBomItem(searchArray, item, isEdit) {
    return searchArray.findIndex(
      (element) =>
        parseInt(isEdit ? element.id : element.bom_id) ===
        parseInt(isEdit ? item.manufactured_product_recipe_id : item.bom_id)
    );
  }

  function addNewIngredientToTargetTotalWeight(newValues) {
    const ingredient_amount_used_old = newValues.ingredient_amount_used_old
      ? newValues.ingredient_amount_used_old
      : 0;
    setValues({
      ...values,
      new_weight: convertToTwoDecimals(
        parseFloat(values.new_weight) -
          ingredient_amount_used_old +
          parseFloat(newValues.ingredient_amount_used)
      ),
      scanned_assembly_barcode: "",
    });
  }

  const addAssemblyItemToProduct = async function (newValues, isEdit) {
    setEmptyModelOpen({
      childComponent: null,
      open: false,
    });

    const isRecipeFlow = isRecipeUsed();
    if (isRecipeFlow) {
      var currentRecipesItems = [...selectedRecipe.recipeItems];
      var recipeItemIndex = findIndexForBomItem(
        currentRecipesItems,
        newValues,
        isEdit
      );

      if (recipeItemIndex === -1) {
        showErrorModal(
          "Item not in recipe",
          "Please double check item scanned"
        );
        return;
      }

      if (recipeItemIndex >= 0) {
        var itemReceivingItems = [];
        if (currentRecipesItems[recipeItemIndex]) {
          if (currentRecipesItems[recipeItemIndex].receivingItems) {
            itemReceivingItems =
              currentRecipesItems[recipeItemIndex].receivingItems;

            const receivingItemAlreadyInRecipeItems =
              itemReceivingItems.findIndex(
                (element) =>
                  parseInt(element.receiving_assembly_item_id) ==
                  parseInt(newValues.receiving_assembly_item_id)
              );

            if (receivingItemAlreadyInRecipeItems >= 0 && !isEdit) {
              showErrorModal(
                "Item already in recipe",
                "Please click view and update it"
              );
              return;
            }
          }
        }

        let recipeAtIndex = currentRecipesItems[recipeItemIndex];
        const currentIngAmountUsed = recipeAtIndex.amount_in_use
          ? recipeAtIndex.amount_in_use
          : 0;
        var total_cost = recipeAtIndex.total_cost
          ? recipeAtIndex.total_cost
          : 0;

        let ingredient_amount_used_old = 0;
        let newReceivingItems = [];
        if (isEdit) {
          const receivingItemIndex = itemReceivingItems.findIndex(
            (element) => parseInt(element.id) == parseInt(newValues.id)
          );

          ingredient_amount_used_old = newValues.ingredient_amount_used_old;

          const itemBeingEdited = {
            ...newValues,
          };
          var payload = {
            index: receivingItemIndex,
            item: itemBeingEdited,
          };
          newReceivingItems = updateObjectInArray(itemReceivingItems, payload);
        } else {
          const itemToAdd = { ...newValues, id: -1 };
          newReceivingItems = [...itemReceivingItems, itemToAdd];
        }

        const costOld =
          parseFloat(newValues.unit_cost) *
          parseFloat(ingredient_amount_used_old);
        const costNew =
          parseFloat(newValues.unit_cost) *
          parseFloat(newValues.ingredient_amount_used);

        const currentItem = {
          ...recipeAtIndex,
          receivingItems: newReceivingItems,
          amount_in_use: (
            parseFloat(currentIngAmountUsed) -
            parseFloat(ingredient_amount_used_old) +
            parseFloat(newValues.ingredient_amount_used)
          ).toFixed(2),
          total_cost: convertToTwoDecimals(
            parseFloat(total_cost) - costOld + costNew
          ),
        };

        const ingredientBeingEdited = {
          ...currentRecipesItems[recipeItemIndex],
          ...currentItem,
        };
        var payload = {
          index: recipeItemIndex,
          item: ingredientBeingEdited,
        };
        const newItems = updateObjectInArray(currentRecipesItems, payload);

        setSelectedRecipe({ ...selectedRecipe, recipeItems: newItems });
      }
    } else {
      const callResult = await addIngredientToManufacturedProduct(
        {
          ...newValues,
          manufactured_product_id: values.manufactured_product_id,
          recipeId: values.recipeId,
        },
        isEdit
      );

      if (callResult.success) {
        addNewIngredientToTargetTotalWeight(newValues);

        const callResult = await getManufacturedProductIngredients(
          values.manufactured_product_id
        );

        if (callResult.success) {
          setProductAssemblyItems(callResult.data);
        }
      }
    }
  };

  const onManualEntryForManufacturingAdded = async function (newValues) {
    setEmptyModelOpen({
      childComponent: null,
      open: false,
    });
    const callResult = await addManualEntryForManufacturingProduct({
      ...newValues,
      manufactured_product_id: values.manufactured_product_id,
    });

    if (callResult.success) {
      setValues({
        ...values,
        new_weight: convertToTwoDecimals(
          parseFloat(values.new_weight) + parseFloat(newValues.used_weight)
        ),
      });
      const callResult = await getManufacturedProductIngredients(
        values.manufactured_product_id
      );
      if (callResult.success) {
        setProductAssemblyItems(callResult.data);
      }
    }
  };

  const printClicked = async function () {
    if (validate()) {
      const callResult = await updateManufacturedProduct(values);
      if (callResult) {
        setEmptyModelOpen({
          childComponent: (
            <PrintBarcodeOrBatchModal
              setOpen={setEmptyModelOpen}
              getManufacturedProductLabel={getManufacturedProductLabel}
              manufacturedProductId={values.manufactured_product_id}
              printProductionCostReport={printProductionCostReport}
              getProductionBatchReport={getProductionBatchReport}
              getLabelCheckReport={getLabelCheckReport}
              getVacuumPackReport={getVacuumPackReport}
              getCookingAndCoolingReport={getCookingAndCoolingReport}
              getMetalDetectorReport={getMetalDetectorReport}
              openNitrateAndPhosphateCalculator={
                openNitrateAndPhosphateCalculator
              }
            />
          ),
          open: true,
        });
      } else {
        showErrorModal("Please try again");
      }
    }
  };

  const openSearchAssemblyItems = async function () {
    setSlideOver({
      childComponent: (
        <ManufactureNewReceivingItemsTableComponent
          getReceivingAssemblyItemInStockByName={
            getReceivingAssemblyItemInStockByName
          }
          openAssemblyItemPopUpToAddToProduct={
            openAssemblyItemPopUpToAddToProduct
          }
          setOpen={setSlideOver}
        />
      ),
      open: true,
    });
  };

  const openReceivingItemInformation = function (assemblyItem) {
    setSlideOver({
      childComponent: (
        <ReceivingItemInformation
          receivingItem={assemblyItem}
          setOpen={setSlideOver}
        />
      ),
      open: true,
    });
  };

  const openManualEntryPopup = () => {
    setEmptyModelOpen({
      childComponent: (
        <ManualEntryForManufacturingPopup
          confirmAction={onManualEntryForManufacturingAdded}
          setOpen={setEmptyModelOpen}
        />
      ),
      open: true,
    });
  };

  const onFetchRecipeItems = (recipeId) => {
    setValues({
      ...values,
      recipe_id: recipeId,
    });
    fetchRecipeItems(recipeId);
  };

  return (
    <div className="px-8 grid grid-cols-1 gap-4">
      <h1 className="text-xl font-semibold leading-6 text-gray-900">
        {isEdit ? "Editing " + values.product_name : "Manufacture new product"}
      </h1>
      <div className="sm:flex sm:items-center px-4 py-5 sm:px-4 card-custom">
        <div className="sm:flex-auto">
          <div className="flex gap-2">
            <DatePickerInput
              label="Production date"
              name="production_date"
              onChange={handleInputChange}
              value={formatDate(values.production_date)}
              labelOn={true}
              type="text"
              dateFormat={"LLL dd, YYY"}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
              error={errors.production_date}
            />
          </div>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex gap-4">
          <button
            type="button"
            className={classNames(
              values.manufactured_product_id > 0
                ? "hidden"
                : "block rounded-md bg-green-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
            )}
            onClick={() => startManufacturingProcess()}
          >
            Start production
          </button>
          <button
            type="button"
            className="block rounded-md bg-yellow-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-yellow-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-600"
            onClick={() => openSearchProductsList()}
          >
            Product list
          </button>
          <button
            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-500 hover:bg-gray-600 items-center"
            onClick={() => {
              openSearchAssemblyItems();
            }}
          >
            Search bom items
          </button>
          <button
            type="button"
            className={classNames(
              values.manufactured_product_id > 0 ? null : "hidden",
              "block rounded-md bg-main-purple px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            )}
            onClick={() => onUpdateManufacturedProduct()}
            disabled={!(values.manufactured_product_id > 0)}
          >
            {manufactureInProgress ? (
              <div className="flex">
                <svg
                  className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
                Creating product
              </div>
            ) : isEdit ? (
              "Save changes"
            ) : (
              "Finish production"
            )}
          </button>
          <button
            type="button"
            className={classNames(
              values.manufactured_product_id > 0 ? null : "hidden",
              "block rounded-md bg-gray-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
            )}
            onClick={() => {
              printClicked();
            }}
          >
            Print
          </button>
        </div>
      </div>
      {!loading ? (
        <div className="grid grid-cols-4 gap-4">
          <ManufactureNewDetails
            certifiedByOptions={certifiedByOptions}
            employeeOptions={employeeOptions}
            supervisorOptions={supervisorOptions}
            claimsOptions={claimsOptions}
            errors={errors}
            isEdit={isEdit}
            handleInputChange={handleInputChange}
            manufacturedProduct={manufacturedProduct}
            values={values}
          />
          <ManufactureNewDetails2
            isEdit={isEdit}
            errors={errors}
            handleInputChange={handleInputChange}
            manufacturedProduct={manufacturedProduct}
            values={values}
          />
          <ManufactureNewDetails3
            barcodeScanInProgress={barcodeScanInProgress}
            errors={errors}
            handleBarcodeScan={handleBarcodeScan}
            recipeItems={selectedRecipe}
            handleInputChange={handleInputChange}
            values={values}
            openManualEntryPopup={openManualEntryPopup}
            setValues={setValues}
            selectedRecipe={selectedRecipe}
          />
          <ManufacturingRecipeSelect
            isEdit={isEdit}
            manufacturedProduct={manufacturedProduct}
            onFetchRecipeItems={onFetchRecipeItems}
            handleInputChange={handleInputChange}
            recipes={recipes}
          />
        </div>
      ) : null}
      {selectedRecipe.recipe ? (
        <ManufactureRecipeItemsTable
          selectedRecipe={selectedRecipe}
          values={values}
          openAssemblyItemPopUpToAddToProduct={
            openAssemblyItemPopUpToAddToProduct
          }
          openReceivingItemInformation={openReceivingItemInformation}
          askDeleteProductionIngredient={askDeleteProductionIngredient}
        />
      ) : (
        <ManufactureIngredientsTable
          productAssemblyItems={productAssemblyItems}
          openAssemblyItemPopUpToAddToProduct={
            openAssemblyItemPopUpToAddToProduct
          }
          openReceivingItemInformation={openReceivingItemInformation}
          askDeleteProductionIngredient={askDeleteProductionIngredient}
        />
      )}
    </div>
  );
}
