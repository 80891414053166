import { useState } from "react";

import { makePostApiCall, makePutApiCall } from "../../../shared/makeApiCall";
import UnitOfMeasuresForm from "../../../components/forms/setup/unitOfMeasures/UnitOfMeasuresForm";
import { BannerNotificationType } from "../../../components/SimpleBanner";

export function UnitOfMeasuresViewModel(logout) {
  const [loading, setLoading] = useState(false);
  const [unitOfMeasures, setUnitOfMeasures] = useState({ data: [] });

  const [showSimpleNotification, setShowSimpleNotification] = useState({
    show: false,
    message: null,
    title: null,
    type: BannerNotificationType.Success,
  });
  const [slideOver, setSlideOver] = useState({
    childComponent: null,
    open: false,
  });

  const user_id = localStorage.getItem("id");

  const getUnitOfMeasures = async function (search, page) {
    setLoading(true);
    const payload = {
      page: page,
      search: search,
    };
    let callResult = await makePostApiCall(`unitOfMeasures`, payload);
    setLoading(false);
    if (callResult.success) {
      setUnitOfMeasures(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const createUnitOfMeasure = async function (values) {
    const payload = { ...values, user_id: user_id };
    let callResult = await makePostApiCall("createUnitOfMeasure", payload);

    if (callResult.success) {
      setShowSimpleNotification({
        show: true,
        title: "UnitOfMeasure added",
        type: BannerNotificationType.Success,
      });
      setSlideOver({
        childComponent: null,
        open: false,
      });
      setTimeout(function () {
        getUnitOfMeasures("", 1);
      }, 1000);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const updateUnitOfMeasure = async function (unitOfMeasure, values, index) {
    let callResult = await makePutApiCall(
      `unitOfMeasures/${unitOfMeasure.id}`,
      values
    );

    if (callResult.success) {
      setShowSimpleNotification({
        show: true,
        title: "UnitOfMeasure updated",
        type: BannerNotificationType.Success,
      });
      setSlideOver({
        childComponent: null,
        open: false,
      });
      setTimeout(function () {
        getUnitOfMeasures("", 1);
      }, 1000);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const openAddUnitOfMeasureSlideOver = () => {
    setSlideOver({
      childComponent: (
        <UnitOfMeasuresForm
          isEdit={false}
          setOpen={setSlideOver}
          actionCall={createUnitOfMeasure}
        />
      ),
      open: true,
    });
  };

  const viewUnitOfMeasureDetails = async function (unitOfMeasure, index) {
    setSlideOver({
      childComponent: (
        <UnitOfMeasuresForm
          unitOfMeasure={unitOfMeasure}
          index={index}
          isEdit={true}
          setOpen={setSlideOver}
          actionCall={updateUnitOfMeasure}
        />
      ),
      open: true,
    });
  };

  return {
    getUnitOfMeasures,
    loading,
    unitOfMeasures,
    openAddUnitOfMeasureSlideOver,
    setSlideOver,
    setShowSimpleNotification,
    slideOver,
    showSimpleNotification,
    viewUnitOfMeasureDetails,
  };
}
