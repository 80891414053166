import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { classNames, convertToDefEventPara } from "../../shared/utility";
import {
  ArrowLeftOnRectangleIcon,
  BackspaceIcon,
} from "@heroicons/react/24/outline";

export default function WeightScalePad(props) {
  const { values, showWeightScalePad, setShowWeightScalePad } = props;

  return (
    <div
      className={classNames(
        showWeightScalePad.show ? "gap-1 space-y-1" : "hidden"
      )}
    >
      {/* <div className="grid grid-cols-3 gap-1">
        <button
          type="button"
          className="rounded-md bg-red-600 px-3 py-1 text-xs font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 my-auto text-center"
          onClick={() =>
            showNumberPad.onChange(
              convertToDefEventPara(
                showNumberPad.name,
                values[showNumberPad.name].concat(1)
              )
            )
          }
        >
          1
        </button>
        <button
          type="button"
          className="rounded-md bg-red-600 px-3 py-1 text-xs font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 my-auto text-center"
          onClick={() =>
            showNumberPad.onChange(
              convertToDefEventPara(
                showNumberPad.name,
                values[showNumberPad.name].concat(2)
              )
            )
          }
        >
          2
        </button>
        <button
          type="button"
          className="rounded-md bg-red-600 px-3 py-1 text-xs font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 my-auto"
          onClick={() =>
            showNumberPad.onChange(
              convertToDefEventPara(
                showNumberPad.name,
                values[showNumberPad.name].concat(3)
              )
            )
          }
        >
          3
        </button>
      </div>
      <div className="grid grid-cols-3 gap-1">
        <button
          type="button"
          className="rounded-md bg-red-600 px-3 py-1 text-xs font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 my-auto text-center"
          onClick={() =>
            showNumberPad.onChange(
              convertToDefEventPara(
                showNumberPad.name,
                values[showNumberPad.name].concat(4)
              )
            )
          }
        >
          4
        </button>
        <button
          type="button"
          className="rounded-md bg-red-600 px-3 py-1 text-xs font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 my-auto text-center"
          onClick={() =>
            showNumberPad.onChange(
              convertToDefEventPara(
                showNumberPad.name,
                values[showNumberPad.name].concat(5)
              )
            )
          }
        >
          2
        </button>
        <button
          type="button"
          className="rounded-md bg-red-600 px-3 py-1 text-xs font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 my-auto"
          onClick={() =>
            showNumberPad.onChange(
              convertToDefEventPara(
                showNumberPad.name,
                values[showNumberPad.name].concat(6)
              )
            )
          }
        >
          6
        </button>
      </div>
      <div className="grid grid-cols-3 gap-1">
        <button
          type="button"
          className="rounded-md bg-red-600 px-3 py-1 text-xs font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 my-auto text-center"
          onClick={() =>
            showNumberPad.onChange(
              convertToDefEventPara(
                showNumberPad.name,
                values[showNumberPad.name].concat(7)
              )
            )
          }
        >
          7
        </button>
        <button
          type="button"
          className="rounded-md bg-red-600 px-3 py-1 text-xs font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 my-auto text-center"
          onClick={() =>
            showNumberPad.onChange(
              convertToDefEventPara(
                showNumberPad.name,
                values[showNumberPad.name].concat(8)
              )
            )
          }
        >
          8
        </button>
        <button
          type="button"
          className="rounded-md bg-red-600 px-3 py-1 text-xs font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 my-auto"
          onClick={() =>
            showNumberPad.onChange(
              convertToDefEventPara(
                showNumberPad.name,
                values[showNumberPad.name].concat(9)
              )
            )
          }
        >
          9
        </button>
      </div>
      <div className="grid grid-cols-3 gap-1">
        <button
          type="button"
          className="rounded-md bg-gray-600 px-3 py-1 text-xs font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600 my-auto"
          onClick={() =>
            showNumberPad.onChange(
              convertToDefEventPara(
                showNumberPad.name,
                values[showNumberPad.name].substring(
                  0,
                  values[showNumberPad.name].length - 1
                )
              )
            )
          }
        >
          <BackspaceIcon className="w-4 h-4 mx-auto" />
        </button>
        <button
          type="button"
          className="rounded-md bg-red-600 px-3 py-1 text-xs font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 my-auto text-center"
          onClick={() =>
            showNumberPad.onChange(
              convertToDefEventPara(
                showNumberPad.name,
                values[showNumberPad.name].concat(0)
              )
            )
          }
        >
          0
        </button>
        <button
          type="button"
          className="rounded-md bg-green-600 px-3 py-1 text-xs font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600 my-auto"
          onClick={() =>
            setShowNumberPad({
              show: false,
            })
          }
        >
          OK
        </button>
      </div> */}
    </div>
  );
}
