import { classNames, formatDate } from "../../../../shared/utility";

export default function PackagingSkidLabel(props) {
  const { skidLabel } = props;

  return (
    <div className="hidden label-section-to-print my-5 mx-5 ml-2">
      <div className="grid grid-cols-1 gap-2">
        <img
          src={"data:image/png;base64, " + skidLabel.barcodeHtml}
          alt="Supplier item barcode"
          width="50%"
        />
        <p className="text-barcode-slip">LOT #: {skidLabel.product_lot}</p>
        <p className="text-barcode-slip">
          PRODUCT NAME: {skidLabel.product_name}
        </p>
        <p className="text-barcode-slip">
          PRODUCT DESCRIPTION: {skidLabel.product_description}
        </p>
        <p className="text-barcode-slip">
          SKID CODE #: {skidLabel.skid_label_number}
        </p>
      </div>
    </div>
  );
}
