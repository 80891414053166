import { useState } from "react";

import { makePostApiCall, makePutApiCall } from "../../shared/makeApiCall";
import AllergensForm from "../../components/forms/setup/allergens/AllergensForm";
import { BannerNotificationType } from "../../components/SimpleBanner";
import DeleteConfirmationForm from "../../components/forms/DeleteConfirmationForm";

export function QaReportsViewModel(logout) {
  const [emptyModal, setEmptyModelOpen] = useState({
    open: false,
    childComponent: null,
  });
  const [loading, setLoading] = useState(false);
  const [reportTypes] = useState([
    {
      id: 1,
      name: "Receiving Report",
      children: [
        { id: 11, name: "Meat Receiving Report" },
        { id: 12, name: "Non-Meat Receiving Report" },
      ],
    },
    {
      id: 2,
      name: "Production Report",
      children: [],
    },
    {
      id: 3,
      name: "Packaging Report",
      children: [],
    },
    {
      id: 4,
      name: "Shipping Report",
      children: [],
    },
  ]);
  const [reportTypeToPrint, setReportTypeToPrint] = useState(-1);

  const [showSimpleNotification, setShowSimpleNotification] = useState({
    show: false,
    message: null,
    title: null,
    type: BannerNotificationType.Success,
  });

  const printQaReportById = async function (reportId) {
    setReportTypeToPrint(reportId);
    // setLoading(true);
    setTimeout(() => {
      window.print();
    }, 1500);

    // const payload = {
    //   page: page,
    //   search: search,
    // };
    // let callResult = await makePostApiCall(`allergens`, payload);
    // setLoading(false);
    // if (callResult.success) {
    //   //   setAllergens(callResult.data);
    // } else {
    //   if (callResult.errorStatus === 401) {
    //     logout();
    //   }
    // }
  };

  return {
    emptyModal,
    loading,
    reportTypes,
    setEmptyModelOpen,
    setShowSimpleNotification,
    showSimpleNotification,
    printQaReportById,
    reportTypeToPrint,
  };
}
