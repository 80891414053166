import React, { useRef, useState } from "react";

import Input from "../../../controls/Input";
import {
  classNames,
  convertToDefEventPara,
  formatDate,
  formatDateWithoutParsing,
} from "../../../../shared/utility";
import InputWithSearch from "../../../controls/InputWithSearch";

const { addDays } = require("date-fns");

export default function NewPackageDetails2(props) {
  const {
    errors,
    handleInputChange,
    values,
    showNumberPad,
    setShowNumberPad,
    createSkidLabelBarcode,
    fetchProductByPlu,
    setValues,
    printSkidLabelInProgress,
  } = props;

  const productPluRef = useRef();

  const [pluSearchInProgress, setPluSearchInProgress] = useState(false);

  const onFetchProductByPlu = async function (scannedPlu) {
    if (scannedPlu || scannedPlu !== "") {
      setPluSearchInProgress(true);

      const fetchResult = await fetchProductByPlu(scannedPlu);

      if (fetchResult.success) {
        const fetchedItem = fetchResult.data.product;

        var newShelfLife = addDays(
          new Date(values.production_date),
          fetchedItem.shelf_life
        );

        setValues({
          ...values,
          product_id: fetchedItem.id,
          product_name: fetchedItem.name_en,
          description_en: fetchedItem.description_en,
          packing_size: fetchedItem.weight,
          shelf_life: fetchedItem.shelf_life,
          best_before_date: formatDateWithoutParsing(newShelfLife),
        });
      } else {
        setValues({
          ...values,
          product_id: null,
          product_name: "",
          description_en: "",
          packing_size: "",
          shelf_life: "",
          best_before_date: "",
        });
      }
      setPluSearchInProgress(false);
    }
  };

  return (
    <div className="p-4 card-custom">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
        <div className="col-span-2">
          <InputWithSearch
            name="product_code"
            actionCall={() => onFetchProductByPlu(values.product_code)}
            onChange={handleInputChange}
            placeholder="Product Plu"
            ref={productPluRef}
            type="search"
            value={values.product_code}
            disableSearch={pluSearchInProgress}
            error={errors.product_code}
          />
        </div>
        <div className="col-span-2">
          <Input
            label="Product name"
            name="product_name"
            onChange={handleInputChange}
            value={values.product_name}
            labelOn={true}
            type="text"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
          />
        </div>
        <div className="col-span-2">
          <Input
            label="Product description"
            name="description_en"
            onChange={handleInputChange}
            value={values.description_en}
            labelOn={true}
            type="text"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
          />
        </div>
        <div className="col-span-1">
          <Input
            label="Production date"
            name="production_date"
            onChange={handleInputChange}
            value={formatDate(values.production_date)}
            labelOn={true}
            type="text"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            //error={errors.production_room}
          />
        </div>
        <div className="col-span-1">
          <Input
            label="Best before date"
            name="best_before_date"
            onChange={handleInputChange}
            value={formatDate(values.best_before_date)}
            labelOn={true}
            type="text"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            //error={errors.production_room}
          />
        </div>
        <div className="col-span-1">
          <Input
            label="Case weight"
            name="packing_size"
            onChange={handleInputChange}
            value={values.packing_size}
            labelOn={true}
            type="text"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            //error={errors.production_room}
          />
        </div>
        <div className="col-span-1">
          <Input
            label="Shelf life"
            name="shelf_life_date"
            onChange={handleInputChange}
            value={values.shelf_life}
            labelOn={true}
            type="text"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            //error={errors.production_room}
          />
        </div>
        <div className="col-span-1">
          <Input
            label="Serial number"
            name="serial_number"
            onChange={handleInputChange}
            value={values.serial_number}
            labelOn={true}
            disabled={true}
            type="text"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            //error={errors.production_room}
          />
        </div>
        <div className="col-span-1">
          <Input
            label="Label to print"
            name="number_of_label_to_print"
            onChange={handleInputChange}
            onClick={() => {
              setShowNumberPad({
                show: true,
                name: "number_of_label_to_print",
                onChange: handleInputChange,
                value: values.number_of_label_to_print,
                showNumberPad: showNumberPad,
                setShowNumberPad: setShowNumberPad,
                disabledDot: true,
              });
            }}
            max={values.is_free_weight ? 1 : 999999}
            value={values.number_of_label_to_print}
            labelOn={true}
            type="number"
            pattern="[0-9]*"
            inputmode="numeric"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            error={errors.number_of_label_to_print}
          />
        </div>
        <div className="col-span-2">
          <Input
            label="Lot number"
            name="product_lot"
            onChange={handleInputChange}
            value={values.product_lot}
            labelOn={true}
            type="text"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            //error={errors.product_lot}
          />
        </div>
        <div className="col-span-2 flex">
          <input
            id="passed_from_metal_detector"
            aria-describedby="selectAllBox"
            name="passed_from_metal_detector"
            type="checkbox"
            checked={values.passed_from_metal_detector}
            className="mt-1 mr-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
            onClick={(e) =>
              handleInputChange(
                convertToDefEventPara(
                  "passed_from_metal_detector",
                  e.target.checked
                )
              )
            }
          />
          Passed from metal detector
        </div>
        <div
          className={classNames(
            values.skid_label_required ? null : "hidden",
            "col-span-2"
          )}
        >
          <Input
            label="Skid label"
            name="skid_label_number"
            onChange={handleInputChange}
            value={values.skid_label_number}
            labelOn={true}
            type="text"
            className="mt-1 block border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm w-48"
            error={errors.skid_label_number}
          />
          <div className="flex w-full mt-2">
            <button
              type="button"
              className="rounded-md bg-main-purple px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 flex my-auto"
              onClick={() =>
                handleInputChange(
                  convertToDefEventPara(
                    "skid_label_number",
                    Math.floor(Math.random() * 90000) +
                      10000 +
                      "" +
                      Math.floor(Math.random() * 90000)
                  )
                )
              }
            >
              Generate code
            </button>
            <button
              type="button"
              className="ml-2 rounded-md bg-main-purple px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 flex my-auto"
              onClick={() =>
                createSkidLabelBarcode(
                  values.skid_label_number,
                  values.product_lot,
                  values.product_name,
                  values.product_description
                )
              }
              disabled={printSkidLabelInProgress}
            >
              Print skid label
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
