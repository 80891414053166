import { useState } from "react";

import { makePostApiCall } from "../../shared/makeApiCall";
import { BannerNotificationType } from "../../components/SimpleBanner";
import ModelErrorComponent from "../../components/modals/ModelErrorComponent";

export function ManufactureNewProductViewModel(logout) {
  const [certifiedByOptions, setCertifiedByOptions] = useState([]);
  const [claimsOptions, setClaimsOptions] = useState([]);
  const [employeeOptions, setEmployeeOptions] = useState([]);
  const [supervisorOptions, setSupervisorOptions] = useState([]);
  const [emptyModal, setEmptyModelOpen] = useState({
    open: false,
    childComponent: null,
  });
  const [showSimpleNotification, setShowSimpleNotification] = useState({
    show: false,
    message: null,
    title: null,
    type: BannerNotificationType.Success,
  });
  const [slideOver, setSlideOver] = useState({
    childComponent: null,
    open: false,
  });
  const [printLabelDetails, setPrintLabelDetails] = useState({
    production_date: null,
    product_plu: null,
    product_name: null,
    product_description: null,
    product_weight: null,
    product_lot: null,
    supervisor: null,
    product_allergens: null,
    barcodeHtml: null,
    barcode: null,
  });
  const [recipes, setRecipes] = useState([]);
  const [selectedRecipe, setSelectedRecipe] = useState([]);
  const [printType, setPrintType] = useState(0);
  const [manufacturedProduct, setManufacturedProduct] = useState(null);

  const getProductsList = async function (search) {
    const payload = {
      page: 1,
      search: search,
    };
    let callResult = await makePostApiCall(`products`, payload);

    if (callResult.success) {
      return callResult;
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const getAssemblyItemReceivingByBarcode = async function (search, page) {
    const payload = {
      manufacture: true,
      page: page,
      search: search,
    };
    let callResult = await makePostApiCall(`receivingInvoiceIndex`, payload);

    if (callResult.success) {
      return callResult.data;
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const getCertifiedByOptionsForDropdown = async function () {
    let callResult = await makePostApiCall(`certifiedByOptionsForDropdown`);

    if (callResult.success) {
      setCertifiedByOptions(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const makeApiCallToStartManufacturing = async function (productValues) {
    let callResult = await makePostApiCall(
      "startManufacturingProduct",
      productValues
    );
    if (callResult.success) {
      return callResult;
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const getClaimsForDropdown = async function () {
    let callResult = await makePostApiCall(`claimsForDropdown`);

    if (callResult.success) {
      setClaimsOptions(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const updateManufacturedProduct = async function (productValues) {
    let updateProductResult = await makePostApiCall(
      "updateManufacturedProduct",
      productValues
    );
    let recipeIngredientsResult = {};
    if (updateProductResult.success) {
      if (selectedRecipe.recipe) {
        recipeIngredientsResult = await makePostApiCall(
          "saveOrUpdateManufactureIngredients",
          {
            ...selectedRecipe,
            manufactured_product_id: productValues.manufactured_product_id,
          }
        );
        if (recipeIngredientsResult.success) {
          window.location =
            "/manufactured-products/" + productValues.manufactured_product_id;
        } else {
          if (recipeIngredientsResult.errorStatus === 401) {
            logout();
          }
        }
      }
    } else {
      if (updateProductResult.errorStatus === 401) {
        logout();
      }
    }
    return updateProductResult;
  };

  const addIngredientToManufacturedProduct = async function (
    ingredientValues,
    isEdit
  ) {
    let callResult = await makePostApiCall(
      isEdit
        ? "updateManufacturedProductIngredientById/" + ingredientValues.id
        : "addIngredientToManufacturedProduct",
      ingredientValues
    );
    if (callResult.success) {
      return callResult;
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const getManufacturedProductIngredients = async function (
    manufactedProductId
  ) {
    let callResult = await makePostApiCall(
      `getManufacturedProductIngredients/${manufactedProductId}`
    );
    if (callResult.success) {
      return callResult;
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const getReceivingAssemblyItemInStockByName = async function (search) {
    let callResult = await makePostApiCall(
      `getReceivingAssemblyItemInStockByName`,
      {
        search: search,
      }
    );

    if (callResult.success) {
      return callResult;
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const deleteIngredientFromManufacturedProduct = async function (
    ingredientId
  ) {
    let callResult = await makePostApiCall(
      `deleteIngredientFromManufacturedProduct/${ingredientId}`
    );
    if (callResult.success) {
      return callResult;
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
    return callResult;
  };

  const addManualEntryForManufacturingProduct = async function (productValues) {
    let callResult = await makePostApiCall(
      `addManualEntryToManufacturedProduct`,
      productValues
    );
    if (callResult.success) {
      return callResult;
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const getManufacturedProductLabel = async function (manufacturedProductId) {
    let callResult = await makePostApiCall(
      `getManufacturedProductLabel/${manufacturedProductId}`
    );
    if (callResult.success) {
      setPrintType(1);
      setPrintLabelDetails(callResult.data);

      setTimeout(() => {
        window.print();
      }, 1500);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const getEmployeesForDropdown = async function (type, department) {
    const payload = { department: department, type: [type] };
    let callResult = await makePostApiCall(`employeesForDropdown`, payload);

    if (callResult.success) {
      if (type === 1) {
        setEmployeeOptions(callResult.data);
      } else {
        setSupervisorOptions(callResult.data);
      }
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const fetchRecipesForDropdown = async function () {
    let callResult = await makePostApiCall(`recipesForDropdown`);

    if (callResult.success) {
      setRecipes(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const fetchRecipeItems = async function (id) {
    let callResult = await makePostApiCall(`fetchRecipeForUse/${id}`);

    if (callResult.success) {
      setSelectedRecipe({
        recipe: callResult.data.recipe,
        recipeItems: [
          ...callResult.data.recipeItems,
          ...callResult.data.packagingItems,
        ],
      });
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const showErrorModal = (title, subtitle) => {
    setEmptyModelOpen({
      childComponent: (
        <ModelErrorComponent
          setOpen={setEmptyModelOpen}
          subtitle={subtitle}
          title={title}
        />
      ),
      open: true,
    });
  };

  return {
    addIngredientToManufacturedProduct,
    certifiedByOptions,
    deleteIngredientFromManufacturedProduct,
    emptyModal,
    getAssemblyItemReceivingByBarcode,
    getCertifiedByOptionsForDropdown,
    getManufacturedProductIngredients,
    getProductsList,
    setEmptyModelOpen,
    showSimpleNotification,
    setShowSimpleNotification,
    setSlideOver,
    slideOver,
    updateManufacturedProduct,
    getReceivingAssemblyItemInStockByName,
    makeApiCallToStartManufacturing,
    addManualEntryForManufacturingProduct,
    getManufacturedProductLabel,
    printLabelDetails,
    employeeOptions,
    getEmployeesForDropdown,
    fetchRecipesForDropdown,
    recipes,
    fetchRecipeItems,
    selectedRecipe,
    setSelectedRecipe,
    showErrorModal,
    manufacturedProduct,
    claimsOptions,
    getClaimsForDropdown,
    supervisorOptions,
  };
}
