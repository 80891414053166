import { useState } from "react";
import { ReceivingInvoiceIndexViewModel } from "../../../viewmodels/receiving/invoices/ReceivingInvoiceIndexViewModel";

import { connect } from "react-redux";

import * as actions from "../../../store/actions/index";

import { withRouter } from "../../../shared/utility";

import SlideOver from "../../../components/slideOver/SlideOver";

import InvoiceRecordDetails from "./records/InvoiceRecordDetails";
import InvoiceRecordProductDetails from "./records/InvoiceRecordProductDetails";
import InvoiceRecordAssemblyOptions from "./records/InvoiceRecordAssemblyOptions";
import InvoiceRecordInventory from "./records/InvoiceRecordInventory";
import InvoiceRecordsSearch from "./records/InvoiceRecordsSearch";
import ReceivingBarcodeLabel from "../../../components/forms/receiving/invoices/ReceivingBarcodeLabel";
import SimpleBanner from "../../../components/SimpleBanner";

function ReceivingInvoiceIndex(props) {
  const { logout } = props;
  const {
    barcodeDetails,
    fetchInvoiceDetails,
    getReceivingInvoiceIndex,
    loading,
    receivingIndex,
    setSlideOver,
    slideOver,
    setShowSimpleNotification,
    showSimpleNotification,
    onGenerateBarcodePdf,
  } = ReceivingInvoiceIndexViewModel(logout);

  const [invoiceDetails, setInvoiceDetails] = useState({
    supplier_name: "",
    product_condition: {
      label: "",
    },
    truck_condition: { label: "" },
    pallet_condition: { label: "" },
  });

  const fetchInvoiceDetailsClicked = async function (itemId) {
    const fetchedInvoice = await fetchInvoiceDetails(itemId);
    setInvoiceDetails(fetchedInvoice);
  };

  return (
    <div>
      <SimpleBanner
        setShow={setShowSimpleNotification}
        showSimpleNotification={showSimpleNotification}
      />
      <div className="hide-for-print">
        <div className="px-4 sm:px-8">
          {/* Invoice details begins */}
          <div className="grid grid-cols-5 gap-4">
            <div className="col-span-3">
              <InvoiceRecordsSearch
                getReceivingInvoiceIndex={getReceivingInvoiceIndex}
              />
              <div className="mt-5 grid grid-cols-2 gap-2">
                <InvoiceRecordProductDetails invoiceDetails={invoiceDetails} />
                <div>
                  <InvoiceRecordDetails invoiceDetails={invoiceDetails} />
                  <InvoiceRecordInventory
                    barcodeDetails={barcodeDetails}
                    invoiceDetails={invoiceDetails}
                    onGenerateBarcodePdf={onGenerateBarcodePdf}
                  />
                </div>
              </div>
            </div>
            <InvoiceRecordAssemblyOptions
              fetchInvoiceDetailsClicked={fetchInvoiceDetailsClicked}
              loading={loading}
              receivingIndex={receivingIndex}
            />
          </div>
          {/* Invoice details ends */}
        </div>
      </div>
      <SlideOver
        childComponent={slideOver.childComponent}
        slideOver={slideOver}
        setSlideOver={setSlideOver}
      />
      <ReceivingBarcodeLabel barcodeDetails={barcodeDetails} />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(actions.logout()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ReceivingInvoiceIndex)
);
