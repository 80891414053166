import { classNames } from "../../shared/utility";

export default function Pills(props) {
  const { content } = props;

  const getColor = () => {
    var returnString = "";
    switch (content.type) {
      case PillType.Success:
        returnString = "bg-green-600 text-white";
        break;
      case PillType.Error:
        returnString = "bg-red-600 text-white";
        break;
      case PillType.Warning:
        returnString = "bg-yellow-400 text-white";
        break;
      case PillType.DarkYellow:
        returnString = "bg-yellow-600 text-white";
        break;
      case PillType.Gray:
        returnString = "bg-gray-600 text-white";
        break;
      case PillType.Orange:
        returnString = "bg-orange-500 text-white";
        break;
      case PillType.LightLime:
        returnString = "bg-lime-500 text-white";
        break;
      case PillType.Cyan:
        returnString = "bg-cyan-500 text-white";
        break;
      case PillType.DarkSky:
        returnString = "bg-sky-700 text-white";
        break;
      case PillType.Rose:
        returnString = "bg-rose-900 text-white";
        break;

      default:
        break;
    }
    return returnString;
  };

  return (
    // <div
    //   className={classNames(
    //     getColor(),
    //     "rounded-md px-1.5 py-1.5 text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 w-fit"
    //   )}
    // >
    //   {content.text}
    // </div>
    <span
      className={classNames(
        getColor(),
        "inline-flex items-center rounded-md px-2 py-1 text-sm font-medium"
      )}
    >
      {content.text}
    </span>
  );
}

export class PillType {
  static Success = "success";
  static Error = "error";
  static Warning = "warning";
  static Gray = "gray";
  static DarkYellow = "darkYellow";
  static Orange = "orange";
  static LightLime = "lightLime";
  static Cyan = "cyan";
  static DarkSky = "darkSky";
  static Rose = "rose";
}
