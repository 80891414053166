import { useEffect, useRef, useState } from "react";
import {
  PackagingBulkActionType,
  PackagingRecordsViewModel,
} from "../../viewmodels/packaging/PackagingRecordsViewModel";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";

import { connect } from "react-redux";

import * as actions from "../../store/actions/index";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

import {
  convertToTwoDecimals,
  formatDateWithTime,
  withRouter,
} from "../../shared/utility";

import SlideOver from "../../components/slideOver/SlideOver";
import EmptyModal from "../../components/modals/EmptyModal";

import { useNavigate } from "react-router-dom";
import { classNames } from "primereact/utils";
import PackagingSkidLabel from "../../components/forms/packaging/barcode-label/PackagingSkidLabel";
import PackagingProductLabel from "../../components/forms/packaging/barcode-label/PackagingProductLabel";
import ButtonSpinner from "../../components/controls/ButtonSpinner";
import SimpleBanner from "../../components/SimpleBanner";

import { Paginator } from "primereact/paginator";
import FilterDropdown from "../../components/filterDropdown/FilterDropdown";
import ReportsDropdown from "../../components/filterDropdown/ReportsDropdown";

function PackagingRecords(props) {
  const { logout } = props;
  const {
    askDeletePackaging,
    emptyModal,
    setEmptyModelOpen,
    getPackagings,
    loading,
    packagings,
    setSlideOver,
    slideOver,
    setShowSimpleNotification,
    showSimpleNotification,
    packagingReprintAll,
    printingLabelType,
    skidLabel,
    packagingLabel,
    bulkActionInProgress,
    reprintSinglePackagingLabel,
    askDeletePackagingLot,
    openPackagingDetailsSideView,
    fetchBrandsForDropdown,
    getEmployeesForDropdown,
    filters,
    showFilters,
    resetFilters,
    filterComponents,
    setShowFilters,
    generatePackagingReport,
    reportComponents,
    showReports,
    setShowReports,
    reportInProgress,
  } = PackagingRecordsViewModel(logout);

  const navigate = useNavigate();

  var currentPage = 1;
  useEffect(() => {
    fetchBrandsForDropdown();
    getEmployeesForDropdown(2, [1, 2]);
    getEmployeesForDropdown(1, [1, 2]);
    getPackagings(null, currentPage, filters);
  }, []);

  const [first, setFirst] = useState(1);
  const [rows, setRows] = useState(20);

  const searchRef = useRef();

  const handleSearch = () => {
    getPackagings(searchRef.current.value, currentPage, filters);
  };

  const handlePagination = (event) => {
    currentPage = event.page + 1;
    setFirst(event.first);
    setRows(event.rows);
    getPackagings(searchRef.current.value, currentPage, filters);
  };

  const [expandedRows, setExpandedRows] = useState(null);

  const allowExpansion = (rowData) => {
    return true;
  };

  const packagingDateBodyTemplate = (rowData) => {
    return formatDateWithTime(rowData.packaging_converted);
  };

  const packSizeBodyTemplate = (rowData) => {
    return formatDateWithTime(rowData.packing_size) + " kg";
  };

  const totalPackagedBody = (rowData) => {
    return rowData.subItems.length;
  };

  const saleStatusDateBodyTemplate = (rowData) => {
    let bg = "bg-green-600";
    let text = "In Stock";
    if (parseInt(rowData.sale_status) === 1) {
      bg = "bg-red-600";
      text = rowData.customer_name;
    } else if (parseInt(rowData.sale_status) === 2) {
      bg = "bg-gray-600";
      text = "Adjusted";
    }
    return (
      <button
        onClick={() => {}}
        className={classNames(
          bg,
          "rounded-md px-1.5 py-1.5 text-sm font-semibold text-white shadow-sm"
        )}
      >
        {text}
      </button>
    );
  };

  const onPackagingReprintAll = async function (itemId, packagingDate) {
    const reprintResult = await packagingReprintAll(itemId, packagingDate);
  };

  const massItemActionTemplate = (rowData) => {
    return (
      <div className="flex">
        <button
          onClick={() => openPackagingDetailsSideView(rowData.product_lot)}
          className="ml-2 rounded-md bg-yellow-500 px-1.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          disabled={bulkActionInProgress.type !== PackagingBulkActionType.NULL}
        >
          <ButtonSpinner
            spin={
              PackagingBulkActionType.Reprint &&
              bulkActionInProgress.lot === rowData.product_lot
            }
            text="Details"
          />
        </button>
        <button
          onClick={() =>
            onPackagingReprintAll(rowData.product_lot, rowData.packaging_date)
          }
          className="ml-2 rounded-md bg-main-purple px-1.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          disabled={bulkActionInProgress.type !== PackagingBulkActionType.NULL}
        >
          <ButtonSpinner
            spin={
              PackagingBulkActionType.Reprint &&
              bulkActionInProgress.lot === rowData.product_lot
            }
            text="Reprint All"
          />
        </button>
        <button
          onClick={() => {
            askDeletePackagingLot(rowData);
          }}
          className="ml-2 rounded-md bg-red-600 px-1.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
          disabled={bulkActionInProgress.type !== PackagingBulkActionType.NULL}
        >
          Delete All
        </button>
      </div>
    );
  };

  const singleItemActionTemplate = (rowData) => {
    return (
      <div className="flex">
        <button
          onClick={() => reprintSinglePackagingLabel(rowData.id)}
          className="ml-2 rounded-md bg-main-purple px-1.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          disabled={bulkActionInProgress.type !== PackagingBulkActionType.NULL}
        >
          <ButtonSpinner
            spin={
              PackagingBulkActionType.Reprint &&
              bulkActionInProgress.packagingId === rowData.id
            }
            text="Reprint"
          />
        </button>
        <button
          onClick={() => {
            askDeletePackaging(rowData);
          }}
          className="ml-2 rounded-md bg-red-600 px-1.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
          disabled={bulkActionInProgress.type !== PackagingBulkActionType.NULL}
        >
          Delete
        </button>
      </div>
    );
  };

  const rowExpansionTemplate = (data) => {
    return (
      <div className="p-3">
        {/* <h5>Orders for {data.name}</h5> */}
        <DataTable
          value={data.subItems}
          tableClassName="min-w-full bg-white divide-y divide-gray-300 packaging-items-table"
          cellClassName="py-2 bg-gray-100 border-1-bottom-gray px-2 text-sm"
        >
          <Column
            field="product_lot"
            header="Product Lot"
            style={{ width: "7%" }}
          />
          <Column
            field="product_code"
            header="Product Plu"
            style={{ width: "4%" }}
          />
          <Column
            field="serial_number"
            header="Serial Number"
            style={{ width: "6%" }}
          />
          <Column field="product_name" header="Name" style={{ width: "8%" }} />
          <Column field="brand_name" header="Brand" style={{ width: "8%" }} />
          <Column
            field="shipping_barcode"
            header="Shipping Barcode"
            style={{ width: "20%" }}
          />
          <Column
            header="Packaging Date"
            body={packagingDateBodyTemplate}
            style={{ width: "8%" }}
          />
          <Column
            header="Pack Size"
            body={packSizeBodyTemplate}
            style={{ width: "11%" }}
          />
          <Column
            field="best_before_date"
            header="Best Before Date"
            style={{ width: "6%" }}
          />
          <Column
            field="sale_status"
            header="Sale Status"
            body={saleStatusDateBodyTemplate}
            style={{ width: "5%" }}
          />
          <Column
            header=""
            body={singleItemActionTemplate}
            style={{ width: "8%" }}
          />
        </DataTable>
      </div>
    );
  };

  const stockInWeightBody = (rowData) => {
    return <p>{convertToTwoDecimals(rowData.stock_in_weight)} kg</p>;
  };

  return (
    <div>
      <SimpleBanner
        setShow={setShowSimpleNotification}
        showSimpleNotification={showSimpleNotification}
      />
      <div className="hide-for-print mt-2">
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="sm:flex sm:items-center px-4 py-5 sm:px-4 card-custom">
            <div className="sm:flex-auto">
              <h1 className="text-base font-semibold leading-6 text-gray-900">
                Packaging Records
              </h1>
              <div className="mt-5 flex rounded-md">
                <div className="relative flex items-stretch flex-grow focus-within:z-10">
                  <input
                    type="search"
                    name="searchPackaging"
                    id="searchPackaging"
                    className="block w-full rounded-lg rounded-l-md pl-2 sm:text-sm border border-gray-300 rounded-tr-none rounded-br-none"
                    placeholder="Search by packaging name or plu"
                    ref={searchRef}
                    onKeyDown={(e) =>
                      e.key === "Enter" ? handleSearch() : null
                    }
                  />
                </div>
                <button
                  type="button"
                  className="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1"
                  onClick={() => handleSearch()}
                >
                  <MagnifyingGlassIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </button>

                <FilterDropdown
                  actionCall={() => handleSearch()}
                  filterComponents={filterComponents}
                  resetFilters={() => {
                    resetFilters();
                  }}
                  showFilters={showFilters}
                  setShowFilters={setShowFilters}
                />
                <div>
                  <button
                    className="ml-2 rounded-md bg-main-purple px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    onClick={() => navigate("/packaging-new")}
                  >
                    Create packaging
                  </button>
                </div>

                <ReportsDropdown
                  actionCall={generatePackagingReport}
                  reportComponents={reportComponents}
                  showReports={showReports}
                  setShowReports={setShowReports}
                  title="Reports"
                  reportInProgress={reportInProgress}
                />
              </div>
            </div>
          </div>
          <div className="mt-8 flow-root">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                {loading ? (
                  <div>
                    <div className="border h-10 shadow rounded-md p-4 w-full mx-auto">
                      <div className="animate-pulse flex space-x-4">
                        Loading
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="overflow-hidden card-custom bg-white">
                    <DataTable
                      value={packagings.data}
                      expandedRows={expandedRows}
                      onRowToggle={(e) => {
                        setExpandedRows(e.data);
                      }}
                      rowExpansionTemplate={rowExpansionTemplate}
                      dataKey="id"
                      tableClassName="min-w-full bg-white divide-y divide-gray-300 text-sm"
                      cellClassName="p-2 bg-white border-1-bottom-gray text-sm"
                    >
                      <Column
                        field="product_code"
                        header="Product Plu"
                        style={{ width: "5%" }}
                      />
                      <Column
                        field="product_name"
                        header="Name"
                        style={{ width: "14%" }}
                      />
                      <Column
                        field="description_en"
                        header="Description"
                        style={{ width: "14%" }}
                      />
                      <Column
                        field="product_lot"
                        header="Product Lot"
                        style={{ width: "6%" }}
                      />
                      <Column
                        field="brand_name"
                        header="Brand"
                        style={{ width: "8%" }}
                      />
                      <Column
                        header="Packaging Date"
                        body={packagingDateBodyTemplate}
                        style={{ width: "11%" }}
                      />
                      <Column
                        field="best_before_date"
                        header=" Best Before Date"
                        style={{ width: "8%" }}
                      />
                      <Column
                        body={totalPackagedBody}
                        header="Total Packed/Cs"
                        style={{ width: "7%" }}
                      />
                      <Column
                        header="Total Packaged"
                        body={stockInWeightBody}
                        style={{ width: "7%" }}
                      />
                      <Column
                        header=""
                        body={massItemActionTemplate}
                        style={{ width: "12%" }}
                      />
                      <Column
                        expander={allowExpansion}
                        style={{ width: "5%" }}
                      />
                    </DataTable>
                  </div>
                )}
              </div>
              <div className="w-full">
                <Paginator
                  first={first}
                  rows={rows}
                  totalRecords={packagings.total}
                  onPageChange={handlePagination}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <EmptyModal
        childComponent={emptyModal.childComponent}
        open={emptyModal.open}
        setOpen={setEmptyModelOpen}
      />
      <SlideOver
        customClass="max-w-3xl"
        childComponent={slideOver.childComponent}
        slideOver={slideOver}
        setSlideOver={setSlideOver}
      />

      {printingLabelType.type === 1 ? (
        <PackagingSkidLabel skidLabel={skidLabel} />
      ) : (
        <PackagingProductLabel packagingLabel={packagingLabel} />
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(actions.logout()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PackagingRecords)
);
