import { formatDate } from "../../../../shared/utility";

export default function ProductionCostReport(props) {
  const { manufacturedProduct, selectedRecipe } = props;

  function getTotalSales() {
    var totalPackedUnits = manufacturedProduct.new_weight;
    if (manufacturedProduct.packing_size > 0) {
      totalPackedUnits = (
        manufacturedProduct.new_weight / manufacturedProduct.packing_size
      ).toFixed(2);
    }

    const salePrice = manufacturedProduct.sale_price;

    return (
      "Total Packed Units: " +
      totalPackedUnits +
      "       Sale Price: $" +
      salePrice +
      "       Total Sale $" +
      manufacturedProduct.total_sales
    );
  }

  console.log("manufacturedProduct", manufacturedProduct);
  return (
    <div className="hidden regular-page-to-print">
      <div className="grid grid-cols-2 gap-2 p-2 pb-0">
        <div className="col-span-2">
          <div className="mb-4">
            <h3 className="font-bold text-2xl">
              {manufacturedProduct.product_name}
            </h3>
          </div>
        </div>
        <div className="mb-4">
          <h3 className="text-sm">Product PLU: {manufacturedProduct.plu}</h3>
          <h3 className="text-sm">
            Production Date: {formatDate(manufacturedProduct.created_at)}
          </h3>
          <h3 className="text-sm">
            Lot Number: {manufacturedProduct.new_lot_number}
          </h3>
          <h3 className="text-sm">
            Supervisor: {manufacturedProduct.supervisor.label}
          </h3>
          <h3 className="text-sm">
            Target Weight: {manufacturedProduct.new_weight}
            {" kg"}
          </h3>
        </div>
        <div className="text-end float-right">
          <img
            src={manufacturedProduct.brand_logo}
            alt="Brand logo"
            width="150px"
            height="115px"
            className="float-right"
          />
        </div>
        <div className="overflow-hidden card-custom col-span-2">
          <table className="min-w-full divide-y divide-gray-300">
            <thead className="bg-white">
              <tr>
                <th
                  scope="col"
                  className="py-1.5 pl-3 pr-3 text-left text-xs font-semibold text-gray-900 sm:pl-6"
                >
                  Bom Plu
                </th>
                <th
                  scope="col"
                  className="px-3 py-1.5 text-left text-xs font-semibold text-gray-900"
                >
                  Bom Name
                </th>
                <th
                  scope="col"
                  className="px-3 py-1.5 text-left text-xs font-semibold text-gray-900"
                >
                  Required Amount
                </th>
                <th
                  scope="col"
                  className="px-3 py-1.5 text-left text-xs font-semibold text-gray-900"
                >
                  Amount in use
                </th>
                <th
                  scope="col"
                  className="px-3 py-1.5 text-left text-xs font-semibold text-gray-900"
                >
                  Unit Price
                </th>
                <th
                  scope="col"
                  className="px-3 py-1.5 text-left text-xs font-semibold text-gray-900"
                >
                  Total
                </th>
              </tr>
            </thead>
            <tbody className="bg-white">
              {selectedRecipe.recipeItems.length > 0 ? (
                selectedRecipe.recipeItems.map((recipeItem) => (
                  <tr key={recipeItem.id}>
                    <td className="whitespace-nowrap py-1.5 pl-4 pr-3 text-xs font-medium text-gray-900 sm:pl-6">
                      {recipeItem.bom_plu}
                    </td>
                    <td className="whitespace-nowrap px-3 py-1.5 text-xs text-gray-500">
                      {recipeItem.bom_name}
                    </td>
                    <td className="whitespace-nowrap px-3 py-1.5 text-xs text-gray-500">
                      {(
                        parseFloat(recipeItem.recipe_per_kg) *
                        manufacturedProduct.new_weight
                      ).toFixed(2)}{" "}
                      {recipeItem.unit_of_measure_label}
                    </td>
                    <td className="whitespace-nowrap px-3 py-1.5 text-xs text-gray-500">
                      {parseFloat(recipeItem.amount_in_use).toFixed(3)}{" "}
                      {recipeItem.unit_of_measure_label}
                    </td>
                    <td className="whitespace-nowrap px-3 py-1.5 text-xs text-gray-500">
                      $
                      {(
                        recipeItem.total_cost / recipeItem.amount_in_use
                      ).toFixed(2)}
                    </td>
                    <td className="whitespace-nowrap px-3 py-1.5 text-xs text-gray-500">
                      {`$${recipeItem.total_cost}`}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={6} className="py-5 text-center">
                    Please choose an ingredient
                  </td>
                </tr>
              )}
              <tr>
                <td
                  colSpan={6}
                  className="whitespace-nowrap px-3 py-1.5 text-xs text-gray-500 text-end"
                >
                  Total Raw Material Cost: $
                  {manufacturedProduct.total_raw_material_cost}
                </td>
              </tr>
              <tr>
                <td
                  colSpan={6}
                  className="whitespace-nowrap px-3 py-1.5 text-xs text-gray-500 text-end"
                >
                  Overhead Cost: ${manufacturedProduct.overhead_total}
                </td>
              </tr>
              <tr>
                <td
                  colSpan={6}
                  className="whitespace-nowrap px-3 py-1.5 text-xs text-gray-500 text-end"
                >
                  Labour Cost: ${manufacturedProduct.labour_total}
                </td>
              </tr>
              <tr>
                <td
                  colSpan={6}
                  className="whitespace-nowrap px-3 py-1.5 text-xs text-gray-500 text-end"
                >
                  Total Cost: ${manufacturedProduct.grand_total}
                </td>
              </tr>
              <tr>
                <td
                  colSpan={6}
                  className="whitespace-nowrap px-3 py-1.5 text-xs text-gray-500 text-end"
                >
                  {getTotalSales()}
                </td>
              </tr>
              <tr>
                <td
                  colSpan={6}
                  className="whitespace-nowrap px-3 py-1.5 text-xs text-gray-500 text-end"
                >
                  Total Profit: ${manufacturedProduct.total_profit}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
