import SelectMenu from "../controls/SelectMenu";

export default function FilterSingleSelectDropdown(props) {
  const { header, onChange, options, resetFilter, valueName, value } = props;

  return (
    <div className="p-3">
      <div className="flex justify-between">
        <p className="text-sm text-gray-700 font-semibold">{header}</p>
        <button
          onClick={() => resetFilter()}
          className="text-sm font-semibold main-purple"
        >
          Reset
        </button>
      </div>
      <div className="mt-1">
        <SelectMenu
          handleInputChange={(e) => {
            onChange(e.target.value);
          }}
          name={valueName}
          options={options}
          value={value}
        />
        {/* <SimpleDropdown
          actionCall={(value, label) =>
            onChange(valueName, labelName, value, label)
          }
          title={title}
          options={options}
          className="w-full"
        /> */}
      </div>
    </div>
  );
}
