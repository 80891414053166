import React, { useEffect, useRef, useState } from "react";

import { Dialog } from "@headlessui/react";
import { MagnifyingGlassIcon, XMarkIcon } from "@heroicons/react/24/outline";

export default function AssemblyItemsTableComponent(props) {
  const { assemblyItemsList, setItemValues, setOpen } = props;

  const searchRef = useRef();

  const [assemblyItems, setAssemblyItems] = useState([]);

  useEffect(() => {
    setAssemblyItems(assemblyItemsList);
  }, [assemblyItemsList]);

  const handleSearch = () => {
    const newAssemblyItems = assemblyItemsList.filter((item) =>
      item.product_name
        .toLowerCase()
        .includes(searchRef.current.value.toLowerCase())
    );

    setAssemblyItems(newAssemblyItems);
  };

  return (
    <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
      <div className="flex min-h-0 flex-1 flex-col overflow-y-scroll py-6">
        <div className="px-4 sm:px-6">
          <div className="flex items-start justify-between">
            <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
              Choose a supplier item
            </Dialog.Title>
            <div className="ml-3 flex h-7 items-center">
              <button
                type="button"
                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                onClick={() => setOpen({ childComponent: null, open: false })}
              >
                <span className="sr-only">Close panel</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
          </div>
          <div className="mt-2 flex rounded-md">
            <div className="relative flex items-stretch flex-grow focus-within:z-10">
              <input
                type="search"
                name="searchInput"
                id="searchInput"
                className="block w-full rounded-lg rounded-l-md pl-2 sm:text-sm border border-gray-300 rounded-tr-none rounded-br-none"
                placeholder="Search by name"
                ref={searchRef}
                onKeyDown={(e) => (e.key === "Enter" ? handleSearch() : null)}
              />
            </div>
            <button
              type="button"
              className="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1"
              onClick={() => handleSearch()}
            >
              <MagnifyingGlassIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </button>
          </div>
        </div>
        <div className="relative mt-6 flex-1 px-4 sm:px-6">
          <div id="product-table" className="overflow-hidden card-custom">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-white">
                <tr>
                  <th
                    scope="col"
                    className="py-3 pl-3 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                  >
                    Name / Product code
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Manufacturer
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Manufacturer Plu
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {assemblyItems.map((assemblyItem) => (
                  <tr
                    key={assemblyItem.id}
                    className="cursor-pointer"
                    onClick={() => {
                      setItemValues(assemblyItem);
                      setOpen({ childComponent: null, open: false });
                    }}
                  >
                    <td className="whitespace-nowrap py-1.5 pl-4 pr-3 text-sm font-medium text-gray-500 sm:pl-6">
                      {assemblyItem.product_name} <br />
                      {assemblyItem.product_code}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {assemblyItem.manufacturer_name}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {assemblyItem.manufacturer_plu}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
