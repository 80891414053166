import { useState } from "react";

import { makePostApiCall } from "../../shared/makeApiCall";
import { BannerNotificationType } from "../../components/SimpleBanner";
import DeleteConfirmationForm from "../../components/forms/DeleteConfirmationForm";

export function RecipeDetailsViewModel(logout) {
  const [emptyModal, setEmptyModelOpen] = useState({
    open: false,
    childComponent: null,
  });
  const [bomItemsLoading, setBomItemsLoading] = useState(false);
  const [bomItems, setBomItems] = useState({ data: [] });
  const [recipeItems, setRecipeItems] = useState([]);
  const [packagingItems, setPackagingItems] = useState([]);
  const [recipe, setRecipe] = useState({ id: 0 });
  const [recipeLoading, setRecipeLoading] = useState(false);

  const [showSimpleNotification, setShowSimpleNotification] = useState({
    show: false,
    message: null,
    title: null,
    type: BannerNotificationType.Success,
  });
  const [slideOver, setSlideOver] = useState({
    childComponent: null,
    open: false,
  });

  const getRecipe = async function (recipeId) {
    setRecipeLoading(true);

    let callResult = await makePostApiCall(`fetchRecipe/${recipeId}`);
    setRecipeLoading(false);
    if (callResult.success) {
      setRecipe(callResult.data.recipe);
      setRecipeItems(callResult.data.recipeItems);
      setPackagingItems(callResult.data.packagingItems);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const getBomItems = async function (search, page) {
    setBomItemsLoading(true);
    const payload = {
      page: page,
      search: search,
    };
    let callResult = await makePostApiCall(`myIngredients`, payload);
    setBomItemsLoading(false);
    if (callResult.success) {
      setBomItems(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const addBomToIngredient = async function (payload) {
    let callResult = await makePostApiCall(`addIngredientToRecipe`, payload);
    setBomItemsLoading(false);
    if (callResult.success) {
      return callResult;
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const saveRecipe = async function (values, isEdit) {
    const payload = {
      recipe: values,
      recipeItems: recipeItems,
      packagingItems: packagingItems,
    };
    let callResult = await makePostApiCall(
      isEdit ? `updateRecipe` : `createRecipe`,
      isEdit ? payload : values
    );
    if (callResult.success) {
      setRecipe(callResult.data.recipe);
      setShowSimpleNotification({
        show: true,
        title: callResult.data.message,
        type: BannerNotificationType.Success,
      });
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const deleteRecipe = async function (id, type) {
    let callResult = await makePostApiCall(`deleteRecipeIngredient/${id}`);
    if (callResult.success) {
      if (type === 1) {
        const filteredPackages = packagingItems.filter(
          (item) => item.id !== id
        );
        setPackagingItems(filteredPackages);
      } else {
        const filteredRecipeItems = recipeItems.filter(
          (item) => item.id !== id
        );
        setRecipeItems(filteredRecipeItems);
      }
      setEmptyModelOpen({
        open: false,
        childComponent: null,
      });
      setShowSimpleNotification({
        show: true,
        title: callResult.data.message,
        type: BannerNotificationType.Success,
      });
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const askDeleteRecipe = function (recipe, type) {
    setEmptyModelOpen({
      childComponent: (
        <DeleteConfirmationForm
          buttonMessage="Delete"
          confirmAction={() => deleteRecipe(recipe.id, type)}
          message={`Are you sure you want to delete ${recipe.bom_name}?`}
          setOpen={setEmptyModelOpen}
        />
      ),
      open: true,
    });
  };

  return {
    bomItems,
    bomItemsLoading,
    emptyModal,
    getBomItems,
    setEmptyModelOpen,
    setSlideOver,
    setShowSimpleNotification,
    slideOver,
    showSimpleNotification,
    recipeItems,
    addBomToIngredient,
    setRecipeItems,
    packagingItems,
    setPackagingItems,
    saveRecipe,
    recipe,
    getRecipe,
    askDeleteRecipe,
  };
}
