import {
  Cog6ToothIcon,
  HomeIcon,
  UsersIcon,
  ArchiveBoxArrowDownIcon,
  HomeModernIcon,
  ScaleIcon,
  TruckIcon,
  ArrowPathRoundedSquareIcon,
  ChartBarIcon,
  CalculatorIcon,
  ChartPieIcon,
  NewspaperIcon,
} from "@heroicons/react/24/outline";

export default function routes(pathname, user_type) {
  var title;
  var routes;

  const isCFIA = user_type === 2;

  routes = [
    {
      name: "Dashboard",
      href: "/dashboard",
      icon: HomeIcon,
      current: false,
      showInMenu: !isCFIA,
    },
    {
      name: "Export label",
      href: "/export-label",
      icon: NewspaperIcon,
      current: false,
      showInMenu: isCFIA,
    },
    {
      name: "Receiving",
      icon: ArchiveBoxArrowDownIcon,
      current: false,
      showInMenu: !isCFIA,
      children: [
        { name: "Add new", href: "/receiving-invoices/new", current: false },
        { name: "History", href: "/receiving-invoices", current: false },
        { name: "Records", href: "/receiving-index", current: false },
      ],
    },
    {
      name: "Production",
      icon: HomeModernIcon,
      current: false,
      showInMenu: !isCFIA,
      children: [
        {
          name: "Manufacture Product",
          href: "/manufacture-product",
          current: false,
        },
        { name: "History", href: "/manufactured-products", current: false },
      ],
    },
    {
      name: "Packaging",
      icon: ScaleIcon,
      current: false,
      showInMenu: !isCFIA,
      children: [
        {
          name: "Package Product",
          href: "/packaging-new",
          current: false,
        },
        { name: "History", href: "/packaging-reports", current: false },
      ],
    },
    {
      name: "Orders",
      icon: NewspaperIcon,
      current: false,
      showInMenu: !isCFIA,
      open: false,
      children: [
        { name: "Create", href: "/create-order" },
        { name: "Manage Orders", href: "/orders" },
        { name: "Import SKUs", href: "/import-skus" },
      ],
    },
    {
      name: "Shipping",
      icon: TruckIcon,
      current: false,
      showInMenu: !isCFIA,
      open: false,
      children: [
        { name: "Create Shipping", href: "/create-shipping" },
        { name: "History", href: "/shippings" },
      ],
    },
    {
      name: "Inventory",
      icon: ScaleIcon,
      current: false,
      showInMenu: !isCFIA,
      children: [
        {
          name: "Raw Material",
          href: "/inventory-raw-material",
          current: false,
        },
        {
          name: "Manufactured",
          href: "/inventory-manufactured-material",
          current: false,
        },
      ],
    },
    {
      name: "Traceability",
      href: "/traceability",
      icon: ArrowPathRoundedSquareIcon,
      current: false,
      showInMenu: !isCFIA,
    },
    // {
    //   name: "QA Reports",
    //   icon: ChartBarIcon,
    //   current: false,
    //   showInMenu: !isCFIA,
    //   children: [
    //     {
    //       name: "Temperature",
    //       href: "/temperature-sensors",
    //       current: false,
    //     },
    //   ],
    // },
    {
      name: "Recipes",
      href: "/recipes",
      icon: ChartPieIcon,
      current: false,
      showInMenu: !isCFIA,
    },
    {
      name: "Setup",
      icon: Cog6ToothIcon,
      current: false,
      showInMenu: !isCFIA,
      open: false,
      children: [
        {
          name: "Allergens",
          href: "/product-allergens",
          current: false,
        },
        { name: "BOM items", href: "/bom-items", current: false },
        { name: "Brands", href: "/brands", current: false },
        {
          name: "Certification Types",
          href: "/certification-types",
          current: false,
        },
        { name: "Claims", href: "/product-claims", current: false },
        { name: "Customers", href: "/customers", current: false },
        { name: "Employees", href: "/employees", current: false },
        { name: "Manufacturers", href: "/manufacturers", current: false },
        { name: "Media Manager", href: "/media-manager", current: false },
        { name: "My Products", href: "/my-products", current: false },
        { name: "Preservatives", href: "/preservatives", current: false },
        { name: "Production Costs", href: "/production-costs", current: false },
        { name: "Supplier items", href: "/supplier-items", current: false },
        { name: "Types", href: "/product-types", current: false },
        { name: "Unit of measures", href: "/unit-of-measures", current: false },
        { name: "Users", href: "/users", current: false },
      ],
    },
  ];

  routes.forEach((route) => {
    route.open = false;
    if (route.children) {
      route.children.forEach((child) => {
        child.current = false;
        if (child.href === pathname) {
          route.open = true;
          child.current = true;

          title = child.name;
        }
      });
    } else if (route.href === pathname) {
      route.current = true;

      title = route.name;
    }
  });

  var returnArray = {
    routes: routes.filter((route) => route.showInMenu),

    title: title,
  };

  return returnArray;
}
