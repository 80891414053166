import { useState } from "react";

import { makePostApiCall } from "../../shared/makeApiCall";
import { BannerNotificationType } from "../../components/SimpleBanner";
import ModelErrorComponent from "../../components/modals/ModelErrorComponent";

export function ShippingDetailsViewModel(shippingId, logout) {
  const [employeeOptions, setEmployeeOptions] = useState([]);
  const [emptyModal, setEmptyModelOpen] = useState({
    open: false,
    childComponent: null,
  });
  const [shipping, setShipping] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showSimpleNotification, setShowSimpleNotification] = useState({
    show: false,
    message: null,
    title: null,
    type: BannerNotificationType.Success,
  });
  const [slideOver, setSlideOver] = useState({
    childComponent: null,
    open: false,
  });

  const getShippingDetails = async function () {
    let callResult = await makePostApiCall(`fetchShipping/${shippingId}`);
    if (callResult.success) {
      const orderDetails = await onFetchOrderItems(callResult.data.order_id);
      if (orderDetails.success) {
        const order = orderDetails.data;
        setShipping({
          ...callResult.data,
          order_id: order.id,
          customer: order.customer,
          orderItems: [...order.products],
        });

        setLoading(false);
      }
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
      showErrorModal(callResult.data.message);
    }
  };

  const onFetchOrderItems = async function (id) {
    let callResult = await makePostApiCall(`fetchOrder/${id}`);
    if (callResult.success) {
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
    return callResult;
  };

  const showErrorModal = (title, subtitle) => {
    setEmptyModelOpen({
      childComponent: (
        <ModelErrorComponent
          setOpen={setEmptyModelOpen}
          subtitle={subtitle}
          title={title}
        />
      ),
      open: true,
    });
  };

  const updateShippingApiCall = async function (payload) {
    let callResult = await makePostApiCall(
      `updateShipping/${shippingId}`,
      payload
    );
    if (callResult.success) {
      setShowSimpleNotification({
        show: true,
        title: callResult.data.message,
        type: BannerNotificationType.Success,
      });
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
      showErrorModal(callResult.data.message);
    }
  };

  const onFetchPackagedItemByBarcode = async function (scannedBarcode) {
    let callResult = await makePostApiCall(
      `fetchPackagedItemByBarcode/${scannedBarcode}`
    );
    if (callResult.success) {
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
    return callResult;
  };

  const addPackagedItemToOrderItem = async function (
    shippingId,
    orderItem,
    packagedItem
  ) {
    const payload = {
      shippingId: shippingId,
      orderItem: orderItem,
      packagedItem: packagedItem,
    };
    let callResult = await makePostApiCall(
      "addPackagedItemToOrderItem",
      payload
    );
    if (callResult.success) {
      setShowSimpleNotification({
        show: true,
        title: "Package added to shipping",
        type: BannerNotificationType.Success,
      });
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
    return callResult;
  };

  const deleteShippingItemApiCall = async function (id) {
    let callResult = await makePostApiCall(`deleteShippingItem/${id}`);
    if (callResult.success) {
      setShowSimpleNotification({
        show: true,
        title: callResult.data.message,
        type: BannerNotificationType.Success,
      });
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
    return callResult;
  };

  const addSkidLabelToOrderItem = async function (
    shippingId,
    orderItemId,
    skidLabelNumber
  ) {
    const payload = {
      shippingId: shippingId,
      orderItemId: orderItemId,
      packaged_items: skidLabelNumber,
    };
    let callResult = await makePostApiCall(
      "addSkidLabelNumberToOrderItem",
      payload
    );
    if (callResult.success) {
      setShowSimpleNotification({
        show: true,
        title: "Packages added to shipping",
        type: BannerNotificationType.Success,
      });
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
    return callResult;
  };

  const getEmployeesForDropdown = async function (type, department) {
    const payload = { department: department, type: type };
    let callResult = await makePostApiCall(`employeesForDropdown`, payload);

    if (callResult.success) {
      setEmployeeOptions(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
    return callResult;
  };

  const deleteShipping = async function () {
    let callResult = await makePostApiCall(`shippings/delete/${shippingId}`);

    if (callResult.success) {
      window.location = "/shippings";
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
      showErrorModal(callResult.data.error);
    }
  };

  const printShipping = async function () {
    let callResult = await makePostApiCall(`printShipping/${shippingId}`);

    if (callResult.success) {
      window.open(callResult.data.location, "_blank");
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const quickAddPackagedItemToOrderItem = async function (
    shippingId,
    orderItemId,
    lotItem,
    original_scanned_amount
  ) {
    const payload = {
      shippingId: shippingId,
      orderItemId: orderItemId,
      lotItem: lotItem,
      original_scanned_amount: original_scanned_amount,
    };
    let callResult = await makePostApiCall(
      "quickAddPackagedItemToOrderItem",
      payload
    );
    if (callResult.success) {
      setShowSimpleNotification({
        show: true,
        title: "Package(s) added to shipping",
        type: BannerNotificationType.Success,
      });
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
    return callResult;
  };

  const fetchPackagedItemsForQuickScan = async function (payload) {
    let callResult = await makePostApiCall(
      `fetchPackagedItemsForQuickScan`,
      payload
    );
    if (callResult.success) {
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
    return callResult;
  };

  const deleteQuickScanShippingItemByLot = async function (payload) {
    let callResult = await makePostApiCall(
      `deleteQuickScanShippingItemByLot`,
      payload
    );
    if (callResult.success) {
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
      showErrorModal("We could not delete the item");
    }
    return callResult;
  };

  const exportOrderForNetSuite = async function () {
    let callResult = await makePostApiCall(`exportOrderForNetSuite`, {
      shipments: [shippingId],
    });
    if (callResult.success) {
      window.open(callResult.data.location, "_blank").focus();
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  return {
    onFetchOrderItems,
    loading,
    showSimpleNotification,
    setEmptyModelOpen,
    setShowSimpleNotification,
    setSlideOver,
    slideOver,
    emptyModal,
    showErrorModal,
    updateShippingApiCall,
    shipping,
    onFetchPackagedItemByBarcode,
    setShipping,
    addPackagedItemToOrderItem,
    getShippingDetails,
    deleteShippingItemApiCall,
    addSkidLabelToOrderItem,
    employeeOptions,
    getEmployeesForDropdown,
    deleteShipping,
    printShipping,
    quickAddPackagedItemToOrderItem,
    fetchPackagedItemsForQuickScan,
    deleteQuickScanShippingItemByLot,
    exportOrderForNetSuite,
  };
}
