import { useState } from "react";

import { makePostApiCall } from "../../shared/makeApiCall";
import { BannerNotificationType } from "../../components/SimpleBanner";
import DeleteConfirmationForm from "../../components/forms/DeleteConfirmationForm";
import PackagingDetails from "../../components/forms/packaging/PackagingDetails";
import FilterSingleSelectDropdown from "../../components/filterDropdown/FilterSingleSelectDropdown";
import DateRangeFilter from "../../components/filterDropdown/DateRangeFilter";

const reportTypeOptions = [{ value: 1, label: "Packaging Report" }];

export function PackagingRecordsViewModel(logout) {
  const [filters, setFilters] = useState({
    supervisor: { value: -1, label: "All Employees" },
    employee1: { value: -1, label: "All Employees" },
    brand: { value: -1, label: "All Brands" },
    startDate: null,
    endDate: null,
  });

  const [brands, setBrands] = useState([]);
  const [employeeOptions, setEmployeeOptions] = useState([]);
  const [supervisorOptions, setSupervisorOptions] = useState([]);
  const [emptyModal, setEmptyModelOpen] = useState({
    open: false,
    childComponent: null,
  });
  const [loading, setLoading] = useState(false);
  const [packagings, setPackagings] = useState({
    data: [],
  });
  const [showSimpleNotification, setShowSimpleNotification] = useState({
    show: false,
    message: null,
    title: null,
    type: BannerNotificationType.Success,
  });
  const [slideOver, setSlideOver] = useState({
    childComponent: null,
    open: false,
  });
  const [printingLabelType, setPrintingLabelType] = useState({ type: 2 });
  const [showFilters, setShowFilters] = useState(false);
  const [skidLabel, setSkidLabel] = useState({
    skid_label_number: "",
  });
  const [packagingLabel, setPackagingLabel] = useState({
    brand_image1: null,
    brand_image2: null,
    brand_image3: null,
    brand_name: null,
    address: null,
    product_lot: null,
    product_plu: null,
    ingredients: null,
    product_name: null,
    product_description: null,
    passed_from_metal_detector: null,
    product_weight: null,
    barcodeHtml: null,
    barcode: null,
    printCounter: 0,
    production_date: null,
    best_before_date: null,
  });
  const [bulkActionInProgress, setBulkActionInProgress] = useState({
    lot: -1,
    type: PackagingBulkActionType.NULL,
  });
  const [reportFilters, setReportFilters] = useState({
    type: { value: -1, label: "Select report" },
    startDate: null,
    endDate: null,
  });
  const [reportInProgress, setReportInProgress] = useState(false);
  const [showReports, setShowReports] = useState(false);

  const getPackagings = async function (search, page, filterArg) {
    setLoading(true);
    const payload = {
      page: page,
      filters: { ...filterArg, search: search },
    };
    let callResult = await makePostApiCall(`fetchAllPackagingRecords`, payload);
    setLoading(false);
    if (callResult.success) {
      setPackagings(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const deletePackaging = async function (id, index) {
    setLoading(true);

    let callResult = await makePostApiCall(`deleteSinglePackagedItem/${id}`);
    setLoading(false);
    if (callResult.success) {
      setEmptyModelOpen({
        open: false,
        childComponent: null,
      });
      getPackagings(null, 0, filters);
      setShowSimpleNotification({
        show: true,
        title: callResult.data.message,
        type: BannerNotificationType.Success,
      });
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const askDeletePackaging = function (packaging, index) {
    setEmptyModelOpen({
      childComponent: (
        <DeleteConfirmationForm
          buttonMessage="Delete"
          confirmAction={() => deletePackaging(packaging.id, index)}
          message={`Are you sure you want to delete ${packaging.product_name} - Serial: ${packaging.serial_number}?`}
          setOpen={setEmptyModelOpen}
        />
      ),
      open: true,
    });
  };

  const deletePackagingLot = async function (lot, packaging_date) {
    setLoading(true);

    let callResult = await makePostApiCall(`deleteEntirePackaging/${lot}`, {
      packaging_date: packaging_date,
    });
    setLoading(false);
    if (callResult.success) {
      setEmptyModelOpen({
        open: false,
        childComponent: null,
      });
      getPackagings(null, 0, filters);
      setShowSimpleNotification({
        show: true,
        title: callResult.data.message,
        type: BannerNotificationType.Success,
      });
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const askDeletePackagingLot = function (packaging, index) {
    setEmptyModelOpen({
      childComponent: (
        <DeleteConfirmationForm
          buttonMessage="Delete"
          confirmAction={() =>
            deletePackagingLot(packaging.product_lot, packaging.packaging_date)
          }
          message={`Are you sure you want to delete ${packaging.product_name} - Lot: ${packaging.product_lot}?`}
          setOpen={setEmptyModelOpen}
        />
      ),
      open: true,
    });
  };

  function printPackagingLabelRecursive(labels) {
    setTimeout(function () {
      window.print();

      if (labels.printCounter < labels.items.length) {
        const newLabelDetails = {
          ...labels,
          printCounter: labels.printCounter + 1,
        };

        const item = newLabelDetails.items[labels.printCounter];
        setPackagingLabel({
          brand_image1: item.brand_image1,
          brand_image2: item.brand_image2,
          brand_image3: item.brand_image3,
          brand_name: item.brand_name,
          address: item.address,
          product_lot: item.product_lot,
          product_plu: item.product_plu,
          ingredients: item.ingredients,
          product_name: item.product_name,
          product_description: item.product_description,
          passed_from_metal_detector: item.passed_from_metal_detector,
          product_weight: item.packing_size,
          barcodeHtml: item.barcodeHtml,
          barcode: item.shipping_barcode,
          production_date: item.production_date,
          best_before_date: item.best_before_date,
        });

        printPackagingLabelRecursive(newLabelDetails);
      }
    }, 125);
  }

  const packagingReprintAll = async function (lot, packagingDate) {
    const payload = { packaging_date: packagingDate };
    setBulkActionInProgress({
      type: PackagingBulkActionType.Reprint,
      lot: lot,
    });
    let callResult = await makePostApiCall(
      `reprintPackagingByLot/${lot}`,
      payload
    );

    if (callResult.success) {
      if (callResult.data.number_of_label_to_print > 1) {
        window.open(callResult.data.location, "_blank");
      } else {
        const item = callResult.data.items[0];
        setPackagingLabel({
          brand_image1: item.brand_image1,
          brand_image2: item.brand_image2,
          brand_image3: item.brand_image3,
          brand_name: item.brand_name,
          address: item.address,
          product_lot: item.product_lot,
          product_plu: item.product_plu,
          ingredients: item.ingredients,
          product_name: item.product_name,
          product_description: item.product_description,
          passed_from_metal_detector: item.passed_from_metal_detector,
          product_weight: item.product_weight + "kg",
          barcodeHtml: item.barcodeHtml,
          barcode: item.shipping_barcode,
          production_date: item.production_date,
          best_before_date: item.best_before_date,
          product_barcode: item.product_barcode,
          product_barcode_html: item.product_barcode_html,
        });
        // const labels = {
        //   items: callResult.data.items,
        //   printCounter: 1,
        // };
        setTimeout(function () {
          window.print();
          // printPackagingLabelRecursive(labels);
        }, 250);
      }
    }

    setBulkActionInProgress({ type: PackagingBulkActionType.NULL, lot: -1 });
  };

  const reprintSinglePackagingLabel = async function (packagingId) {
    setBulkActionInProgress({
      type: PackagingBulkActionType.Reprint,
      lot: -1,
      packagingId: packagingId,
    });
    let callResult = await makePostApiCall(
      `reprintPackagingById/${packagingId}`
    );

    if (callResult.success) {
      const item = callResult.data;
      setPackagingLabel(item);

      setTimeout(function () {
        window.print();
      }, 125);
    }

    setBulkActionInProgress({
      type: PackagingBulkActionType.NULL,
      lot: -1,
      packagingId: -1,
    });
  };

  const openPackagingDetailsSideView = async function (product_lot) {
    let callResult = await makePostApiCall(
      `fetchPackagingRecord/${product_lot}`
    );
    if (callResult.success) {
      setSlideOver({
        childComponent: (
          <PackagingDetails
            packagedItem={callResult.data}
            setOpen={setSlideOver}
          />
        ),
        open: true,
      });
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const getEmployeesForDropdown = async function (type, department) {
    const payload = { department: department, type: [type] };
    let callResult = await makePostApiCall(`employeesForDropdown`, payload);

    if (callResult.success) {
      if (type === 1) {
        setEmployeeOptions(callResult.data);
      } else {
        setSupervisorOptions(callResult.data);
      }
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const fetchBrandsForDropdown = async function () {
    let callResult = await makePostApiCall(`brandsForDropdown`);

    if (callResult.success) {
      setBrands([{ value: -1, label: "All Brands" }, ...callResult.data]);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const resetFilters = () => {
    const filterReset = {
      supervisor: { value: -1, label: "All Employees" },
      employee1: { value: -1, label: "All Employees" },
      brand: { value: -1, label: "All Brands" },
      startDate: null,
      endDate: null,
    };
    setFilters(filterReset);

    setShowFilters(false);
    getPackagings(null, 1, filterReset);
  };

  const filterComponents = [
    <DateRangeFilter
      endDate={filters.endDate}
      onChange={(name, value) =>
        setFilters({
          ...filters,
          [name]: value,
        })
      }
      resetFilter={() =>
        setFilters({
          ...filters,
          startDate: null,
          endDate: null,
        })
      }
      startDate={filters.startDate}
    />,
    <FilterSingleSelectDropdown
      header="Brand"
      onChange={(value) =>
        setFilters({
          ...filters,
          brand: value,
        })
      }
      options={brands}
      resetFilter={() =>
        setFilters({
          ...filters,
          brand: { value: -1, label: "All Brands" },
        })
      }
      valueName="brand"
      value={filters.brand}
    />,
    <FilterSingleSelectDropdown
      header="Supervisor"
      onChange={(value) =>
        setFilters({
          ...filters,
          supervisor: value,
        })
      }
      options={supervisorOptions}
      resetFilter={() =>
        setFilters({
          ...filters,
          supervisor: { value: -1, label: "All Employees" },
        })
      }
      valueName="supervisor"
      value={filters.supervisor}
    />,
    <FilterSingleSelectDropdown
      header="Employee 1"
      onChange={(value) =>
        setFilters({
          ...filters,
          employee1: value,
        })
      }
      options={employeeOptions}
      resetFilter={() =>
        setFilters({
          ...filters,
          employee1: { value: -1, label: "All Employees" },
        })
      }
      valueName="employee1"
      value={filters.employee1}
    />,
  ];

  const generatePackagingReport = async function () {
    setReportInProgress(true);
    let callResult = await makePostApiCall(
      `generatePackagingReport`,
      reportFilters
    );
    if (callResult.success) {
      setEmptyModelOpen({
        open: false,
        childComponent: null,
      });
      window.open(callResult.data.location, "_blank");
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
    setReportInProgress(false);
  };

  const reportComponents = [
    <FilterSingleSelectDropdown
      header="Report type"
      onChange={(value) =>
        setReportFilters({
          ...reportFilters,
          type: value,
        })
      }
      options={reportTypeOptions}
      resetFilter={() =>
        setReportFilters({
          ...reportFilters,
          type: { value: -1, label: "Select report" },
        })
      }
      valueName="type"
      value={reportFilters.type}
    />,
    <DateRangeFilter
      endDate={reportFilters.endDate}
      onChange={(name, value) =>
        setReportFilters({
          ...reportFilters,
          [name]: value,
        })
      }
      maxDate={new Date()}
      resetFilter={() =>
        setReportFilters({
          ...reportFilters,
          startDate: null,
          endDate: null,
        })
      }
      startDate={reportFilters.startDate}
    />,
  ];

  return {
    askDeletePackaging,
    emptyModal,
    setEmptyModelOpen,
    getPackagings,
    loading,
    packagings,
    setSlideOver,
    slideOver,
    setShowSimpleNotification,
    showSimpleNotification,
    packagingReprintAll,
    printingLabelType,
    skidLabel,
    packagingLabel,
    bulkActionInProgress,
    reprintSinglePackagingLabel,
    askDeletePackagingLot,
    openPackagingDetailsSideView,
    fetchBrandsForDropdown,
    getEmployeesForDropdown,
    filters,
    showFilters,
    resetFilters,
    filterComponents,
    setShowFilters,
    generatePackagingReport,
    reportComponents,
    showReports,
    setShowReports,
    reportInProgress,
  };
}

export class PackagingBulkActionType {
  static Delete = "delete";
  static NULL = "null";
  static Reprint = "reprint";
}
