import { classNames, formatDate } from "../../../../shared/utility";

export default function ReceivingReportNonMeatUi(props) {
  const { packagingLabel } = props;

  return (
    <div className="regular-page-section-to-print  mt-2 ml-2">
      THIS IS RECEIVING REPORT non meat
    </div>
  );
}
