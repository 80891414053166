import { Link } from "react-router-dom";
import {
  formatDate,
  formatDateWithTime,
  orderStatusOptions,
} from "../../../shared/utility";
import TextWithLabel from "../../controls/TextWithLabel";
import ManufactureRecipeItemsTable from "../production/components/ManufactureRecipeItemsTable";
import ManufactureIngredientsTable from "../production/components/ManufactureIngredientsTable";
import TraceInventoryArea from "./TraceInventoryArea";

export default function TraceLotNumber(props) {
  const { product, openReceivingItemInformation } = props;

  const convertStatus = (status) => {
    const statusOption = orderStatusOptions.filter((el) => el.value === status);

    return statusOption.length === 1
      ? { text: statusOption[0].label, type: statusOption[0].type }
      : { text: orderStatusOptions[0].label, type: orderStatusOptions[0].type };
  };

  return (
    <div className="flow-root overflow-x-auto">
      <div className="inline-block min-w-full py-2 align-middle">
        <div className="grid grid-cols-2 gap-4 mt-2">
          <div className="col-span-2">
            <h3 className="text-lg mb-2">Production history</h3>
            <div className="overflow-hidden card-custom p-4 flex">
              <div className="grid grid-cols-4 gap-4 mt-2 flex-grow">
                <TextWithLabel
                  label="Product Name"
                  text={product.produced_product.product_name}
                />
                <TextWithLabel
                  label="PLU"
                  text={product.produced_product.plu}
                />
                <TextWithLabel
                  label="Description"
                  text={product.produced_product.description_en}
                />
                <TextWithLabel
                  label="Lot Number"
                  text={product.produced_product.new_lot_number}
                />
                <TextWithLabel
                  label="Production Date"
                  text={formatDate(product.produced_product.production_date)}
                />
                <TextWithLabel
                  label="Supervisor"
                  text={product.produced_product.supervisor.label}
                />
                <TextWithLabel
                  label="Certified By"
                  text={product.produced_product.certification_name}
                />
                <TextWithLabel label="Claims(s)" text="" />
                <TextWithLabel
                  label="Production Room"
                  text={product.produced_product.production_room}
                />
                <TextWithLabel
                  label="Production Room Temp C&deg;"
                  text={product.produced_product.production_room_temperature}
                />
                <TextWithLabel
                  label="Raw Product Temp C&deg;"
                  text={product.produced_product.raw_product_temperature}
                />
              </div>
              <div>
                <Link
                  className="ml-5 rounded-md bg-main-purple px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  to={"/manufactured-products/" + product.produced_product.id}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View
                </Link>
              </div>
            </div>
          </div>
          {product.inventory ? (
            <TraceInventoryArea inventory={product.inventory} />
          ) : null}
          <div className="col-span-2">
            {product.produced_product.selectedRecipe ? (
              <ManufactureRecipeItemsTable
                selectedRecipe={product.produced_product.selectedRecipe}
                values={product.produced_product}
                openReceivingItemInformation={openReceivingItemInformation}
                isTraceability={true}
              />
            ) : (
              <ManufactureIngredientsTable
                productAssemblyItems={product.produced_product.ingredients}
                openReceivingItemInformation={openReceivingItemInformation}
                isTraceability={true}
              />
            )}
          </div>
          <div className="col-span-2">
            <h3 className="text-lg mb-2">Packaging history</h3>
            <div className="overflow-hidden">
              <div className="grid grid-cols-2 gap-2 mt-2">
                {product.packaged_product.map((singleItem, eventIdx) => (
                  <div key={singleItem.id} className="card-custom p-4">
                    <div className="relative pb-8">
                      <div className="relative flex space-x-3">
                        <div>
                          <div className="grid grid-cols-4 gap-4">
                            <div className="col-span-2">
                              <TextWithLabel
                                label={`Product Name (${singleItem.product_code})`}
                                text={singleItem.product_name}
                              />
                            </div>
                            <div>
                              <TextWithLabel
                                label="Total Packaged"
                                text={singleItem.total_individual_units}
                              />
                            </div>
                            <div>
                              <TextWithLabel
                                label="In Stock"
                                text={singleItem.in_stock}
                              />
                            </div>
                          </div>
                          <div className="grid grid-cols-3 gap-4 mt-2">
                            <div>
                              <TextWithLabel
                                label="Packaging Date"
                                text={formatDateWithTime(
                                  singleItem.packaging_date
                                )}
                              />
                            </div>
                            <div>
                              <TextWithLabel
                                label="Brand"
                                text={singleItem.brand_name}
                              />
                            </div>
                            <div>
                              <TextWithLabel
                                label="Supervisor"
                                text={singleItem.supervisor}
                              />
                            </div>
                          </div>
                          <div className="grid grid-cols-4 gap-4 mt-2">
                            {singleItem.employee_1 ? (
                              <div>
                                <TextWithLabel
                                  label="Employee(s)"
                                  text={singleItem.employee_1}
                                />
                              </div>
                            ) : null}
                            <div>
                              <TextWithLabel
                                label="Best Before Date"
                                text={singleItem.best_before_date}
                              />
                            </div>
                            <div>
                              <TextWithLabel
                                label="Packing Size"
                                text={singleItem.packing_size}
                              />
                            </div>
                            <div>
                              <TextWithLabel
                                label="Shelf life"
                                text={singleItem.shelf_life}
                              />
                            </div>
                          </div>
                          <div className="grid grid-cols-4 gap-4 mt-2">
                            <div>
                              <TextWithLabel
                                label="Packaging Material 1"
                                text={singleItem.packaging_material_1}
                              />
                            </div>
                            <div>
                              <TextWithLabel
                                label="Packaging Material 2"
                                text={singleItem.packaging_material_2}
                              />
                            </div>
                            <div>
                              <TextWithLabel
                                label="Room Temp C&deg;"
                                text={singleItem.room_temperature}
                              />
                            </div>
                            <div>
                              <TextWithLabel
                                label="Product Temp C&deg;"
                                text={singleItem.product_temperature}
                              />
                            </div>
                          </div>
                          <div className="grid grid-cols-5 gap-4 mt-2">
                            <div className="col-span-2">
                              <TextWithLabel
                                label="Passed from metal detector"
                                text={
                                  singleItem.passed_from_metal_detector
                                    ? "Yes"
                                    : "No"
                                }
                              />
                            </div>
                            <div className="col-span-2">
                              <TextWithLabel
                                label="Skid Label"
                                text={
                                  singleItem.skid_label_number
                                    ? singleItem.skid_label_number
                                    : "N/A"
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="col-span-2">
            <h3 className="text-lg mb-2">Order history</h3>
            {product.orders ? (
              product.orders.length > 0 ? (
                <div className="grid grid-cols-2 gap-4 mt-2">
                  {product.orders.map((order) => (
                    <div className="overflow-hidden card-custom p-4 flex">
                      <div className="grid grid-cols-3 gap-4 flex-grow">
                        <TextWithLabel label="Customer" text={order.customer} />
                        <TextWithLabel label="PO #" text={order.po_number} />
                        <TextWithLabel
                          label="Date"
                          text={formatDate(order.order_date)}
                        />
                        <TextWithLabel
                          label="Quantity"
                          text={order.total_sold}
                        />
                        <TextWithLabel
                          label="status"
                          text={convertStatus(order.status).text}
                        />
                        <TextWithLabel
                          label="Shipped date"
                          text={order.shipped_date}
                        />
                      </div>
                      <div>
                        <Link
                          className="ml-5 rounded-md bg-main-purple px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                          to={"/orders/" + order.id}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          View
                        </Link>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="overflow-hidden card-custom p-4">
                  <div className="grid grid-cols-4 gap-4 mt-2">
                    <TextWithLabel label="Sale status" text="Not sold" />
                  </div>
                </div>
              )
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}
