import React from "react";

import { formatDate } from "../../../../shared/utility";

export default function InvoiceRecordAssemblyOptions(props) {
  const { fetchInvoiceDetailsClicked, loading, receivingIndex } = props;

  return (
    <div className="col-span-2">
      <div className="mt-5 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full align-middle sm:px-6 lg:px-8">
            {loading ? (
              <div>
                <div className="border h-10 shadow rounded-md p-4 w-full mx-auto">
                  <div className="animate-pulse flex space-x-4">Loading</div>
                </div>
              </div>
            ) : (
              <div className="overflow-hidden card-custom">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-white">
                    <tr>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Date
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Supplier
                      </th>
                      <th
                        scope="col"
                        className="py-3 pl-3 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        Invoice
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Manufacturer
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Product
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {receivingIndex.data.map((receivingIndex, index) => (
                      <tr
                        key={receivingIndex.id}
                        className="cursor-pointer"
                        onClick={() =>
                          fetchInvoiceDetailsClicked(
                            receivingIndex.receiving_assembly_item_id
                          )
                        }
                      >
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {formatDate(receivingIndex.created_at)}
                        </td>
                        <td className="whitespace-nowrap py-1.5 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          {receivingIndex.supplier_name}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {receivingIndex.invoice_number}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {receivingIndex.manufacturer_name}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {receivingIndex.product_name}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
