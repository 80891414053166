import { useEffect } from "react";
import { ShippingDetailsViewModel } from "../../viewmodels/shipping/ShippingDetailsViewModel";

import { useParams } from "react-router-dom";

import { connect } from "react-redux";

import * as actions from "../../store/actions/index";

import { withRouter } from "../../shared/utility";

import EmptyModal from "../../components/modals/EmptyModal";
import SlideOver from "../../components/slideOver/SlideOver";
import SimpleBanner from "../../components/SimpleBanner";
import CreateNewShippingForm from "../../components/forms/shipping/CreateNewShippingForm";

function ShippingDetails(props) {
  const { logout } = props;

  const { shippingId } = useParams();

  const {
    onFetchOrderItems,
    loading,
    showSimpleNotification,
    setEmptyModelOpen,
    setShowSimpleNotification,
    setSlideOver,
    slideOver,
    emptyModal,
    showErrorModal,
    updateShippingApiCall,
    shipping,
    onFetchPackagedItemByBarcode,
    addPackagedItemToOrderItem,
    getShippingDetails,
    deleteShippingItemApiCall,
    addSkidLabelToOrderItem,
    employeeOptions,
    getEmployeesForDropdown,
    deleteShipping,
    printShipping,
    quickAddPackagedItemToOrderItem,
    fetchPackagedItemsForQuickScan,
    deleteQuickScanShippingItemByLot,
    exportOrderForNetSuite,
  } = ShippingDetailsViewModel(shippingId, logout);

  const apiFunctionCalls = async function () {
    let callResult = await getEmployeesForDropdown([1, 2], [1, 5]);
    if (callResult.success) {
      getShippingDetails();
    } else {
    }
  };

  useEffect(() => {
    apiFunctionCalls();
  }, []);

  return (
    <div>
      <SimpleBanner
        setShow={setShowSimpleNotification}
        showSimpleNotification={showSimpleNotification}
      />
      <div className="mt-2 px-4">
        <div className="sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold leading-6 text-gray-900">
              Editing Shipping
            </h1>
          </div>
          <div className="mt-5">
            {shipping ? (
              <CreateNewShippingForm
                orders={[]}
                onFetchOrderItems={onFetchOrderItems}
                isEdit={true}
                loading={loading}
                setEmptyModelOpen={setEmptyModelOpen}
                showErrorModal={showErrorModal}
                updateShippingApiCall={updateShippingApiCall}
                shipping={shipping}
                onFetchPackagedItemByBarcode={onFetchPackagedItemByBarcode}
                addPackagedItemToOrderItem={addPackagedItemToOrderItem}
                deleteShippingItemApiCall={deleteShippingItemApiCall}
                addSkidLabelToOrderItem={addSkidLabelToOrderItem}
                employeeOptions={employeeOptions}
                deleteShipping={deleteShipping}
                printShipping={printShipping}
                fetchPackagedItemsForQuickScan={fetchPackagedItemsForQuickScan}
                quickAddPackagedItemToOrderItem={
                  quickAddPackagedItemToOrderItem
                }
                deleteQuickScanShippingItemByLot={
                  deleteQuickScanShippingItemByLot
                }
                exportOrderForNetSuite={exportOrderForNetSuite}
              />
            ) : null}
          </div>
        </div>
      </div>
      <EmptyModal
        childComponent={emptyModal.childComponent}
        open={emptyModal.open}
        setOpen={setEmptyModelOpen}
      />
      <SlideOver
        childComponent={slideOver.childComponent}
        slideOver={slideOver}
        customClass="max-w-3xl"
        setSlideOver={setSlideOver}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(actions.logout()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ShippingDetails)
);
