import Select from "react-select";
import { convertToDefEventPara } from "../../shared/utility";

export default function SelectMenu(props) {
  const {
    error,
    handleInputChange,
    menuPlacement,
    name,
    options,
    defaultValue,
    isMulti,
    title,
    value,
    isClearable,
    disabled,
    parentClass,
    placeholder,
  } = props;

  const customStyles = {
    control: (base) => ({
      ...base,
      minHeight: 34,
      fontSize: 15,
      paddingTop: 0,
    }),
  };

  return (
    <div className={parentClass}>
      {title ? (
        <div className="flex space-x-2 mb-1">
          <label
            htmlFor="type"
            className="block text-sm font-medium text-gray-900"
          >
            {title}
          </label>
        </div>
      ) : null}
      <div>
        <Select
          isMulti={isMulti}
          name={name}
          options={options}
          placeholder={placeholder}
          defaultValue={defaultValue}
          value={value}
          menuPlacement={menuPlacement}
          noOptionsMessage={() => `Choose a ${title}`}
          onChange={(val) => {
            handleInputChange(convertToDefEventPara(name, val));
          }}
          styles={customStyles}
          // styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          isClearable={isClearable}
          isDisabled={disabled}
        />
        {error ? <p className="font-medium text-red-500">{error}</p> : null}
      </div>
    </div>
  );
}
