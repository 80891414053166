import { useEffect } from "react";
import { useForm } from "../../useForm";

import { Dialog } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";

import Input from "../../../controls/Input";

const initialFValues = {
  name: "",
  name_fr: "",
};

export default function AllergensForm(props) {
  const { index, allergen, setOpen, actionCall, isEdit } = props;

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isEdit) {
      actionCall(allergen, values, index);
    } else {
      actionCall(values);
    }
  };

  useEffect(() => {
    if (isEdit) {
      setValues(allergen);
    }
  }, [allergen, setValues]);

  return (
    <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
      <div className="flex min-h-0 flex-1 flex-col overflow-y-scroll py-6">
        <div className="px-4 sm:px-6">
          <div className="flex items-start justify-between">
            <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
              {isEdit ? "Edit " + allergen.name : "Add new product allergen"}
            </Dialog.Title>
            <div className="ml-3 flex h-7 items-center">
              <button
                type="button"
                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                onClick={() => setOpen({ childComponent: null, open: false })}
              >
                <span className="sr-only">Close panel</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
        <div className="relative mt-6 flex-1 px-4 sm:px-6">
          <div className="mt-2">
            <Input
              label="Name"
              name="name"
              onChange={handleInputChange}
              value={values.name}
              labelOn={true}
              type="text"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            />
          </div>
          <div className="mt-2">
            <Input
              label="Name (FR)"
              name="name_fr"
              onChange={handleInputChange}
              value={values.name_fr}
              labelOn={true}
              type="text"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            />
          </div>
        </div>
      </div>
      <div className="flex flex-shrink-0 justify-end px-4 py-4">
        <button
          type="button"
          className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400"
          onClick={() => setOpen({ childComponent: null, open: false })}
        >
          Cancel
        </button>
        <button
          type="submit"
          className="ml-4 inline-flex justify-center rounded-md bg-main-purple px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          onClick={handleSubmit}
        >
          {isEdit ? "Save" : "Create"}
        </button>
      </div>
    </div>
  );
}
