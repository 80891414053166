import { useEffect, useRef } from "react";
import { CreateRecipeViewModel } from "../../viewmodels/recipes/CreateRecipeViewModel";

import { connect } from "react-redux";

import * as actions from "../../store/actions/index";

import { withRouter } from "../../shared/utility";

import EmptyModal from "../../components/modals/EmptyModal";
import SlideOver from "../../components/slideOver/SlideOver";
import SimpleBanner from "../../components/SimpleBanner";
import RecipesForm from "../../components/forms/recipes/RecipesForm";

function CreateRecipe(props) {
  const { logout } = props;
  const {
    bomItems,
    bomItemsLoading,
    emptyModal,
    getBomItems,
    setEmptyModelOpen,
    setSlideOver,
    setShowSimpleNotification,
    slideOver,
    showSimpleNotification,
    recipeItems,
    addBomToIngredient,
    setRecipeItems,
    packagingItems,
    setPackagingItems,
    saveRecipe,
    recipe,
    askDeleteRecipe,
  } = CreateRecipeViewModel(logout);

  // var currentPage = 1;
  // useEffect(() => {
  //   getBomItems(null, currentPage);
  // }, []);

  return (
    <div>
      <SimpleBanner
        setShow={setShowSimpleNotification}
        showSimpleNotification={showSimpleNotification}
      />
      <div className="mt-2 px-4 sm:px-6 lg:px-8">
        <div className="sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold leading-6 text-gray-900">
              Create Recipe
            </h1>
          </div>
          <div className="mt-5 sm:items-center bg-white px-4 py-5 border-b border-gray-200 rounded-xl">
            <RecipesForm
              bomItemsLoading={bomItemsLoading}
              getBomItems={getBomItems}
              bomItems={bomItems}
              recipeItems={recipeItems}
              addBomToIngredient={addBomToIngredient}
              setEmptyModelOpen={setEmptyModelOpen}
              setRecipeItems={setRecipeItems}
              packagingItems={packagingItems}
              setPackagingItems={setPackagingItems}
              saveRecipe={saveRecipe}
              recipe={recipe}
              askDeleteRecipe={askDeleteRecipe}
            />
          </div>
        </div>
      </div>
      <EmptyModal
        childComponent={emptyModal.childComponent}
        open={emptyModal.open}
        setOpen={setEmptyModelOpen}
      />
      <SlideOver
        childComponent={slideOver.childComponent}
        slideOver={slideOver}
        customClass="max-w-3xl"
        setSlideOver={setSlideOver}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(actions.logout()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CreateRecipe)
);
