import { useEffect } from "react";

import { useParams } from "react-router-dom";

import { connect } from "react-redux";

import * as actions from "../../store/actions/index";

import { withRouter } from "../../shared/utility";

import SlideOver from "../../components/slideOver/SlideOver";
import SimpleBanner from "../../components/SimpleBanner";

import EmptyModal from "../../components/modals/EmptyModal";
import ManufactureNewProductForm from "../../components/forms/production/ManufactureNewProductForm";
import { ManufacturedProductViewModel } from "../../viewmodels/production/ManufacturedProductViewModel";
import ManufacturedProductLabel from "../../components/forms/production/barcode-label/ManufacturedProductLabel";
import ProductionCostReport from "../../components/forms/production/barcode-label/ProductionCostReport";

function ManufacturedProductDetails(props) {
  const { logout } = props;

  const { productId } = useParams();

  const {
    loading,
    addIngredientToManufacturedProduct,
    getManufacturedProductIngredients,
    emptyModal,
    getProductReportById,
    claimsOptions,
    manufacturedProduct,
    setEmptyModelOpen,
    showSimpleNotification,
    setShowSimpleNotification,
    setSlideOver,
    slideOver,
    certifiedByOptions,
    getAssemblyItemReceivingByBarcode,
    getCertifiedByOptionsForDropdown,
    getProductsList,
    updateManufacturedProduct,
    getReceivingAssemblyItemInStockByName,
    deleteIngredientFromManufacturedProduct,
    addManualEntryForManufacturingProduct,
    getManufacturedProductLabel,
    printLabelDetails,
    employeeOptions,
    getEmployeesForDropdown,
    fetchRecipesForDropdown,
    recipes,
    fetchRecipeItems,
    selectedRecipe,
    setSelectedRecipe,
    showErrorModal,
    printType,
    printProductionCostReport,
    getProductionBatchReport,
    getLabelCheckReport,
    getVacuumPackReport,
    getCookingAndCoolingReport,
    getMetalDetectorReport,
    getClaimsForDropdown,
    supervisorOptions,
    openNitrateAndPhosphateCalculator,
  } = ManufacturedProductViewModel(logout, productId);

  useEffect(() => {
    getClaimsForDropdown();
    getCertifiedByOptionsForDropdown();
    getEmployeesForDropdown(2, [1, 3]);
    getEmployeesForDropdown(1, [1, 3]);
    fetchRecipesForDropdown();
    getProductReportById(productId);
  }, []);

  function renderPrintItem() {
    let html = null;
    if (printType === 1) {
      html = <ManufacturedProductLabel printLabelDetails={printLabelDetails} />;
    } else if (printType === 2) {
      html = (
        <ProductionCostReport
          manufacturedProduct={manufacturedProduct}
          selectedRecipe={selectedRecipe}
        />
      );
    }
    return html;
  }
  return (
    <div>
      <SimpleBanner
        setShow={setShowSimpleNotification}
        showSimpleNotification={showSimpleNotification}
      />
      <div className="hide-for-print mt-2">
        {manufacturedProduct ? (
          <ManufactureNewProductForm
            loading={loading}
            addIngredientToManufacturedProduct={
              addIngredientToManufacturedProduct
            }
            deleteIngredientFromManufacturedProduct={
              deleteIngredientFromManufacturedProduct
            }
            isEdit={true}
            manufacturedProduct={manufacturedProduct}
            certifiedByOptions={certifiedByOptions}
            employeeOptions={employeeOptions}
            claimsOptions={claimsOptions}
            getManufacturedProductIngredients={
              getManufacturedProductIngredients
            }
            getAssemblyItemReceivingByBarcode={
              getAssemblyItemReceivingByBarcode
            }
            getProductsList={getProductsList}
            updateManufacturedProduct={updateManufacturedProduct}
            setEmptyModelOpen={setEmptyModelOpen}
            setSlideOver={setSlideOver}
            getReceivingAssemblyItemInStockByName={
              getReceivingAssemblyItemInStockByName
            }
            addManualEntryForManufacturingProduct={
              addManualEntryForManufacturingProduct
            }
            getManufacturedProductLabel={getManufacturedProductLabel}
            recipes={recipes}
            fetchRecipeItems={fetchRecipeItems}
            selectedRecipe={selectedRecipe}
            setSelectedRecipe={setSelectedRecipe}
            showErrorModal={showErrorModal}
            printProductionCostReport={printProductionCostReport}
            getProductionBatchReport={getProductionBatchReport}
            getLabelCheckReport={getLabelCheckReport}
            getVacuumPackReport={getVacuumPackReport}
            getCookingAndCoolingReport={getCookingAndCoolingReport}
            getMetalDetectorReport={getMetalDetectorReport}
            supervisorOptions={supervisorOptions}
            openNitrateAndPhosphateCalculator={
              openNitrateAndPhosphateCalculator
            }
          />
        ) : null}
      </div>
      <EmptyModal
        childComponent={emptyModal.childComponent}
        open={emptyModal.open}
        setOpen={setEmptyModelOpen}
      />
      <SlideOver
        childComponent={slideOver.childComponent}
        customClass="max-w-7xl"
        slideOver={slideOver}
        setSlideOver={setSlideOver}
      />
      {renderPrintItem()}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(actions.logout()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ManufacturedProductDetails)
);
