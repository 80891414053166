import { useState } from "react";

import { makePostApiCall } from "../../shared/makeApiCall";
import { BannerNotificationType } from "../../components/SimpleBanner";
import MediaManagerForm from "../../components/forms/mediaManager/MediaManagerForm";

export function MediaManagerViewModel(logout) {
  const [emptyModal, setEmptyModelOpen] = useState({
    open: false,
    childComponent: null,
  });
  const [loading, setLoading] = useState(false);
  const [media, setMedia] = useState({
    data: [],
  });
  const [showSimpleNotification, setShowSimpleNotification] = useState({
    show: false,
    message: null,
    title: null,
    type: BannerNotificationType.Success,
  });
  const [slideOver, setSlideOver] = useState({
    childComponent: null,
    open: false,
  });

  const getAllMedia = async function (search, page) {
    setLoading(true);
    const payload = {
      page: page,
      search: search,
    };
    let callResult = await makePostApiCall(`allMedia`, payload);
    setLoading(false);
    if (callResult.success) {
      setMedia(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const createMedia = async function (values) {
    const payload = { ...values };
    let callResult = await makePostApiCall(
      "createMedia",
      payload,
      "multipart/form-data"
    );

    if (callResult.success) {
      setShowSimpleNotification({
        show: true,
        title: "Media created",
        type: BannerNotificationType.Success,
      });
      setSlideOver({
        childComponent: null,
        open: false,
      });
      setTimeout(function () {
        getAllMedia("", 1);
      }, 1000);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const updateMedia = async function (brand, values) {
    let callResult = await makePostApiCall(
      `media/${brand.id}`,
      values,
      "multipart/form-data"
    );

    if (callResult.success) {
      setShowSimpleNotification({
        show: true,
        title: "Media updated",
        type: BannerNotificationType.Success,
      });
      setSlideOver({
        childComponent: null,
        open: false,
      });
      setTimeout(function () {
        getAllMedia("", 1);
      }, 1000);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const openAddMediaSlideOver = () => {
    setSlideOver({
      childComponent: (
        <MediaManagerForm
          isEdit={false}
          setOpen={setSlideOver}
          actionCall={(values) => createMedia(values)}
        />
      ),
      open: true,
    });
  };

  const askDeleteMedia = function (brand) {
    // setEmptyModelOpen({
    //   childComponent: (
    //     <DeleteConfirmationForm
    //       buttonMessage="Delete"
    //       confirmAction={() => deleteBrand(brand.id, index)}
    //       message={`Are you sure you want to delete ${brand.name}?`}
    //       setOpen={setEmptyModelOpen}
    //     />
    //   ),
    //   open: true,
    // });
  };

  const viewMedia = async function (media) {
    setSlideOver({
      childComponent: (
        <MediaManagerForm
          media={media}
          isEdit={true}
          setOpen={setSlideOver}
          actionCall={(values) => updateMedia(media, values)}
        />
      ),
      open: true,
    });
  };

  return {
    emptyModal,
    setEmptyModelOpen,
    setShowSimpleNotification,
    showSimpleNotification,
    media,
    getAllMedia,
    loading,
    askDeleteMedia,
    viewMedia,
    openAddMediaSlideOver,
    slideOver,
    setSlideOver,
  };
}
