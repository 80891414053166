import React from "react";

import Input from "../../../controls/Input";
import { convertToDefEventPara } from "../../../../shared/utility";

export default function ManufactureNewDetails3(props) {
  const {
    barcodeScanInProgress,
    handleBarcodeScan,
    handleInputChange,
    values,
    openManualEntryPopup,
    selectedRecipe,
    setValues,
  } = props;

  return (
    <div className="p-4 card-custom">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-2">
        <div className="col-span-1">
          <Input
            label="Total Target Weight"
            name="new_weight"
            onChange={(e) => {
              if (selectedRecipe.recipe) {
                setValues({
                  ...values,
                  new_weight: e.target.value,
                  required_total_raw_material: (
                    e.target.value *
                    parseFloat(selectedRecipe.recipe.target_production_carpan)
                  ).toFixed(2),
                });
              } else {
                handleInputChange(e);
              }
            }}
            value={values.new_weight}
            labelOn={true}
            type="text"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-1 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
          />
        </div>
        <div className="col-span-1">
          <Input
            label="Total Raw Material"
            name="required_total_raw_material"
            value={values.required_total_raw_material}
            labelOn={true}
            disabled={true}
            type="text"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-1 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
          />
        </div>
        <div className="col-span-1">
          <Input
            label="Product Temp &deg;C"
            name="new_product_temperature"
            onChange={handleInputChange}
            value={values.new_product_temperature}
            labelOn={true}
            type="text"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-1 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            //error={errors.new_product_temperature}
          />
        </div>
        <div className="col-span-2">
          <Input
            label="Allergens (EN)"
            name="allergens_en"
            onChange={handleInputChange}
            value={values.allergens_en}
            labelOn={true}
            type="text"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-1 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            disabled={true}
            //error={errors.allergens_en}
          />
        </div>
        <div className="col-span-2">
          <div className="flex space-x-2 mb-1">
            <label
              htmlFor="scanned_assembly_barcode"
              className={"text-sm block font-medium text-gray-900"}
            >
              Barcode Scanner
            </label>
          </div>
          <input
            label="Barcode Scanner"
            name="scanned_assembly_barcode"
            onChange={handleInputChange}
            value={values.scanned_assembly_barcode}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleBarcodeScan(e.target.value);
              }
            }}
            disabled={barcodeScanInProgress}
            labelOn={true}
            type="text"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-1 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            //error={errors.scanned_assembly_barcode}
          />
          <div className="mt-2 flex">
            <input
              id="quick_scan"
              aria-describedby="selectAllBox"
              name="quick_scan"
              type="checkbox"
              checked={values.quick_scan}
              className="mt-1 mr-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
              onClick={(e) =>
                handleInputChange(
                  convertToDefEventPara("quick_scan", e.target.checked)
                )
              }
            />
            Quick Scan
          </div>
          <div>
            <button
              onClick={() => {
                openManualEntryPopup();
              }}
              className="mt-2 rounded-md bg-main-purple px-3 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Manual entry
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
