import React from "react";

import Input from "../../../../components/controls/Input";
import { formatDate } from "../../../../shared/utility";
import InvoiceRecordReceivingQualityDetails from "./InvoiceRecordReceivingQualityDetails";

export default function InvoiceRecordProductDetails(props) {
  const { invoiceDetails } = props;

  return (
    <div>
      <div className="mb-5 px-4 py-5 sm:p-2 pt-0 grid grid-cols-1 overflow-hidden rounded-lg bg-white shadow md:grid-cols-2">
        <div className="col-span-2">
          <h3 className="text-base font-semibold leading-6 text-gray-900">
            Supplier item details
          </h3>
        </div>
        <div className="mt-2 col-span-1">
          <Input
            label="Supplier"
            name="supplier_name"
            value={invoiceDetails.supplier_name}
            labelOn={true}
            type="text"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-0 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
          />
        </div>
        <div className="mt-2 ml-2 col-span-1">
          <Input
            label="Product"
            name="product_name"
            value={invoiceDetails.product_name}
            labelOn={true}
            type="text"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-0 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
          />
        </div>
        <div className="col-span-2 grid grid-cols-3">
          <div className="mt-2 col-span-1">
            <Input
              label="Total unit"
              name="total_unit"
              value={invoiceDetails.total_unit}
              labelOn={true}
              type="text"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-0 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            />
          </div>
          <div className="mt-2 ml-2 col-span-1">
            <Input
              label="Pack Unit"
              name="pack_unit"
              value={invoiceDetails.pack_unit}
              labelOn={true}
              type="text"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-0 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            />
          </div>
          <div className="mt-2 ml-2 col-span-1">
            <Input
              label="Unit type"
              name="total_unit"
              value={invoiceDetails.unit_of_measure}
              labelOn={true}
              type="text"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-0 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            />
          </div>
        </div>

        <div className="mt-2 col-span-1">
          <Input
            label="Box Plu"
            name="product_code"
            value={invoiceDetails.product_code}
            labelOn={true}
            type="text"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-0 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
          />
        </div>
        <div className="mt-2 ml-2 col-span-1">
          <Input
            label="Product Code"
            name="product_code"
            value={invoiceDetails.product_code}
            labelOn={true}
            type="text"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-0 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
          />
        </div>
        <div className="mt-2 col-span-1">
          <Input
            label="Box Lot/Serial"
            name="product_lot"
            value={invoiceDetails.product_lot}
            labelOn={true}
            type="text"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-0 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
          />
        </div>
        <div className="mt-2 ml-2 col-span-1">
          <Input
            label="Box Date"
            name="box_date"
            value={formatDate(invoiceDetails.box_date)}
            labelOn={true}
            type="text"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-0 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
          />
        </div>

        <div className="mt-2 col-span-1">
          <Input
            label="BOM item Plu"
            name="ingredient_plu"
            value={invoiceDetails.bom_plu}
            labelOn={true}
            type="text"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-0 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
          />
        </div>
        <div className="mt-2 ml-2 col-span-1">
          <Input
            label="BOM item Name"
            name="ingredient_name"
            value={invoiceDetails.bom_name}
            labelOn={true}
            type="text"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-0 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
          />
        </div>
        <div className="mt-2 col-span-2">
          <Input
            label="Box Barcode"
            name="pack_unit"
            value={invoiceDetails.box_barcode}
            labelOn={true}
            type="text"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-0 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
          />
        </div>
      </div>
      <InvoiceRecordReceivingQualityDetails invoiceDetails={invoiceDetails} />
    </div>
  );
}
