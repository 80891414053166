import { useEffect } from "react";
import { useForm } from "../useForm";

import Input from "../../controls/Input";
import RecipeBomItemsTable from "./RecipeBomItemsTable";
import CreateRecipeIngredientsTable from "./CreateRecipeIngredientsTable";
import SelectMenu from "../../controls/SelectMenu";
import CreateRecipePackagingTable from "./CreateRecipePackagingTable";
import AddBomToIngredientPopup from "./modals/AddBomToIngredientPopup";
import { updateObjectInArray } from "../../../shared/utility";

const initialFValues = {
  id: 0,
  recipe_number: "",
  recipe_name: "",
  batch_size: 0,
  weight_per_unit: "",
  unit_per_case: "",
  weight_per_case: "",
  manufacture_loss_percent: 0,
  fermentation_loss_percent: 0,
  cooking_loss_percent: 0,
  slicing_loss_percent: 0,
  manufacture_loss_calculated: 0,
  fermentation_loss_calculated: 0,
  cooking_loss_calculated: 0,
  slicing_loss_calculated: 0,
  target_production_carpan: 0,
  meat_ingredients: [],
  packaging_ingredients: [],
  labour: 0,
  overhead: 0,
  processed: 0,
};

export default function RecipesForm(props) {
  const {
    bomItemsLoading,
    recipe,
    getBomItems,
    bomItems,
    recipeItems,
    setEmptyModelOpen,
    setRecipeItems,
    packagingItems,
    setPackagingItems,
    saveRecipe,
    addBomToIngredient,
    askDeleteRecipe,
  } = props;

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, false);

  const handleSubmit = (e) => {
    e.preventDefault();
    saveRecipe(values, values.id > 0);
  };

  useEffect(() => {
    if (recipe.id > 0) {
      setValues(recipe);
    }
  }, [recipe]);

  const calculateLossesFunc = (
    newBatchSize,
    newRecipeItems,
    newPackagingItems
  ) => {
    const man_percent_value = values.manufacture_loss_percent;
    const man_loss_kg = newBatchSize * (man_percent_value / 100);
    const manufacture_loss_calculated = parseFloat(
      newBatchSize - man_loss_kg
    ).toFixed(2);
    const ferment_loss_kg =
      manufacture_loss_calculated * (values.fermentation_loss_percent / 100);
    const fermentation_loss_calculated = parseFloat(
      parseFloat(manufacture_loss_calculated - ferment_loss_kg).toFixed(2)
    );
    const cooking_loss_kg = parseFloat(
      fermentation_loss_calculated * (values.cooking_loss_percent / 100)
    );
    const cooking_loss_calculated = parseFloat(
      parseFloat(fermentation_loss_calculated - cooking_loss_kg).toFixed(2)
    );
    const slicing_loss_kg = parseFloat(
      cooking_loss_calculated * (values.slicing_loss_percent / 100)
    );

    const slicing_loss_calculated = parseFloat(
      parseFloat(cooking_loss_calculated - slicing_loss_kg).toFixed(2)
    );
    setValues({
      ...values,
      batch_size: newBatchSize,
      manufacture_loss_calculated: manufacture_loss_calculated,
      fermentation_loss_calculated: fermentation_loss_calculated,
      cooking_loss_calculated: cooking_loss_calculated,
      slicing_loss_calculated: slicing_loss_calculated,
      processed: slicing_loss_calculated,
      target_production_carpan: (
        newBatchSize / slicing_loss_calculated
      ).toFixed(6),
    });

    newRecipeItems.forEach(function (a) {
      a.recipe_per_kg = (a.quantity / slicing_loss_calculated).toFixed(6);
    });
    setRecipeItems(newRecipeItems);

    newPackagingItems.forEach(function (a) {
      a.recipe_per_kg = (a.quantity / slicing_loss_calculated).toFixed(6);
    });
    setPackagingItems(newPackagingItems);
  };

  useEffect(() => {});

  const waitAddBomToIngredient = async function (confirmValue) {
    const valToSave = {
      bom_id: confirmValue.id,
      bom_name: confirmValue.name,
      bom_plu: confirmValue.plu,
      description: confirmValue.description,
      unit_of_measure: confirmValue.unit_of_measure,
      product_types_value: confirmValue.product_types_value,
      product_types_label: confirmValue.product_types_label,
      product_type: confirmValue.product_type,
      quantity: confirmValue.quantity,
      is_packaging:
        confirmValue.product_type.value === 12 ||
        confirmValue.product_type.value === 13,
    };

    const callResult = await addBomToIngredient({
      recipe_id: values.id,
      ...valToSave,
    });

    if (callResult.success) {
      var newPackagingItems = [...packagingItems];
      var newRecipeItems = [...recipeItems];
      var newBatchSize = values.batch_size;

      if (valToSave.is_packaging) {
        newPackagingItems = [...packagingItems, callResult.data];
        setPackagingItems(newPackagingItems);
      } else {
        newRecipeItems = [...recipeItems, callResult.data];
        newBatchSize =
          parseFloat(newBatchSize) + parseFloat(valToSave.quantity);
        setRecipeItems(newRecipeItems);
        setValues({
          ...values,
          batch_size: newBatchSize,
        });
      }

      setTimeout(() => {
        calculateLossesFunc(newBatchSize, newRecipeItems, newPackagingItems);
        setEmptyModelOpen({
          open: false,
          childComponent: null,
        });
      }, 500);
    }
  };

  const onAddBomToIngredient = async function (newBomItem) {
    setEmptyModelOpen({
      childComponent: (
        <AddBomToIngredientPopup
          bomItem={newBomItem}
          confirmAction={(confirmValue) => {
            waitAddBomToIngredient(confirmValue);
          }}
          isEdit={false}
          setOpen={setEmptyModelOpen}
        />
      ),
      open: true,
    });
  };

  const updateIngredientQuantity = (ingredient, index, oldQuantity) => {
    var newPackagingItems = [...packagingItems];
    var newRecipeItems = [...recipeItems];
    var newBatchSize = values.batch_size;

    if (ingredient.is_packaging === 1) {
      const currentPackagingItems = [...packagingItems];
      const ingredientBeingEdited = {
        ...packagingItems[index],
        ...ingredient,
      };
      var payload = {
        index: index,
        item: ingredientBeingEdited,
      };
      newPackagingItems = updateObjectInArray(currentPackagingItems, payload);
      setPackagingItems(newPackagingItems);
    } else {
      const currentRecipeItems = [...recipeItems];
      const ingredientBeingEdited = {
        ...recipeItems[index],
        ...ingredient,
      };
      var payload = {
        index: index,
        item: ingredientBeingEdited,
      };
      newRecipeItems = updateObjectInArray(currentRecipeItems, payload);
      setRecipeItems(newRecipeItems);
      newBatchSize =
        parseFloat(newBatchSize) -
        parseFloat(oldQuantity) +
        parseFloat(ingredient.quantity);
      setValues({
        ...values,
        batch_size: newBatchSize,
      });
    }

    setTimeout(() => {
      calculateLossesFunc(newBatchSize, newRecipeItems, newPackagingItems);
      setEmptyModelOpen({
        open: false,
        childComponent: null,
      });
    }, 500);
  };

  const openUpdateIngredientQuantity = (ingredient, index) => {
    setEmptyModelOpen({
      childComponent: (
        <AddBomToIngredientPopup
          bomItem={{ ...ingredient, name: ingredient.bom_name }}
          index={index}
          confirmAction={(confirmValue, index) => {
            updateIngredientQuantity(confirmValue, index, ingredient.quantity);
          }}
          isEdit={true}
          setOpen={setEmptyModelOpen}
        />
      ),
      open: true,
    });
  };

  return (
    <div className="flex h-full flex-col divide-y divide-gray-200">
      <div className="flex min-h-0 flex-1 flex-col overflow-y-scroll">
        <div className="flex gap-3 pt-2">
          <div className="mt-2 col-span-2 w-1/2 gap-2">
            <div className="mt-2 col-span-2 flex gap-2">
              <Input
                label="Recipe #"
                name="recipe_number"
                onChange={handleInputChange}
                value={values.recipe_number}
                labelOn={true}
                type="text"
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                error={errors.recipe_number}
              />
              <Input
                label="Recipe Name"
                name="recipe_name"
                onChange={handleInputChange}
                value={values.recipe_name}
                labelOn={true}
                type="text"
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                error={errors.recipe_name}
              />
              <SelectMenu
                handleInputChange={handleInputChange}
                name="selected_product_do_not_save"
                options={[]}
                title={"Product (optional)"}
              />
            </div>
            <div className="mt-2 col-span-2 flex gap-2">
              <Input
                label="Batch size"
                name="batch_size"
                onChange={handleInputChange}
                value={values.batch_size}
                labelOn={true}
                type="text"
                className="mt-1 block w-24 border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                error={errors.batch_size}
              />
              <Input
                label="Weight/unit"
                name="weight_per_unit"
                onChange={handleInputChange}
                value={values.weight_per_unit}
                labelOn={true}
                type="text"
                className="mt-1 block w-24 border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                error={errors.weight_per_unit}
              />
              <Input
                label="Unit/case"
                name="unit_per_case"
                onChange={handleInputChange}
                value={values.unit_per_case}
                labelOn={true}
                type="text"
                className="mt-1 block w-24 border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                error={errors.unit_per_case}
              />
              <Input
                label="Weight/case"
                name="weight_per_case"
                onChange={handleInputChange}
                value={values.weight_per_case}
                labelOn={true}
                type="text"
                className="mt-1 block w-24 border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                error={errors.weight_per_case}
              />
              <Input
                label="Labour (%)"
                name="labour"
                onChange={handleInputChange}
                value={values.labour}
                labelOn={true}
                type="text"
                className="mt-1 block w-24 border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                error={errors.labour}
              />
              <Input
                label="Overhead (%)"
                name="overhead"
                onChange={handleInputChange}
                value={values.overhead}
                labelOn={true}
                type="text"
                className="mt-1 block w-24 border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                error={errors.overhead}
              />
            </div>
            <div className="mt-2 col-span-2 flex gap-2">
              <Input
                label="Manufacture loss (%)"
                name="manufacture_loss_percent"
                onChange={handleInputChange}
                value={values.manufacture_loss_percent}
                labelOn={true}
                type="text"
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                error={errors.manufacture_loss_percent}
              />
              <Input
                label="Fermentation loss (%)"
                name="fermentation_loss_percent"
                onChange={handleInputChange}
                value={values.fermentation_loss_percent}
                labelOn={true}
                type="text"
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                error={errors.fermentation_loss_percent}
              />
              <Input
                label="Cooking loss (%)"
                name="cooking_loss_percent"
                onChange={handleInputChange}
                value={values.cooking_loss_percent}
                labelOn={true}
                type="text"
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                error={errors.cooking_loss_percent}
              />
              <Input
                label="Slicing loss (%)"
                name="slicing_loss_percent"
                onChange={handleInputChange}
                value={values.slicing_loss_percent}
                labelOn={true}
                type="text"
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                error={errors.slicing_loss_percent}
              />
              <Input
                label="Processed"
                name="processed"
                onChange={handleInputChange}
                value={values.processed}
                labelOn={true}
                disabled={true}
                type="text"
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                error={errors.processed}
              />
            </div>
            <div className="mt-2 col-span-2 flex gap-2">
              <Input
                label="Manufacture loss"
                name="manufacture_loss_calculated"
                onChange={handleInputChange}
                value={values.manufacture_loss_calculated}
                labelOn={true}
                disabled={true}
                type="text"
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                error={errors.manufacture_loss_calculated}
              />
              <Input
                label="Fermentation loss"
                name="fermentation_loss_calculated"
                onChange={handleInputChange}
                value={values.fermentation_loss_calculated}
                labelOn={true}
                disabled={true}
                type="text"
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                error={errors.fermentation_loss_calculated}
              />
              <Input
                label="Cooking loss"
                name="cooking_loss_calculated"
                onChange={handleInputChange}
                value={values.cooking_loss_calculated}
                labelOn={true}
                disabled={true}
                type="text"
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                error={errors.cooking_loss_calculated}
              />
              <Input
                label="Slicing loss"
                name="slicing_loss_calculated"
                onChange={handleInputChange}
                value={values.slicing_loss_calculated}
                labelOn={true}
                disabled={true}
                type="text"
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                error={errors.slicing_loss_calculated}
              />
              <Input
                label="Target production loss"
                name="target_production_carpan"
                onChange={handleInputChange}
                value={values.target_production_carpan}
                labelOn={true}
                disabled={true}
                type="text"
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                error={errors.target_production_carpan}
              />
              <button
                type="button"
                className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400"
                onClick={() =>
                  calculateLossesFunc(
                    values.batch_size,
                    recipeItems,
                    packagingItems
                  )
                }
              >
                Calculate
              </button>
            </div>
            <div className="col-span-2">
              <CreateRecipeIngredientsTable
                recipeItems={recipeItems}
                openUpdateIngredientQuantity={openUpdateIngredientQuantity}
                askDeleteRecipe={askDeleteRecipe}
              />
              <CreateRecipePackagingTable
                packagingItems={packagingItems}
                openUpdateIngredientQuantity={openUpdateIngredientQuantity}
                askDeleteRecipe={askDeleteRecipe}
              />
            </div>
          </div>
          <div className="col-span-2 w-1/2">
            <RecipeBomItemsTable
              getBomItems={getBomItems}
              bomItems={bomItems}
              bomItemsLoading={bomItemsLoading}
              onAddBomToIngredient={onAddBomToIngredient}
              recipe={recipe}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-shrink-0 justify-end px-4 py-4">
        <button
          type="button"
          className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400"
          onClick={() => {}}
        >
          Cancel
        </button>
        <button
          type="submit"
          className="ml-4 inline-flex justify-center rounded-md bg-main-purple px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          onClick={handleSubmit}
        >
          {values.id > 0 ? "Save" : "Create"}
        </button>
      </div>
    </div>
  );
}
