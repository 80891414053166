import { useEffect, useRef, useState } from "react";
import { UnitOfMeasuresViewModel } from "../../../viewmodels/setup/unitOfMeasures/UnitOfMeasuresViewModel";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";

import { connect } from "react-redux";

import * as actions from "../../../store/actions/index";

import { withRouter } from "../../../shared/utility";

import SlideOver from "../../../components/slideOver/SlideOver";
import SimpleBanner from "../../../components/SimpleBanner";

import { Paginator } from "primereact/paginator";

function UnitOfMeasures(props) {
  const { logout } = props;
  const {
    getUnitOfMeasures,
    loading,
    unitOfMeasures,
    openAddUnitOfMeasureSlideOver,
    setSlideOver,
    setShowSimpleNotification,
    slideOver,
    showSimpleNotification,
    viewUnitOfMeasureDetails,
  } = UnitOfMeasuresViewModel(logout);

  var currentPage = 1;
  useEffect(() => {
    getUnitOfMeasures(null, currentPage);
  }, []);

  const [first, setFirst] = useState(1);
  const [rows, setRows] = useState(20);
  const searchRef = useRef();

  const handleSearch = () => {
    getUnitOfMeasures(searchRef.current.value, currentPage);
  };

  const handlePagination = (event) => {
    currentPage = event.page + 1;
    setFirst(event.first);
    setRows(event.rows);
    getUnitOfMeasures(searchRef.current.value, currentPage);
  };

  return (
    <div>
      <SimpleBanner
        setShow={setShowSimpleNotification}
        showSimpleNotification={showSimpleNotification}
      />
      <div className="mt-2">
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="sm:flex sm:items-center px-4 py-5 sm:px-4 card-custom">
            <div className="sm:flex-auto">
              <h1 className="text-base font-semibold leading-6 text-gray-900">
                Unit Of Measures
              </h1>
              <div className="mt-5 flex rounded-md">
                <div className="relative flex items-stretch flex-grow focus-within:z-10">
                  <input
                    type="search"
                    name="searchProduct"
                    id="searchProduct"
                    className="block w-full rounded-lg rounded-l-md pl-2 sm:text-sm border border-gray-300 rounded-tr-none rounded-br-none"
                    placeholder="Search by name"
                    ref={searchRef}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") handleSearch();
                    }}
                  />
                </div>
                <button
                  type="button"
                  className="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1"
                  onClick={() => handleSearch()}
                >
                  <MagnifyingGlassIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </button>
                <button
                  type="button"
                  className="ml-5 rounded-md bg-main-purple px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  onClick={() => openAddUnitOfMeasureSlideOver()}
                >
                  Add unit of measure
                </button>
              </div>
            </div>
          </div>
          <div className="mt-8 flow-root">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                {loading ? (
                  <div>
                    <div className="border h-10 shadow rounded-md p-4 w-full mx-auto">
                      <div className="animate-pulse flex space-x-4">
                        Loading
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="overflow-hidden card-custom">
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead className="bg-white">
                        <tr>
                          <th
                            scope="col"
                            className="py-3 pl-3 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                          >
                            Name
                          </th>
                          <th
                            scope="col"
                            className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                          ></th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {unitOfMeasures.data.length > 0 ? (
                          unitOfMeasures.data.map((unitOfMeasure, index) => (
                            <tr key={unitOfMeasure.id}>
                              <td className="whitespace-nowrap py-1.5 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                {unitOfMeasure.name}
                              </td>
                              <td className="relative whitespace-nowrap py-1.5 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                <button
                                  onClick={() => {
                                    viewUnitOfMeasureDetails(
                                      unitOfMeasure,
                                      index
                                    );
                                  }}
                                  className="rounded-md bg-main-purple px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                  Edit
                                </button>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td
                              className="whitespace-nowrap py-3 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 text-center"
                              colSpan={2}
                            >
                              No unit of measure, please add one
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
              <div className="w-full">
                <Paginator
                  first={first}
                  rows={rows}
                  totalRecords={unitOfMeasures.total}
                  onPageChange={handlePagination}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <SlideOver
        childComponent={slideOver.childComponent}
        slideOver={slideOver}
        setSlideOver={setSlideOver}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(actions.logout()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UnitOfMeasures)
);
