import TextWithLabel from "../../controls/TextWithLabel";

export default function TraceInventoryArea(props) {
  const { inventory } = props;

  return (
    <div className="col-span-2">
      <h3 className="text-lg mb-2">Inventory</h3>
      <div className="overflow-hidden card-custom p-4 flex">
        <div className="grid grid-cols-4 gap-4 mt-2 flex-grow">
          <TextWithLabel
            label="Inventory"
            text={inventory.inventory_in_stock}
          />
          <TextWithLabel label="Sold" text={inventory.inventory_sold} />
        </div>
      </div>
    </div>
  );
}
