import { useState } from "react";

import { makePostApiCall, makePutApiCall } from "../../../shared/makeApiCall";
import CertifiedByForm from "../../../components/forms/setup/certifiedByOptions/CertifiedByOptionsForm";
import { BannerNotificationType } from "../../../components/SimpleBanner";
import DeleteConfirmationForm from "../../../components/forms/DeleteConfirmationForm";

export function CertifiedByOptionsViewModel(logout) {
  const [emptyModal, setEmptyModelOpen] = useState({
    open: false,
    childComponent: null,
  });
  const [loading, setLoading] = useState(false);
  const [certifiedByOptions, setCertifiedByOptions] = useState({ data: [] });

  const [showSimpleNotification, setShowSimpleNotification] = useState({
    show: false,
    message: null,
    title: null,
    type: BannerNotificationType.Success,
  });
  const [slideOver, setSlideOver] = useState({
    childComponent: null,
    open: false,
  });

  const user_id = localStorage.getItem("id");

  const getCertifiedByOptions = async function (search, page) {
    setLoading(true);
    const payload = {
      page: page,
      search: search,
    };
    let callResult = await makePostApiCall(`certifiedByOptions`, payload);

    setLoading(false);
    if (callResult.success) {
      setCertifiedByOptions(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const createCertifiedByOption = async function (values) {
    const payload = { ...values, user_id: user_id };
    let callResult = await makePostApiCall("createCertifiedByOption", payload);

    if (callResult.success) {
      setShowSimpleNotification({
        show: true,
        title: "CertifiedBy added",
        type: BannerNotificationType.Success,
      });
      setSlideOver({
        childComponent: null,
        open: false,
      });
      setTimeout(function () {
        getCertifiedByOptions("", 1);
      }, 1000);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const updateCertifiedByOption = async function (certifiedBy, values, index) {
    let callResult = await makePutApiCall(
      `certifiedByOption/${certifiedBy.id}`,
      values
    );

    if (callResult.success) {
      setShowSimpleNotification({
        show: true,
        title: "Certification updated",
        type: BannerNotificationType.Success,
      });
      setSlideOver({
        childComponent: null,
        open: false,
      });
      setTimeout(function () {
        getCertifiedByOptions("", 1);
      }, 1000);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const openAddCertifiedOptionBylideOver = () => {
    setSlideOver({
      childComponent: (
        <CertifiedByForm
          isEdit={false}
          setOpen={setSlideOver}
          actionCall={createCertifiedByOption}
        />
      ),
      open: true,
    });
  };

  const viewCertifiedOptionByDetails = async function (certifiedBy, index) {
    setSlideOver({
      childComponent: (
        <CertifiedByForm
          certifiedBy={certifiedBy}
          index={index}
          isEdit={true}
          setOpen={setSlideOver}
          actionCall={updateCertifiedByOption}
        />
      ),
      open: true,
    });
  };

  const deleteCertifiedByOption = async function (id, index) {
    setLoading(true);

    let callResult = await makePostApiCall(`deleteCertifiedByOption/${id}`, {});
    setLoading(false);
    if (callResult.success) {
      setEmptyModelOpen({
        open: false,
        childComponent: null,
      });
      getCertifiedByOptions(null, 0);
      setShowSimpleNotification({
        show: true,
        title: callResult.data.message,
        type: BannerNotificationType.Success,
      });
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const askDeleteCertifiedByOption = function (certifiedByOption, index) {
    setEmptyModelOpen({
      childComponent: (
        <DeleteConfirmationForm
          buttonMessage="Delete"
          confirmAction={() =>
            deleteCertifiedByOption(certifiedByOption.id, index)
          }
          message={`Are you sure you want to delete ${certifiedByOption.name}?`}
          setOpen={setEmptyModelOpen}
        />
      ),
      open: true,
    });
  };

  return {
    askDeleteCertifiedByOption,
    emptyModal,
    getCertifiedByOptions,
    loading,
    certifiedByOptions,
    openAddCertifiedOptionBylideOver,
    setEmptyModelOpen,
    setSlideOver,
    setShowSimpleNotification,
    slideOver,
    showSimpleNotification,
    viewCertifiedOptionByDetails,
  };
}
