import React from "react";

import Input from "../../../controls/Input";
import SelectMenu from "../../../controls/SelectMenu";
import CreatableSelectMenu from "../../../controls/CreatableSelectMenu";

export default function ManufactureNewDetails(props) {
  const {
    certifiedByOptions,
    employeeOptions,
    supervisorOptions,
    claimsOptions,
    errors,
    handleInputChange,
    manufacturedProduct,
    values,
    isEdit,
  } = props;

  return (
    <div className="p-4 card-custom">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
        <div className="col-span-2">
          <CreatableSelectMenu
            handleInputChange={handleInputChange}
            name="supervisor"
            options={supervisorOptions}
            defaultValue={isEdit ? manufacturedProduct.supervisor : null}
            title={"Supervisor"}
            error={errors.supervisor}
          />
        </div>
        <div className="col-span-2">
          <CreatableSelectMenu
            handleInputChange={handleInputChange}
            isMulti={true}
            name="employee_1"
            options={employeeOptions}
            defaultValue={isEdit ? manufacturedProduct.employee_1 : null}
            title={"Employee"}
            error={errors.employee_1}
          />
        </div>
        <div className="col-span-2">
          <SelectMenu
            handleInputChange={handleInputChange}
            name="claims"
            options={claimsOptions}
            defaultValue={isEdit ? manufacturedProduct.claims : null}
            title={"Claims"}
            showLabel={true}
            isMulti={true}
          />
        </div>
        <div className="col-span-1">
          <SelectMenu
            handleInputChange={handleInputChange}
            name="certification_type"
            options={certifiedByOptions}
            defaultValue={
              isEdit ? manufacturedProduct.certification_type : null
            }
            title={"Certified by"}
            showLabel={true}
          />
        </div>
        <div className="col-span-1">
          <Input
            label="Production Room"
            name="production_room"
            onChange={handleInputChange}
            value={values.production_room}
            labelOn={true}
            type="text"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            error={errors.production_room}
          />
        </div>
        <div className="col-span-1">
          <Input
            label="Production Room Temp &deg;C"
            name="production_room_temperature"
            onChange={handleInputChange}
            value={values.production_room_temperature}
            labelOn={true}
            type="text"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-1 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            error={errors.production_room_temperature}
          />
        </div>
        <div className="col-span-1">
          <Input
            label="Raw Product Temp &deg;C"
            name="raw_product_temperature"
            onChange={handleInputChange}
            value={values.raw_product_temperature}
            labelOn={true}
            type="text"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-1 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            error={errors.raw_product_temperature}
          />
        </div>
      </div>
    </div>
  );
}
