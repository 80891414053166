import React, { useEffect, useState } from "react";

import TextArea from "../../../controls/TextArea";

export default function OrderNotes(props) {
  const { orderNote, confirmAction, setOpen } = props;

  const [note, setNote] = useState(orderNote);

  useEffect(() => {
    setNote(orderNote);
  }, []);

  return (
    <div className="justify-between">
      <div>
        <div className="flex items-center justify-between flex-wrap sm:flex-nowrap">
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Add note
            </h3>
          </div>
        </div>

        <div className="py-1 gap-4 bg-white sm:rounded-xl">
          <div className="grid grid-cols-1 gap-4 w-full">
            <TextArea
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm max-w-lg"
              labelOn={false}
              name="notes"
              onChange={(e) => setNote(e.target.value)}
              value={note}
              rows="3"
            />
          </div>

          <div className="sm:flex-no-wrap my-auto flex items-center">
            <button
              onClick={() => {
                confirmAction(note);
                setOpen({
                  open: false,
                });
              }}
              className="inline-flex justify-center mt-3 py-35-custom px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-main-purple hover:bg-indigo-700 items-center"
            >
              OK
            </button>
            <button
              onClick={() =>
                setOpen({
                  open: false,
                })
              }
              className="ml-2 inline-flex justify-center mt-3 py-35-custom px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 items-center"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
