import { useState } from "react";
import { QaReportsViewModel } from "../../viewmodels/qaReports/QaReportsViewModel";

import { connect } from "react-redux";

import * as actions from "../../store/actions/index";

import { withRouter } from "../../shared/utility";

import EmptyModal from "../../components/modals/EmptyModal";
import SimpleBanner from "../../components/SimpleBanner";
import SelectQaReportModal from "../../components/forms/qaReports/SelectQaReportModal";
import ReceivingReportMeatUi from "../../components/forms/qaReports/reportUI/ReceivingReportMeatUi";
import ReceivingReportNonMeatUi from "../../components/forms/qaReports/reportUI/ReceivingReportNonMeatUi";

function QaReportsScreen(props) {
  const { logout } = props;
  const {
    emptyModal,
    loading,
    reportTypes,
    setEmptyModelOpen,
    setShowSimpleNotification,
    showSimpleNotification,
    printQaReportById,
    reportTypeToPrint,
  } = QaReportsViewModel(logout);

  const renderQaReportUi = () => {
    var reportToReturn = null;
    switch (reportTypeToPrint) {
      case 11:
        reportToReturn = <ReceivingReportMeatUi />;
        break;
      case 12:
        reportToReturn = <ReceivingReportNonMeatUi />;
        break;
      default:
        break;
    }

    return reportToReturn;
  };

  useState(() => {
    renderQaReportUi();
  }, [reportTypeToPrint]);

  return (
    <div>
      <SimpleBanner
        setShow={setShowSimpleNotification}
        showSimpleNotification={showSimpleNotification}
      />
      <div className="hide-for-print mt-10">
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="sm:flex sm:items-center px-4 py-5 sm:px-4 card-custom">
            <div className="sm:flex-auto">
              <h1 className="text-base font-semibold leading-6 text-gray-900">
                QA Reports
              </h1>
              <div className="mt-5 flex rounded-md">
                {/* <button
                  type="button"
                  className="ml-5 rounded-md bg-main-purple px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  onClick={() => openAddAllergenSlideOver()}
                >
                  Add Allergen
                </button> */}
              </div>
            </div>
          </div>
          <div className="mt-8 flow-root">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <div className="overflow-hidden card-custom">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-white">
                      <tr>
                        <th
                          scope="col"
                          className="py-3 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                        >
                          Name
                        </th>
                        <th
                          scope="col"
                          className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                        ></th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {reportTypes.map((reportType, index) => (
                        <tr key={reportType.id}>
                          <td className="whitespace-nowrap py-1.5 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                            {reportType.name}
                          </td>
                          <td className="relative whitespace-nowrap py-1.5 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                            <button
                              onClick={() =>
                                setEmptyModelOpen({
                                  childComponent: (
                                    <SelectQaReportModal
                                      printQaReportById={printQaReportById}
                                      reportType={reportType}
                                      setOpen={setEmptyModelOpen}
                                    />
                                  ),
                                  open: true,
                                })
                              }
                              className="rounded-md bg-main-purple px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                              Print
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <EmptyModal
        childComponent={emptyModal.childComponent}
        open={emptyModal.open}
        setOpen={setEmptyModelOpen}
      />
      {renderQaReportUi()}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(actions.logout()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(QaReportsScreen)
);
