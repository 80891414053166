import { Dialog } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";

import Input from "../../controls/Input";
import { formatDate, formatDateWithTime } from "../../../shared/utility";
import TextWithLabel from "../../controls/TextWithLabel";
import { classNames } from "primereact/utils";

export default function PackagingDetails(props) {
  const { packagedItem, setOpen } = props;

  return (
    <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
      <div className="flex min-h-0 flex-1 flex-col overflow-y-scroll py-6">
        <div className="px-4 sm:px-6">
          <div className="flex items-start justify-between">
            <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
              Packaging Details - {packagedItem[0].product_lot}
            </Dialog.Title>
            <div className="ml-3 flex h-7 items-center">
              <button
                type="button"
                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                onClick={() => setOpen({ childComponent: null, open: false })}
              >
                <span className="sr-only">Close panel</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
        <div className="relative mt-6 flex-1 px-4 sm:px-6">
          <div className="flow-root">
            <ul className="-mb-8">
              {packagedItem.map((singleItem, eventIdx) => (
                <li key={singleItem.id}>
                  <div className="relative pb-8">
                    {eventIdx !== packagedItem.length - 1 ? (
                      <span
                        className="absolute left-2 top-4 -ml-px h-full w-0.5 bg-gray-200"
                        aria-hidden="true"
                      />
                    ) : null}
                    <div className="relative flex space-x-3">
                      <div>
                        <span
                          className={
                            "flex h-4 w-4 items-center justify-center rounded-full ring-8 ring-white bg-gray-500"
                          }
                        ></span>
                      </div>
                      <div>
                        <div className="grid grid-cols-3 gap-4">
                          <div className="col-span-2">
                            <TextWithLabel
                              label={`Product Name (${singleItem.product_code})`}
                              text={singleItem.product_name}
                            />
                          </div>
                          <div>
                            <TextWithLabel
                              label="Total Packaged"
                              text={singleItem.total_individual_units}
                            />
                          </div>
                        </div>
                        <div className="grid grid-cols-4 gap-4 mt-2">
                          <div>
                            <TextWithLabel
                              label="Brand"
                              text={singleItem.brand_name}
                            />
                          </div>
                          <div>
                            <TextWithLabel
                              label="Supervisor"
                              text={singleItem.supervisor}
                            />
                          </div>

                          {singleItem.employee_1 ? (
                            <div>
                              <TextWithLabel
                                label="Employee(s)"
                                text={singleItem.employee_1}
                              />
                            </div>
                          ) : null}
                        </div>
                        <div className="grid grid-cols-5 gap-4 mt-2">
                          <div className="col-span-2">
                            <TextWithLabel
                              label="Production Date"
                              text={formatDateWithTime(
                                singleItem.production_date
                              )}
                            />
                          </div>
                          <div>
                            <TextWithLabel
                              label="Best Before Date"
                              text={singleItem.best_before_date}
                            />
                          </div>
                          <div>
                            <TextWithLabel
                              label="Packing Size"
                              text={singleItem.packing_size}
                            />
                          </div>
                          <div>
                            <TextWithLabel
                              label="Shelf life"
                              text={singleItem.shelf_life}
                            />
                          </div>
                        </div>
                        <div className="grid grid-cols-4 gap-4 mt-2">
                          <div>
                            <TextWithLabel
                              label="Packaging Material 1"
                              text={singleItem.packaging_material_1}
                            />
                          </div>
                          <div>
                            <TextWithLabel
                              label="Packaging Material 2"
                              text={singleItem.packaging_material_2}
                            />
                          </div>
                          <div>
                            <TextWithLabel
                              label="Room Temp C&deg;"
                              text={singleItem.room_temperature}
                            />
                          </div>
                          <div>
                            <TextWithLabel
                              label="Product Temp C&deg;"
                              text={singleItem.product_temperature}
                            />
                          </div>
                        </div>
                        <div className="grid grid-cols-5 gap-4 mt-2">
                          <div className="col-span-2">
                            <TextWithLabel
                              label="Passed from metal detector"
                              text={
                                singleItem.passed_from_metal_detector
                                  ? "Yes"
                                  : "No"
                              }
                            />
                          </div>
                          <div className="col-span-2">
                            <TextWithLabel
                              label="Skid Label"
                              text={
                                singleItem.skid_label_number
                                  ? singleItem.skid_label_number
                                  : "N/A"
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="flex flex-shrink-0 justify-end px-4 py-4">
        <button
          type="button"
          className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400"
          onClick={() => setOpen({ childComponent: null, open: false })}
        >
          Close
        </button>
      </div>
    </div>
  );
}
